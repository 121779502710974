

export const myDetailsTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    kurzMyProfile: {
      customerNoLabel: 'Customer Number',
      role: 'Role',
      salutation: 'Salutation',
      name: 'Name',
      mail: 'eMail',
      lang: 'Language',
      password: 'Password',
      changePassword: 'Change password'
    },
    myDetails: {
      header: 'My Account',
      welcomeBack: 'Welcome back, {{name}}',
      myData: {
        mainTitle: 'Personal Data',
        myProfileTitle: 'Personal Data',
        changePasswordTitle: 'Change password',
        changePasswordModal: {
          passwordRulesText: 'The password must consist of at least 9 characters and contain an uppercase letter, a lowercase letter and a number.',
          oldPasswordLabel: 'Old Password',
          oldPasswordPlaceholder: 'Old Password',
          newPasswordLabel: 'New Password',
          newPasswordPlaceholder: 'New Password',
          newPasswordErrorMessage: 'The Password does not meet the minimum requirement.',
          repeatPasswordLabel: 'Confirm Password',
          repeatPasswordPlaceholder: 'Confirm Password',
          updateFailedMessage: 'Changing the password has failed',
          updateSuccessMessage: 'Successfully changed the password!'
        },
      },
      delivery: {
        mainTitle: 'Delivery',
        deliveryAddressTitle: 'Address Book',
        myDeliveryAddresses: 'My Delivery Addresses',
        deliveryConditionTitle: 'Delivery Conditions',
        noConditionsFound: 'No Delivery Conditions found'
      },
      payment: {
        mainTitle: 'Invoice',
        paymentAddressTitle: 'Address Book',
        myPaymentAddresses: 'My billing addresses',
        paymentConditionTitle: 'Payment Conditions',
        noConditionsFound: 'No Payment Conditions found'
      },
      administration: {
        mainTitle: 'Administration',
        manageUsersTitle: 'User overview',
        manageUsers: {
          userDetails: 'User details',
          email: 'eMail',
          name: 'Name',
          role: 'Role',
          state: 'State',
          active: 'active',
          inactive: 'inactive'
        }
      },
      settings: {
        mainTitle: 'Settings',
        unitsOfMeasurement: 'Units Of Measurement'
      }
    }
  },
  // -------------------------- DE
  de: {
    kurzMyProfile: {
      customerNoLabel: 'Kundennummer',
      role: 'Rolle',
      salutation: 'Anrede',
      name: 'Name',
      mail: 'E-Mail',
      lang: 'Sprache',
      password: 'Passwort',
      changePassword: 'Passwort ändern',
    },
    myDetails: {
      header: 'Mein Konto',
      welcomeBack: 'Willkommen zurück, {{name}}',
      myData: {
        mainTitle: 'Persönliche Daten',
        myProfileTitle: 'Persönliche Daten',
        changePasswordTitle: 'Passwort ändern',
        changePasswordModal: {
          passwordRulesText: 'Das Passwort muss aus mindestens 9 Zeichen bestehen und einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
          oldPasswordLabel: 'Altes Passwort',
          oldPasswordPlaceholder: 'Altes Passwort',
          newPasswordLabel: 'Neues Passwort',
          newPasswordPlaceholder: 'Neues Passwort',
          newPasswordErrorMessage: 'Das Passwort erfüllt nicht die Mindestanforderung',
          repeatPasswordLabel: 'Neues Passwort bestätigen',
          repeatPasswordPlaceholder: 'Neues Passwort bestätigen',
          updateFailedMessage: 'Fehler! Ihr Passwort wurde nicht geändert.',
          updateSuccessMessage: 'Ihr Passwort wurde geändert.'
        },
      },
      delivery: {
        mainTitle: 'Lieferung',
        deliveryAddressTitle: 'Adressbuch',
        myDeliveryAddresses: 'Meine Lieferadressen',
        deliveryConditionTitle: 'Lieferbedingungen',
        noConditionsFound: 'Keine Lieferbedingungen gefunden'
      },
      payment: {
        mainTitle: 'Rechnung',
        paymentAddressTitle: 'Adressbuch',
        myPaymentAddresses: 'Meine Rechnungsadressen',
        paymentConditionTitle: 'Zahlungsbedingungen',
        noConditionsFound: 'Keine Zahlungsbedingungen gefunden'
      },
      administration: {
        mainTitle: 'Administration',
        manageUsersTitle: 'Benutzerübersicht',
        manageUsers: {
          userDetails: 'Benutzerdetails',
          email: 'E-Mail',
          name: 'Name',
          role: 'Rolle',
          state: 'Status',
          active: 'aktiv',
          inactive: 'inaktiv'
        }
      },
      settings: {
        mainTitle: 'Einstellungen',
        unitsOfMeasurement: 'Maßeinheiten'
      }
    }
  },
  // -------------------------- FR
  fr: {
    kurzMyProfile: {
      customerNoLabel: 'Numéro de client',
      role: 'Rôle',
      salutation: 'Formule d\'appel',
      name: 'Nom',
      mail: 'E-mail',
      lang: 'Langue',
      password: 'Mot de passe',
      changePassword: 'Modifier le mot de passe',
    },
    myDetails: {
      header: 'Mon Compte',
      welcomeBack: 'Content de te revoir, {{name}}',
      myData: {
        mainTitle: 'Données personnelles',
        myProfileTitle: 'Données personnelles',
        changePasswordTitle: 'Modifier le mot de passe',
        changePasswordModal: {
          passwordRulesText: 'Le mot de passe doit être composé d\'au moins 9 caractères et contenir une lettre majuscule, une lettre minuscule, et un chiffre.',
          oldPasswordLabel: 'Ancient mot de passe',
          oldPasswordPlaceholder: 'Ancient mot de passe',
          newPasswordLabel: 'Nouveau mot de passe',
          newPasswordPlaceholder: 'Nouveau mot de passe',
          newPasswordErrorMessage: 'Le mot de passe ne répond pas aux exigences minimales',
          repeatPasswordLabel: 'Répéter le mot de passe',
          repeatPasswordPlaceholder: 'Répéter le mot de passe',
          updateFailedMessage: 'Échec du changement du mot de passe',
          updateSuccessMessage: 'Succès pour changer le mot de passe !'
        },
      },
      delivery: {
        mainTitle: 'Livraison',
        deliveryAddressTitle: 'Carnet d\'adresse',
        myDeliveryAddresses: 'Mes adresses de livraison',
        deliveryConditionTitle: 'Conditions de livraison',
        noConditionsFound: 'Des conditions de livraison non-trouvées'
      },
      payment: {
        mainTitle: 'Facture',
        paymentAddressTitle: 'Carnet d\'adresse',
        myPaymentAddresses: 'Mes adresses de facture',
        paymentConditionTitle: 'Conditions de paiement',
        noConditionsFound: 'Des conditions de paiement non-trouvées'
      },
      administration: {
        mainTitle: 'Administration',
        manageUsersTitle: 'Résumé de l\'utilisateur',
        manageUsers: {
          userDetails: 'Détails d\'utilisateur',
          email: 'E-Mail',
          name: 'Nom',
          role: 'Rôle',
          state: 'État',
          active: 'aktif',
          inactive: 'inaktif'
        }
      },
      settings: {
        mainTitle: 'Paramètres',
        unitsOfMeasurement: 'Des unités de dimensions'
      }
    }
  },
  // -------------------------- NL
  nl: {
    kurzMyProfile: {
      customerNoLabel: 'Klantnummer',
      role: 'Rol',
      salutation: 'Aanhef',
      name: 'Naam',
      mail: 'E-mail',
      lang: 'Taal',
      password: 'Wachtwoord',
      changePassword: 'Verander wachtwoord',
    },
    myDetails: {
      header: 'Mijn Rekening',
      welcomeBack: 'Welkom terug, {{name}}',
      myData: {
        mainTitle: 'Persoonlijke gegevens',
        myProfileTitle: 'Persoonlijke gegevens',
        changePasswordTitle: 'Verander wachtwoord',
        changePasswordModal: {
          passwordRulesText: 'Het wachtwoord moet uit minimaal 9 tekens bestaan ​​en een hoofdletter, een kleine letter, en een cijfer bevatten.',
          oldPasswordLabel: 'Oud Wachtwoord',
          oldPasswordPlaceholder: 'Oud Wachtwoord',
          newPasswordLabel: 'Nieuw Wachtwoord',
          newPasswordPlaceholder: 'Nieuw Wachtwoord',
          newPasswordErrorMessage: 'Het wachtwoord voldoet niet aan de minimumvereiste',
          repeatPasswordLabel: 'Herhaal Wachtwoord',
          repeatPasswordPlaceholder: 'Herhaal Wachtwoord',
          updateFailedMessage: 'Fout bij het wijzigen van het wachtwoord',
          updateSuccessMessage: 'Succes bij het wijzigen van het wachtwoord!'
        },
      },
      delivery: {
        mainTitle: 'Levering',
        deliveryAddressTitle: 'Adresboek',
        myDeliveryAddresses: 'Mijn afleveradressen',
        deliveryConditionTitle: 'Leveringsvoorwaarden',
        noConditionsFound: 'Geen leveringsvoorwaarden gevonden'
      },
      payment: {
        mainTitle: 'Rekening',
        paymentAddressTitle: 'Adresboek',
        myPaymentAddresses: 'Mijn factuuradressen',
        paymentConditionTitle: 'Betalingsvoorwaarden',
        noConditionsFound: 'Geen betalingsvoorwaarden gevonden'
      },
      administration: {
        mainTitle: 'Administratie',
        manageUsersTitle: 'Gebruikersoverzicht',
        manageUsers: {
          userDetails: 'Gebruikersdetails',
          email: 'E-mail:',
          name: 'Naam',
          role: 'Rol',
          state: 'Status',
          active: 'aktive',
          inactive: 'inaktive'
        }
      },
      settings: {
        mainTitle: 'Instellingen',
        unitsOfMeasurement: 'Meeteenheden'
      }
    }
  },
  chunks: [
    'kurzMyProfile',
    'myDetails'
  ]
};
