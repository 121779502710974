
export const productDetailsTranslations = { //NOSONAR
  en: {
    pdp: {
      orderForm: {
        materialNumber: 'Material Number',
        dreamcomposerModal: {
          header: '3D Product Representation',
          buttonLabel: '3D',
          environmentsLabel: 'Environments',
          backgroundsLabel: 'Background',
          geometriesLabel: 'Geometry',
          modalContentHTML: `
            <div>The 3D representation of our products is generated by our innovative DREAMCOMPOSER® tool. DREAMCOMPOSER® offers a realistic, interactive product view that provides optimum support in the selection of surface finishes.</div>
            <div>Find out more about DREAMCOMPOSER® and its many possibilities at <a target="_blank" href="https://www.dreamcomposer.de/en/">Dreamcomposer.de</a>.</div>`
        },
        summaryBox: {
          productGroup: 'Product Group',
          quality: 'Quality',
          basePriceOnRequest: 'Request Price',
          basePrice: 'Base Price',
          showFurtherPrices: 'Show further prices',
          technicalSpecification: 'Technical Specification'
        },
        anonymous: {
          heading: 'Sign in to take advantage of your exclusive customer benefits:',
          subHeading: 'Benefit from a personalized shopping experience!',
          prices: 'Personalized prices',
          configs: 'Custom product configurations and access to stock rolls inventory',
          documents: 'Download of product-specific documents',
        },
        noStocklevelForStockOnlyProduct: 'No stock available for this product.',
        quantity: 'Quantity',
        confirmQuantityModal: {
          title: 'Confirm',
          question: 'Are you certain, that you want to order {{quantity}} rolls?'
        },
        totalArea: 'Total Area',
        priceBase: 'Price Base',
        totalPrice: 'Total Price',
        addToCartButton: 'Add To Cart',
        requestPrice: 'Request Price',
        informationModal: {
          plastics: {
            width: {
              infoText: 'When selecting a special width you can only order in fixed amounts as listed.'
            },
            lengthFinishingTypeCore: {
              title1: 'Standard Cut',
              paragraph1: 'Select our Standard Cut for your hot stamping foil. Our Standard Cut features clean-cut edges, accurate converting of up to a maximum roll lenght of 305m, and the usual fast and reliable delivery.',
              title2: 'Premium Cut',
              paragraph2: 'Our Premium Cut features extremely well-defined cutting edges, extra-long roll length, and minimum flaking thanks to modern reel cutters. Your cuts include rewinding to ensure perfect unwinding and smooth processing. When ordering, please note that you cannot custom cut rolls with Premium Cut due to the winding.'
            },
            quantity: { infoText: 'In the case of special widths, you can only order in fixed amounts.' },
          },
          graphics: {
            core: { infoText: '' },
            width: { infoText: '' },
            length: { infoText: '' },
            quantity: { infoText: '' },
          },
        },
        requestPriceModal: {
          header: 'Contact Request',
          dateLabel: 'Date',
          topicLabel: 'Topic',
          topicValue: 'Price offer',
          productLabel: 'Product',
          commentLabel: 'Comment',
          commentPlaceholder: 'Please type your request'
        },
        confirmRollContractConfigModal: {
          textForOne: 'This roll contract is only valid for the following configuration:',
          textForMany: 'This roll contract is only valid for one of the following configurations:',
          questionForOne: 'Would you like to apply this configuration?',
          questionForMany: 'Would you like to apply the selected configuration?'
        },
        secondaryButtons: {
          openRequestModal: {
            label: 'Contact a Representative',
            subjectValue: 'Request'
          },
          seeProductStockLabel: 'See available Stock Rolls',
          openProductSampleRequestModal: {
            label: 'Request Product Sample',
            subjectValue: 'Product sample'
          },
          seeProductContractsLabel: 'See Contracts',
          compareProduct: 'Compare Product',
        },
        toProduct: 'To Product',
      },
      tabBar: {
        productInformationTab: {
          title: 'Information',
          recyclable: {
            heading: 'Sustainability',
          },
          quality: {
            heading: 'Quality',
            uspHeading: 'Key features',
            colorHeading: 'Color',
            substrateHeading: 'Substrates',
            applicationExamplesHeading: 'Application examples',
            processingHeading: 'Processing',
            processingTempsHeading: 'Processing temperatures',
            motifHeading: 'Motifs',
            resistanceHeading: 'Resistance',
            washResistanceHeading: 'Wash resistance',
            overPrintabilityHeading: 'Overprint- and paintability',
            motifs: {
              FINE: 'fine',
              MEDIUM: 'medium',
              AREAL: 'solid',
              RELIEF: 'embossed',
            },
            resistance: {
              bulkResistance: 'bulk resistance',
              adhesion: 'adhesion',
              edgeDefinition: 'edge definition',
              definition: 'definition',
              coverage: 'coverage',
              alcoholResistance: 'alcohol resistance',
              resistanceDryCleaning: 'resistance dry cleaning',
              scratchResistance: 'scratch resistance',
              abrasionResistance: 'abrasion resistance',
              overStampability: 'over-moldability',
              VERY_CLEAN: 'very clean',
              CLEAN: 'clean',
              NOT_CLEAN: 'unclean',
              VERY_RESISTANT: 'highly resistant',
              RESISTANT: 'resistant',
              NOT_RESISTANT: 'not resistant',
              VERY_GOOD: 'very good',
              GOOD: 'good',
              NOT_SUITABLE: 'unsuitable',
              POSSIBLE: 'possible',
              CONDITIONALLY_POSSIBLE: 'partly possible',
              NOT_POSSIBLE: 'not possible',
              NOT_TESTED: 'not tested',
              CYCLES_10_AT_40_C: '10 cycles at 40 °C',
              handWashing: 'hand wash (30 °C)',
              machineWashing: 'machine wash 30-40 °C',
              numberOfWashingCycles: 'number of washing cycles',
            },
            overPrintability: {
              conventionalOverprinting: 'for conventional overprinting',
              uvOverprinting: 'for UV overprinting',
              tonerSystem: 'toner system',
              hpIndigo: 'HP-Indigo (Elektro Ink)',
              VERY_GOOD_SUITABILITY: 'highly suitability',
              GOOD_SUITABILITY: 'very suitable',
              SUITABLE: 'suitable',
              NOT_SUITABLE: 'unsuitable',
            }
          }
        },
        productDocumentsTab: {
          title: 'Documents',
          technicalSpecification: 'Technical Specification for this product',
          productInformation: 'Detailed product informationen',
          deinkability: 'Deinkability for this product',
          compostability: 'Compostability for this product',
        },
        productStockSalesTab: {
          title: 'Stock Rolls',
        },
        productPricesTab: {
          title: 'Further Prices',
          columnHeaders: {
            priceBaseTerm: 'Price type',
            materialNumber: 'Material number',
            priceBase: 'Price Base',
            unit: 'Unit',
            price: 'Price'
          },
          priceEntry: {
            STANDARD: 'Base price',
            BEST_IN_CLASS: 'Best in Class',
            TAILOR_MADE: 'Tailor Made',
            SCALE_PRICE: 'Scale prices',
            PROJECT: 'Project price',
            CONTRACT: 'Contract',
            configuration: '1 Configuration',
            configurations: '{{n}} Configurations',
            selectConfiguration: 'Select Configuration',
            applyContractConfiguration: 'Apply Configuration',
          }
        },
      }
    }
  },
  de: {
    pdp: {
      orderForm: {
        materialNumber: 'Materialnummer',
        dreamcomposerModal: {
          header: '3D-Darstellung',
          buttonLabel: '3D',
          environmentsLabel: 'Umgebungen',
          backgroundsLabel: 'Hintergrund',
          geometriesLabel: 'Geometrie',
          modalContentHTML: `
            <div>Die 3D-Darstellung unserer Produkte wird durch unser innovatives Tool DREAMCOMPOSER® erzeugt. DREAMCOMPOSER® bietet eine realitätsnahe, interaktive Produktansicht, welche optimal bei der Auswahl von Oberflächenveredelungen unterstützt.</div>
            <div>Erfahren Sie mehr über DREAMCOMPOSER® und seine vielfältigen Möglichkeiten auf <a target="_blank" href="https://www.dreamcomposer.de/de/">Dreamcomposer.de</a>.</div>
          `
        },
        summaryBox: {
          productGroup: 'Produktgruppe',
          quality: 'Qualität',
          basePriceOnRequest: 'Preis auf Anfrage',
          basePrice: 'Grundpreis',
          showFurtherPrices: 'Weitere Preise anzeigen',
          technicalSpecification: 'Technische Spezifikation'
        },
        anonymous: {
          heading: 'Melden Sie sich an, um Ihre exklusiven Kundenvorteile zu nutzen:',
          subHeading: 'Profitieren Sie von einer personalisierten Einkaufserfahrung!',
          prices: 'Persönliche Preise',
          configs: 'Eigene Produktkonfigurationen sowie Einsicht in Lagerrollen-Bestand',
          documents: 'Download produktspezifischer Dokumente',
        },
        noStocklevelForStockOnlyProduct: 'Für dieses Produkt ist kein Bestand verfügbar.',
        quantity: 'Anzahl',
        confirmQuantityModal: {
          title: 'Bestätigen Sie',
          question: 'Sind Sie sich sicher, dass Sie {{quantity}} Rollen bestellen wollen?'
        },
        totalArea: 'Gesamtfläche',
        priceBase: 'Preisgrundlage',
        totalPrice: 'Gesamtpreis',
        addToCartButton: 'In den Warenkorb',
        requestPrice: 'Preis anfragen',
        informationModal: {
          plastics: {
            width: {
              infoText: 'Bei Auswahl einer Sonderbreite können nur die unter “Anzahl” vorgeschlagenen Mengen bestellt werden.'
            },
            lengthFinishingTypeCore: {
              title1: 'Standard Cut',
              paragraph1: 'Wählen Sie unseren Basis-Schnitt für Ihre Heißprägefolie. Saubere Schnittkanten, akkurate Konfektionierung bis zu max. 305 m Lauflänge sowie eine gewohnt schnelle und zuverlässige Lieferung sind die Vorzüge unseres Standard-Cuts.',
              title2: 'Premium Cut',
              paragraph2: 'Profitieren Sie von unserem Premium-Schnitt: Extrem scharfe Schnittkanten, extralange Lauflänge und minimale Flake-Bildung dank modernster Rollenschneider, das Umrollen Ihrer Zuschnitte ist inklusive. Perfektes Abrollverhalten für eine reibungslose Weiterverarbeitung ist damit garantiert.'
            },
            quantity: { infoText: 'Aufgrund der gewählten Sonderbreite können Sie nur die unter “Anzahl” vorgeschlagenen Mengen bestellen.' },
          },
          graphics: {
            core: { infoText: '' },
            width: { infoText: '' },
            length: { infoText: '' },
            quantity: { infoText: '' },
          }
        },
        requestPriceModal: {
          header: 'Kontaktanfrage',
          dateLabel: 'Datum',
          topicLabel: 'Betreff',
          topicValue: 'Preisangebot',
          productLabel: 'Produkt',
          commentLabel: 'Kommentar',
          commentPlaceholder: 'Bitte geben Sie Ihre Anfrage ein'
        },
        confirmRollContractConfigModal: {
          textForOne: 'Dieser Rollenkontrakt setzt die folgende Folienkonfiguration voraus:',
          textForMany: 'Dieser Rollenkontrakt setzt eine der folgenden Folienkonfigurationen voraus:',
          questionForOne: 'Möchten Sie diese Konfiguration jetzt übernehmen?',
          questionForMany: 'Möchten Sie die selektierte Konfiguration jetzt übernehmen?'
        },
        secondaryButtons: {
          openRequestModal: {
            label: 'Anfrage an Kundenberater',
            subjectValue: 'Anfrage'
          },
          seeProductStockLabel: 'Verfügbare Lagerrollen ansehen',
          openProductSampleRequestModal: {
            label: 'Produktmuster anfragen',
            subjectValue: 'Produktmuster'
          },
          seeProductContractsLabel: 'Rahmenverträge',
          compareProduct: 'Produkt vergleichen',
        },
        toProduct: 'Zum Produkt',
      },
      tabBar: {
        productInformationTab: {
          title: 'Informationen',
          recyclable: {
            heading: 'Nachhaltigkeit',
          },
          quality: {
            heading: 'Qualität',
            uspHeading: 'Wesentliche Merkmale',
            colorHeading: 'Farbe',
            substrateHeading: 'Substrate',
            applicationExamplesHeading: 'Anwendungsbeispiele',
            processingHeading: 'Verarbeitung',
            processingTempsHeading: 'Verarbeitungstemperaturen',
            motifHeading: 'Motive',
            resistanceHeading: 'Beständigkeit',
            washResistanceHeading: 'Waschbeständigkeit',
            overPrintabilityHeading: 'Überdruck- und Lackierbarkeit',
            motifs: {
              FINE: 'fein',
              MEDIUM: 'mittel',
              AREAL: 'flächig',
              RELIEF: 'relief',
            },
            resistance: {
              bulkResistance: 'Füllgutbeständigkeit',
              adhesion: 'Haftung',
              edgeDefinition: 'Randschärfe',
              definition: 'Ausprägung',
              coverage: 'Deckkraft',
              alcoholResistance: 'Alkoholbeständigkeit',
              resistanceDryCleaning: 'Beständigkeit chemische Reinigung',
              scratchResistance: 'Kratzbeständigkeit',
              abrasionResistance: 'Abriebsfestigkeit',
              overStampability: 'Überprägbarkeit',
              VERY_CLEAN: 'sehr sauber',
              CLEAN: 'sauber',
              NOT_CLEAN: 'unsauber',
              VERY_RESISTANT: 'sehr beständig',
              RESISTANT: 'beständig',
              NOT_RESISTANT: 'nicht beständig',
              VERY_GOOD: 'sehr gut',
              GOOD: 'gut',
              NOT_SUITABLE: 'nicht geeignet',
              POSSIBLE: 'möglich',
              CONDITIONALLY_POSSIBLE: 'bedingt möglich',
              NOT_POSSIBLE: 'nicht möglich',
              NOT_TESTED: 'nicht getestet',
              CYCLES_10_AT_40_C: '10 Waschgänge bei 40 °C',
              handWashing: 'Handwäsche (30 °C)',
              machineWashing: 'Maschinenwäsche 30-40 °C',
              numberOfWashingCycles: 'Anzahl der Waschdurchgänge',
            },
            overPrintability: {
              conventionalOverprinting: 'für konventionelle Überdruckung',
              uvOverprinting: 'für UV Überdruckung',
              tonerSystem: 'Tonersystem',
              hpIndigo: 'HP-Indigo (Elektro Ink)',
              VERY_GOOD_SUITABILITY: 'sehr gut geeignet',
              GOOD_SUITABILITY: 'gut geeignet',
              SUITABLE: 'geeignet',
              NOT_SUITABLE: 'nicht geeignet',
            }
          }
        },
        productDocumentsTab: {
          title: 'Dokumente',
          technicalSpecification: 'Technische Spezifikation für dieses Produkt',
          productInformation: 'Detaillierte Produktinformationen',
          deinkability: 'Deinkbarkeit für dieses Produkt',
          compostability: 'Kompostierbarkeit für dieses Produkt',
        },
        productStockSalesTab: {
          title: 'Lagerrollen',
        },
        productPricesTab: {
          title: 'Weitere Preise',
          columnHeaders: {
            priceBaseTerm: 'Preisbezeichnung',
            materialNumber: 'Materialnummer',
            priceBase: 'Preisgrundlage',
            unit: 'Einheit',
            price: 'Preis'
          },
          priceEntry: {
            STANDARD: 'Grundpreis',
            BEST_IN_CLASS: 'Best in Class',
            TAILOR_MADE: 'Tailor Made',
            SCALE_PRICE: 'Staffelpreise',
            PROJECT: 'Projektpreis',
            CONTRACT: 'Rahmenvertrag',
            selectConfiguration: 'Konfiguration auswählen',
            configuration: '1 Konfiguration',
            configurations: '{{n}} Konfigurationen',
            applyContractConfiguration: 'Konfiguration übernehmen'
          }
        },
      }
    },
  },
  fr: {
    pdp: {
      orderForm: {
        materialNumber: 'Code article',
        dreamcomposerModal: {
          header: 'Représentation 3D',
          buttonLabel: '3D',
          environmentsLabel: 'Environnements',
          backgroundsLabel: 'Arrière-plan',
          geometriesLabel: 'Géométrie',
          modalContentHTML: `
            <div>La visualisation en 3D de nos produits est générée par notre outil innovant DREAMCOMPOSER®. DREAMCOMPOSER® offre une vue interactive des produits proche de la réalité, qui aide de façon très efficace à choisir les types de finitions de surface.</div>
            <div>Apprenez-en plus sur DREAMCOMPOSER® et ses multiples possibilités sur <a target="_blank" href="https://www.dreamcomposer.de/fr/">Dreamcomposer.de</a>.</div>`
        },
        summaryBox: {
          productGroup: 'Groupe de produit',
          quality: 'Qualité',
          basePriceOnRequest: 'Prix sur demande',
          basePrice: 'Prix de base',
          showFurtherPrices: 'Indiquer d\'autres prix',
          technicalSpecification: 'Spécification technique'
        },
        anonymous: {
          heading: 'Connectez-vous pour profiter de vos avantages clients exclusifs :',
          subHeading: 'Profitez d\'une expérience d\'achat personnalisée !',
          prices: 'Tarifs personnalisés',
          configs: 'Configurations de produits sur mesure et accès au stock de rouleaux',
          documents: 'Téléchargement de documents spécifiques aux produits',
        },
        noStocklevelForStockOnlyProduct: 'Pour ce produit pas de stock disponible.',
        quantity: 'Quantité',
        confirmQuantityModal: {
          title: 'Confirmez',
          question: 'Vous êtes sûr de vouloir commander {{quantity}} rouleaux?'
        },
        totalArea: 'Surface',
        priceBase: 'Base de prix',
        totalPrice: 'Total (HT)',
        addToCartButton: 'Ajouter à mon panier',
        requestPrice: 'Demander le prix',
        informationModal: {
          plastics: {
            width: {
              infoText: 'Si vous choisissez une laize non standard, vous ne pouvez commander sous "quantité" que les quantités proposées.'
            },
            lengthFinishingTypeCore: {
              title1: 'Coupe standard',
              paragraph1: 'Choisissez une coupe de base pour vos films de marquage à chaud. Les avantages de nos coupes standards sont : des bords propres, une confection exacte pour une longueur de 305m maximum, ainsi qu\'une livraison habituellement rapide et fiable.',
              title2: 'Coupe premium',
              paragraph2: 'Bénéficiez de notre coupe premium : des bords très nets, des longueurs de rlx plus longues et la création minimale de paillettes grâce à nos slitteurs très modernes. L\'enroulage de vos découpes est inclus. Un déroulage parfait du film pour un traitement ultérieur impeccable est garanti.'
            },
            quantity: { infoText: 'Si vous choisissez une laize non standard, vous ne pouvez commander par "quantité" que les quantités proposées.' },
          },
          graphics: {
            core: { infoText: '' },
            width: { infoText: '' },
            length: { infoText: '' },
            quantity: { infoText: '' },
          }
        },
        requestPriceModal: {
          header: 'Demande de contact',
          dateLabel: 'Date',
          topicLabel: 'Objet',
          topicValue: 'Offre de prix',
          productLabel: 'Produit',
          commentLabel: 'Commentaire',
          commentPlaceholder: 'Merci de saisir votre demande'
        },
        confirmRollContractConfigModal: {
          textForOne: 'FR:This roll contract is only valid for the following configuration:',
          textForMany: 'FR:This roll contract is only valid for one of the following configurations:',
          questionForOne: 'FR:Would you like to apply this configuration?',
          questionForMany: 'FR:Would you like to apply the selected configuration?'
        },
        secondaryButtons: {
          openRequestModal: {
            label: 'Demander à votre interlocuteur Kurz',
            subjectValue: 'Demande'
          },
          seeProductStockLabel: 'Consulter les rlx disponibles',
          openProductSampleRequestModal: {
            label: 'Demander un échantillon',
            subjectValue: 'Échantillon de produit'
          },
          seeProductContractsLabel: 'Commandes sur appel',
          compareProduct: 'Comparer les produits',
        },
        toProduct: 'Vers le produit',
      },
      tabBar: {
        productInformationTab: {
          title: 'Informations',
          quality: {
            heading: 'Qualité',
            uspHeading: 'Caractéristiques essentielles',
            colorHeading: 'Couleur',
            substrateHeading: 'Substrats',
            applicationExamplesHeading: 'Exemples d\'application',
            processingHeading: 'Traitement',
            processingTempsHeading: 'Températures de traitement',
            motifHeading: 'Motifs',
            resistanceHeading: 'Résistance',
            washResistanceHeading: 'Résistance au lavage',
            overPrintabilityHeading: 'Possibilité de surimpression et de peinture',
            motifs: {
              FINE: 'fin',
              MEDIUM: 'moyen',
              AREAL: 'á plat',
              RELIEF: 'relief',
            },
            resistance: {
              bulkResistance: 'résistance au jus',
              adhesion: 'adhésion',
              edgeDefinition: 'précision des bords',
              definition: 'netteté du marquage',
              coverage: 'couvrance',
              alcoholResistance: 'résistance à l\'alcool',
              resistanceDryCleaning: 'résistance au nettoyage chimique',
              scratchResistance: 'résistance au grattage',
              abrasionResistance: 'résistance à l\'abrasion',
              overStampability: 'surmarquage',
              VERY_CLEAN: 'pas propre',
              CLEAN: 'très propre',
              NOT_CLEAN: 'not clean',
              VERY_RESISTANT: 'très résistant',
              RESISTANT: 'résistant ',
              NOT_RESISTANT: 'non résistant',
              VERY_GOOD: 'très bien',
              GOOD: 'bien',
              NOT_SUITABLE: 'pas adapté',
              POSSIBLE: 'possible',
              CONDITIONALLY_POSSIBLE: 'possible sous réserve',
              NOT_POSSIBLE: 'impossible',
              NOT_TESTED: 'pas testé',
              CYCLES_10_AT_40_C: '10 passages à 40 °C',
              handWashing: 'Lavage à la main (30 °C)',
              machineWashing: 'Lavage en machine 30-40 °C',
              numberOfWashingCycles: 'Nombre de cycles de lavage',

            },
            overPrintability: {
              conventionalOverprinting: 'pour surimpression conventionnelle',
              uvOverprinting: 'pour surimpression UV',
              tonerSystem: 'système toner',
              hpIndigo: 'HP-Indigo (Elektro Ink)',
              VERY_GOOD_SUITABILITY: 'très bien adapté',
              GOOD_SUITABILITY: 'bien adapté',
              SUITABLE: 'adapté',
              NOT_SUITABLE: 'pas adapté',
            }
          }
        },
        productDocumentsTab: {
          title: 'Documents',
          technicalSpecification: 'Spécification technique pour ce produit',
          productInformation: 'Information du produit détaillé',
          deinkability: 'Déincabilité de ce produit',
          compostability: 'Compostabilité de ce produit',
        },
        productStockSalesTab: {
          title: 'rouleaux en stock',
        },
        productPricesTab: {
          title: 'D\'autres prix',
          columnHeaders: {
            priceBaseTerm: 'Type de prix',
            materialNumber: 'Code article',
            priceBase: 'Prix de base',
            unit: 'Unité',
            price: 'Prix'
          },
          priceEntry: {
            STANDARD: 'Prix de base',
            BEST_IN_CLASS: 'Best in Class',
            TAILOR_MADE: 'Tailor Made',
            SCALE_PRICE: 'Prix échelonnés',
            PROJECT: 'Prix de projet',
            CONTRACT: 'Commande sur appel',
            selectConfiguration: 'Choisir la combinaison',
            configuration: '1 combinaison',
            configurations: '{{n}} combinaisons',
            applyContractConfiguration: 'Accepter la combinaison'
          }
        },
      }
    },
  },
  nl: {
    pdp: {
      orderForm: {
        materialNumber: 'Artikelnummer',
        dreamcomposerModal: {
          header: '3D-productweergave',
          buttonLabel: '3D',
          environmentsLabel: 'Omgevingen',
          backgroundsLabel: 'Achtergrond',
          geometriesLabel: 'Geometrie',
          modalContentHTML: `
          <div>De 3d-weergave van onze producten wordt gegenereerd door onze innovatieve tool DREAMCOMPOSER®. DREAMCOMPOSER® biedt een realistische, interactieve productweergave die de keuze van de oppervlakteafwerking optimaal ondersteund.</div>
          <div>Lees meer over DREAMCOMPOSER® en de diverse mogelijkheden op <a target="_blank" href="https://www.dreamcomposer.de/nl/">Dreamcomposer.de</a>.</div>`
        },
        summaryBox: {
          productGroup: 'Productgroep',
          quality: 'Kwaliteit',
          basePriceOnRequest: 'Prijs op aanvraag',
          basePrice: 'Basisprijs',
          showFurtherPrices: 'Meer prijzen aangeven',
          technicalSpecification: 'Technische specificatie'
        },
        anonymous: {
          heading: 'Meld u aan om van uw exclusieve klantvoordelen te profiteren:',
          subHeading: 'Profiteer van een gepersonaliseerde winkelervaring!',
          prices: 'Persoonlijke prijzen',
          configs: 'Aangepaste productconfiguraties en toegang tot de voorraadrollen',
          documents: 'Download van productspecifieke documenten',
        },
        noStocklevelForStockOnlyProduct: 'Er is geen voorraad beschikbaar van dit product.',
        quantity: 'Aantal',
        confirmQuantityModal: {
          title: 'bevestig',
          question: 'Weet u zeker dat u {{quantity}} rollen wilt bestellen'
        },
        totalArea: 'Oppervlakte',
        priceBase: 'Prijs basis',
        totalPrice: 'Totaal prijs (netto)',
        addToCartButton: 'Aan winkelwagentje toevoegen',
        requestPrice: 'Prijsaanvraag',
        informationModal: {
          plastics: {
            width: {
              infoText: 'Bij de keuze van een niet standaard breedte, kan alleen de onder "Aantal" voorgestelde hoeveelheden worden besteld.'
            },
            lengthFinishingTypeCore: {
              title1: 'Standaard snit',
              paragraph1: 'Kies onze basissnit voor uw warmdruk transferfolie. Schone snijranden, nauwkeurige verpakking tot een maximale lengte van 305 m en de gebruikelijke snelle en betrouwbare levering zijn de voordelen van onze standaard snede.',
              title2: 'Premium snit',
              paragraph2: 'Profiteer van onze premium snit: Extreem scherpe snijkanten, extra lange lengtes en minimale "flakevorming" dankzij de modernste rollensnijmachines, de gewenste breedte is inclusief. Perfecte confectie voor een optimale verdere afwerking is gegarandeerd.'
            },
            quantity: { infoText: 'Vanwege de speciaal geselcteerde breedte kunt u alleen de aangegeven hoeveelheid bestellen die onder "Aantal" worden vermeld.' },
          },
          graphics: {
            core: { infoText: '' },
            width: { infoText: '' },
            length: { infoText: '' },
            quantity: { infoText: '' },
          }
        },
        requestPriceModal: {
          header: 'Contactaanvraag',
          dateLabel: 'Datum',
          topicLabel: 'Onderwerp',
          topicValue: 'aanbieding',
          productLabel: 'Product',
          commentLabel: 'opmerking',
          commentPlaceholder: 'Geef hier uw verzoek in'
        },
        confirmRollContractConfigModal: {
          textForOne: 'NL:This roll contract is only valid for the following configuration:',
          textForMany: 'NL:This roll contract is only valid for one of the following configurations:',
          questionForOne: 'NL:Would you like to apply this configuration?',
          questionForMany: 'NL:Would you like to apply the selected configuration?'
        },
        secondaryButtons: {
          openRequestModal: {
            label: 'Contacteer accountmanager',
            subjectValue: 'Aanvraag'
          },
          seeProductStockLabel: 'Controleer beschikbare voorraadrollen',
          openProductSampleRequestModal: {
            label: 'Sample rol aanvragen',
            subjectValue: 'product monster'
          },
          seeProductContractsLabel: 'Raamcontract',
          compareProduct: 'Product vergelijken',
        },
        toProduct: 'Naar het product',
      },
      tabBar: {
        productInformationTab: {
          title: 'informaties',
          quality: {
            heading: 'Kwaliteit',
            uspHeading: 'Belangrijkste kenmerken',
            colorHeading: 'Kleur',
            substrateHeading: 'Substraten',
            applicationExamplesHeading: 'Toepassingsvoorbeelden',
            processingHeading: 'Verwerking',
            processingTempsHeading: 'Verwerkingstemperaturen',
            motifHeading: 'Motieven',
            resistanceHeading: 'Consistentie',
            washResistanceHeading: 'Wasbestendigheid',
            overPrintabilityHeading: 'Overdruk en overschilderbaarheid',
            motifs: {
              FINE: 'fijn',
              MEDIUM: 'middel',
              AREAL: 'vlak',
              RELIEF: 'reliëf',
            },
            resistance: {
              bulkResistance: 'inhoud bestendigheid',
              adhesion: 'hechting',
              edgeDefinition: 'randscherpte',
              definition: 'afdruk',
              coverage: 'dekkracht',
              alcoholResistance: 'alcoholbestendigheid',
              resistanceDryCleaning: 'bestendigheid chemische reiniging',
              scratchResistance: 'krasbestendigheid',
              abrasionResistance: 'slijtvastheid',
              overStampability: 'overdrukbaarheid',
              VERY_CLEAN: 'zeer schoon',
              CLEAN: 'schoon',
              NOT_CLEAN: 'niet schoon',
              VERY_RESISTANT: 'zeer bestendig',
              RESISTANT: 'bestendig',
              NOT_RESISTANT: 'niet bestendig',
              VERY_GOOD: 'zeer goed',
              GOOD: 'goed',
              NOT_SUITABLE: 'niet geschikt',
              POSSIBLE: 'mogelijk',
              CONDITIONALLY_POSSIBLE: 'voorwaardelijk mogelijk',
              NOT_POSSIBLE: 'niet mogelijk',
              NOT_TESTED: 'niet getest',
              CYCLES_10_AT_40_C: '10 wasbeurten op 40 °C',
              handWashing: 'handwas (30 °C)',
              machineWashing: 'wasmachine 30-40 °C',
              numberOfWashingCycles: 'aantal wasbeurten',
            },
            overPrintability: {
              conventionalOverprinting: 'voor conventioneel overdrukken',
              uvOverprinting: 'voor UV overdrukken',
              tonerSystem: 'toner systeem',
              hpIndigo: 'HP-Indigo (elektro-inkt)',
              VERY_GOOD_SUITABILITY: 'zeer goed geschikt',
              GOOD_SUITABILITY: 'goed geschikt',
              SUITABLE: 'geschikt',
              NOT_SUITABLE: 'niet geschikt',
            }
          }
        },
        productDocumentsTab: {
          title: 'Documenten',
          technicalSpecification: 'Technische specificatie voor dit product',
          productInformation: 'Gedetailleerde productinformatie',
          deinkability: 'De-inktbaarheid voor dit product',
          compostability: 'Composteerbaarheid voor dit product',
        },
        productStockSalesTab: {
          title: 'voorraadrollen',
        },
        productPricesTab: {
          title: 'Meer prijzen',
          columnHeaders: {
            priceBaseTerm: 'prijsopgave',
            materialNumber: 'Artikelnummer',
            priceBase: 'Prijs basis',
            unit: 'Eenheid',
            price: 'Prijs'
          },
          priceEntry: {
            STANDARD: 'Basisprijs',
            BEST_IN_CLASS: 'Best in Class',
            TAILOR_MADE: 'Tailor Made',
            SCALE_PRICE: 'Staffelprijs',
            PROJECT: 'Projectprijs',
            CONTRACT: 'Raamcontract',
            selectConfiguration: 'Configuratie selecteren',
            configuration: '1 Configuratie',
            configurations: '{{n}} Configuraties',
            applyContractConfiguration: 'Configuratie toepassen'
          }
        },
      }
    },
  },
  en_US: {
    pdp: {
      tabBar: {
        productInformationTab: {
          quality: {
            motifHeading: 'Coverage'
          }
        }
      }
    }
  },
  chunks: ['pdp']
};
