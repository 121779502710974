
export const savedCartsTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    savedCarts: {
      title: 'Saved Carts',
      searchPlaceholder: 'Search...',
      noSavedCartsMainText: 'No draft and favorite orders have been created yet',
      noSavedCartsSubText: 'noSavedCartsSubText',
      noSavedCartsFoundText: 'No result was found for your search criterion. Please adjust your search',
      stateText: '{{current}} of {{total}} Saved Carts',
      panel: {
        addAllToCartButton: 'Add to cart',
        onRenameCartSuccess: 'Cart successfully renamed',
        onRenameCartError: 'There was a problem at renaming the Cart',
        confirmDeleteCartTitle: 'Delete saved cart',
        confirmDeleteCartQuestion: 'Would you like to delete this saved cart?',
        onDeleteCartSuccess: 'Cart successfully deleted',
        onDeleteCartError: 'There was a problem at delete the Cart',
        onAddToCartSuccess: 'Items successfully added to cart',
        onAddToCartError: 'Error at adding items to cart',
      },
      cells: {
        product: {
          addUserNumberAndReference: 'Add MaterialNo / Reference'
        },
        foilConfiguration: {},
        entryConfiguration: {},
        price: {},
        actions: {
          confirmDeleteTitle: 'Delete product',
          confirmDeleteQuestion: 'Would you like to delete this product from the saved cart?',
          //TODO: check with PO if this text is ok or if it is even necessary.
          confirmDeleteLastProduct: 'This is the last product in the cart, if you delete it the whole cart will be deleted, do you still want to delete it?',
          onProductDeleteSuccess: 'The product was successfully deleted',
          onProductDeleteError: 'There was a problem at delete the product'
        }
      },
      warnings: {
        hasStockPositions: 'You have Stock sale positions on your cart, those will not be saved!',
        hasOnlyStockPositions: 'Stock items cannot be saved as an order favorite.'
      }
    }
  },
  // -------------------------- DE
  de: {
    savedCarts: {
      title: 'Bestellfavoriten',
      searchPlaceholder: 'Suche...',
      noSavedCartsMainText: 'Es wurden noch keine Bestellfavoriten angelegt',
      noSavedCartsSubText: 'noSavedCartsSubText',
      noSavedCartsFoundText: 'Für Ihr Suchkriterium wurde kein Ergebnis gefunden. Bitte passen Sie Ihre Suche an',
      stateText: '{{current}} von {{total}} Bestellfavoriten',
      panel: {
        addAllToCartButton: 'In den Warenkorb',
        onRenameCartSuccess: 'Der Bestellfavorit wurde umbenannt.',
        onRenameCartError: 'Fehler beim Umbenennen des Bestellfavoriten.',
        confirmDeleteCartTitle: 'Bestellfavorit löschen',
        confirmDeleteCartQuestion: 'Möchten Sie den Bestellfavoriten löschen?',
        onDeleteCartSuccess: 'Der Bestellfavorit wurde gelöscht.',
        onDeleteCartError: 'Fehler bei der Löschung des Bestellfavorit.',
        onAddToCartSuccess: 'Artikel erfolgreich in den Warenkorb gelegt',
        onAddToCartError: 'Fehler beim Hinzufügen von Artikeln zum Warenkorb',
      },
      cells: {
        product: {
          addUserNumberAndReference: 'Materialnummer / Referenz hinzufügen'
        },
        foilConfiguration: {},
        entryConfiguration: {},
        price: {},
        actions: {
          confirmDeleteTitle: 'Produkt löschen',
          confirmDeleteQuestion: 'Möchten Sie das Produkt aus dem Bestellfavoriten löschen?',
          //TODO: check with PO if this text is ok or if it is even necessary.
          confirmDeleteLastProduct: 'Dies ist das letzte Produkt des Warenkorbs, bei Löschen wird der gesamte Warenkorb gelöscht, möchten Sie ihn trotzdem löschen?',
          onProductDeleteSuccess: 'Das Produkt wurde erfolgreich entfernt.',
          onProductDeleteError: 'Fehler bei der Löschung des Produkts.'
        }
      },
      warnings: {
        hasStockPositions: 'Sie haben Stock-Positionen in Ihrem Warenkorb, diese werden nicht in den Favoriten gespeichert.',
        hasOnlyStockPositions: 'Stock-Positionen können nicht als Bestellfavorit gespeichert werden'
      }
    }
  },
  // -------------------------- FR
  fr: {
    savedCarts: {
      title: 'Paniers sauvegardés',
      searchPlaceholder: 'Recherche...',
      noSavedCartsMainText: 'aucun favori de commande n’a encore été créé',
      noSavedCartsSubText: 'noSavedCartsSubText',
      noSavedCartsFoundText: 'Aucun résultat trouvé pour votre critère de recherche. Veuillez ajuster votre recherche.',
      stateText: '{{current}} sur {{total}} paniers sauvegardés',
      panel: {
        addAllToCartButton: 'Ajouter au panier',
        onRenameCartSuccess: 'Le panier a été renommé avec succès',
        onRenameCartError: 'Un problème est survenu lors du renommage du panier',
        confirmDeleteCartTitle: 'Supprimer le panier sauvegardé',
        confirmDeleteCartQuestion: 'Souhaitez-vous supprimer ce panier sauvegardé ?',
        onDeleteCartSuccess: 'Le panier a été supprimé avec succès',
        onDeleteCartError: 'Un problème est survenu lors de la suppression du panier',
        onAddToCartSuccess: 'Les articles ont été ajoutés au panier avec succès',
        onAddToCartError: 'Erreur lors de l\'ajout des articles au panier'
      },
      cells: {
        product: {
          addUserNumberAndReference: 'Ajouter Matricule/Référence'
        },
        foilConfguration: {},
        entryConfiguration: {},
        price: {},
        actions: {
          confirmDeleteTitle: 'Supprimer le produit',
          confirmDeleteQuestion: 'Souhaitez-vous supprimer ce produit du panier sauvegardé ?',
          confirmDeleteLastProduct: 'Il s\'agit du dernier produit dans le panier. Si vous le supprimez, l\'ensemble du panier sera supprimé. Souhaitez-vous toujours le supprimer ?',
          onProductDeleteSuccess: 'Le produit a été supprimé avec succès',
          onProductDeleteError: 'Un problème est survenu lors de la suppression du produit'
        }
      },
      warnings: {
        hasStockPositions: 'Vous avez des positions en stock dans votre panier, elles ne seront pas enregistrées dans les favoris.',
        hasOnlyStockPositions: 'Les positions en stock ne peuvent pas être enregistrées comme favori de commande.'
      }
    }
  },
  // -------------------------- NL
  nl: {
    savedCarts: {
      title: 'Opgeslagen winkelwagens',
      searchPlaceholder: 'Zoektocht...',
      noSavedCartsMainText: 'Er zijn nog geen bestelfavorieten aangemaakt',
      noSavedCartsSubText: 'noSavedCartsSubText',
      noSavedCartsFoundText: 'Geen resultaten gevonden voor uw zoekcriteria. Pas uw zoekopdracht aan',
      stateText: '{{current}} van {{total}} opgeslagen winkelwagens',
      panel: {
        addAllToCartButton: 'Toevoegen aan winkelwagen',
        onRenameCartSuccess: 'Winkelwagen succesvol hernoemd',
        onRenameCartError: 'Er is een probleem opgetreden bij het hernoemen van de winkelwagen',
        confirmDeleteCartTitle: 'Opgeslagen winkelwagen verwijderen',
        confirmDeleteCartQuestion: 'Wilt u deze opgeslagen winkelwagen verwijderen?',
        onDeleteCartSuccess: 'Winkelwagen succesvol verwijderd',
        onDeleteCartError: 'Er is een probleem opgetreden bij het verwijderen van de winkelwagen',
        onAddToCartSuccess: 'Items succesvol toegevoegd aan winkelwagen',
        onAddToCartError: 'Fout bij het toevoegen van items aan winkelwagen',
      },
      cells: {
        product: {
          addUserNumberAndReference: 'Voeg materiaalnummer / referentie toe'
        },
        foilConfiguration: {},
        entryConfiguration: {},
        price: {},
        actions: {
          confirmDeleteTitle: 'Product verwijderen',
          confirmDeleteQuestion: 'Wilt u dit product uit de opgeslagen winkelwagen verwijderen?',
          confirmDeleteLastProduct: 'Dit is het laatste product in de winkelwagen. Als u het verwijdert, wordt de hele winkelwagen verwijderd. Wilt u het nog steeds verwijderen?',
          onProductDeleteSuccess: 'Het product is succesvol verwijderd',
          onProductDeleteError: 'Er is een probleem opgetreden bij het verwijderen van het product'
        }
      },
      warnings: {
        hasStockPositions: 'U heeft voorraadposities in uw winkelwagen, deze worden niet opgeslagen in favorieten.',
        hasOnlyStockPositions: 'Voorraadposities kunnen niet als favoriete bestelling worden opgeslagen.'
      }
    }
  },
  chunks: [
    'savedCarts'
  ]
};
