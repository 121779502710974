import { Injectable } from '@angular/core';
import { I18nextTranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class KurzI18nextTranslationService extends I18nextTranslationService {

  i18nextRef = this.i18next;

  protected translationTrigger$ = new BehaviorSubject<void>(void 0);


  setFallbackLanguageMap(map: Record<string, string[]>) {
    this.i18next.options.fallbackLng = map;
  }

  override translate(key: string, options?: any, whitespaceUntilLoaded?: boolean): Observable<string> {
    const superObs = super.translate(key, options, whitespaceUntilLoaded);
    return this.translationTrigger$.pipe(switchMap(() => (superObs)));
  }

  refreshUITranslations() {
    this.translationTrigger$.next(void 0);
  }

}
