import {KurzTranslationObject} from '.';

export const headerTranslations: KurzTranslationObject = { //NOSONAR
  // -------------------------- EN
  en: {
    header: {
      burgerMenuLabel_closed: 'Customer Portal',
      burgerMenuLabel_open: 'Menu',
      serviceMenuLabel: 'ACCOUNT & INFORMATION',
      countrySelectorLabel: 'Country Selector',
      productComparison: 'Product Comparison',
      languageSelector: 'Language',

    },
  },
  // -------------------------- DE
  de: {
    header: {
      burgerMenuLabel_closed: 'Kundenportal',
      burgerMenuLabel_open: 'Menü',
      serviceMenuLabel: 'ACCOUNT & INFORMATION',
      countrySelectorLabel: 'Länder-Wahl',
      productComparison: 'Produktvergleich',
      languageSelector: 'Sprache'
    },
  },
  // -------------------------- FR
  fr: {
    header: {
      burgerMenuLabel_closed: 'Portail Clients',
      burgerMenuLabel_open: 'Menu',
      serviceMenuLabel: 'COMPTE & INFORMATION',
      countrySelectorLabel: 'Sélectionner le pays',
      productComparison: 'Comparaison des produits',
      languageSelector: 'Langue'
    },
  },
  // -------------------------- NL
  nl: {
    header: {
      burgerMenuLabel_closed: 'Klantenportaal',
      burgerMenuLabel_open: 'Menu',
      serviceMenuLabel: 'ACCOUNT & INFORMATIE',
      countrySelectorLabel: 'Landkiezer',
      productComparison: 'Productvergelijking',
      languageSelector: 'Taal'
    },
  },
  chunks: ['header']
};
