

// English translations for the forgotten password component are a part of the user profile translations
// https://github.com/SAP/spartacus .\spartacus\feature-libs\user\profile\assets\translations\en\user-profile.ts

export const forgottenPasswordTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    forgottenPassword: {
      formText: 'Please enter the email address associated with your account. Instructions to reset the password will be sent to this email address.',
      resetPassword: 'Reset Password',
      enterEmailAddressAssociatedWithYourAccount: 'Please enter the email address associated with your account',
      emailAddress: {
        label: 'Email address',
        placeholder: 'Enter email here',
      },
      enterValidEmail: 'Please enter a valid email address.',
      passwordResetEmailSent:
      'Instructions to reset the password have been sent to the provided email address.',
      passwordResetSuccess: 'Success! You can now log in with the new password.',
    },
  },
  // -------------------------- DE
  de: {
    forgottenPassword: {
      formText: 'Bitte geben Sie die E-Mail-Adresse des Kontos ein. Eine Anleitung zum Zurücksetzen des Passworts wird an diese E-Mail-Adresse gesendet.',
      resetPassword: 'Passwort zurücksetzen',
      enterEmailAddressAssociatedWithYourAccount: 'Bitte geben Sie die E-Mail-Adresse des Kontos ein',
      emailAddress: {
        label: 'E-Mail-Adresse',
        placeholder: 'E-Mail hier eingeben',
      },
      enterValidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      passwordResetEmailSent:
        'Eine Anleitung zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.',
      passwordResetSuccess: 'Passwort erfolgreich zurückgesetzt. Sie können sich nun mit dem neuen Passort anmelden.',
    },
  },
  // -------------------------- FR
  fr: {
    forgottenPassword: {
      formText: 'Veuillez saisir l\'adresse-mail du compte. Des instructions pour réinitialiser le mot de passe seront envoyées à cette adresse-mail.',
      resetPassword: 'Réinitialiser le mot de passe',
      enterEmailAddressAssociatedWithYourAccount: 'Veuillez saisir l\'adresse-mail du compte.',
      emailAddress: {
        label: 'Adresse-mail',
        placeholder: 'Merci d\'entrer l\'adresse-mail ici',
      },
      enterValidEmail: 'Merci d\'entrer une adresse-mail valable',
      passwordResetEmailSent:
        'Des instructions pour réinitialiser le mot de passe ont été envoyées à l\'adresse-mail indiquée.',
      passwordResetSuccess: 'OK! Vous pouvez vous connecter avec votre nouveau mot de passe.',
    },
  },
  // -------------------------- NL
  nl: {
    forgottenPassword: {
      formText: 'Voer het e-mailadres van het account in. Instructies voor het opnieuw instellen van het wachtwoord worden naar dit e-mailadres verzonden.',
      resetPassword: 'Wachtwoord resetten',
      enterEmailAddressAssociatedWithYourAccount: 'Voer het e-mailadres van het account in',
      emailAddress: {
        label: 'e-mailadres',
        placeholder: 'Voer hier e-mailadres in',
      },
      enterValidEmail: 'Gelieve een geldig e-mailadres in te geven.',
      passwordResetEmailSent:
        'Instructies voor het opnieuw instellen van het wachtwoord zijn verzonden naar het opgegeven e-mailadres.',
      passwordResetSuccess: 'Succes! U kunt nu inloggen met het nieuwe wachtwoord.',
    },
  },
  chunks: [
    'forgottenPassword'
  ]
};
