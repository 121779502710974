
export const orderHistoryTranslations = { //NOSONAR
  // -------------------------- en
  en: {
    orders: {
      title: 'Order History',
      noOrders: 'No orders for your search criteria. Please adjust your search.',
      emptyRecord: 'We have no order records for this account.',
      code: 'KURZ Order No.',
      orderReference: 'Order Reference',
      myReference: 'My Reference',
      myMaterialNumber: 'My Material Number',
      totalNet: 'Total (Net):',
      total: 'Total:',
      statusLabel: 'Status',
      status: {
        new: 'open',
        open: 'open',
        created: 'open',
        in_progress: 'In Progress',
        processing: 'In Progress',
        completed: 'completed',
        partially_delivered: 'partial delivery',
        cancelled: 'canceled',
        sap_create_order_error: 'SAP Error',
        processing_error: 'open',
        blocked_customer: 'In Progress',
      },
      quantity: 'Quantity',
      area: 'Area',
      requestedDate: 'Requested delivery date',
      deliveryDate: 'Delivery date',
      cancellationDate: 'Cancellation date',
      textFilterPlaceholder: 'Search',
      periodSearch: 'Period',
      searchPast6Months: 'Period: past 6 Months',
      olderOrders: 'Older Orders',
      stateText: '{{current}} of {{total}} Orders',
      date: {
        prefixMonth: 'Month',
        prefixWeek: 'Week',
        arriving: '',
        outgoing: 'outgoing',
        missing: 'Date follows',
        approximately: 'approx.'
      },
      details: {
        title: 'Order Details',
        materialNumber: 'Material Number',
        orderDate: 'Order Date',
        deliveryMethod: 'Delivery Method',
        fullDelivery: 'Complete Delivery',
        orderedBy: 'Ordered by',
        conditions: 'Delivery Conditions',
        detailedCalc: 'Detailed price calculation',
        subtotal: 'Subtotal',
        minQuantitySurcharge: 'Minimum quantity surcharge',
        minValueSurcharge: 'Minimum value surcharge',
        orderConfirmation: 'Order confirmation',
        invoice: 'Invoice',
        saveAsFavourite: {
          label: 'Save as favorite',
          success: 'Saved successfully',
          failure: 'Saving failed'
        },
        addToCart: 'Add to cart',
        registerComplaint: 'Register Complaint',
        noPartialDelivery: 'No partial delivery',
        techSpec: 'Tech. Specification',
        deliveryInfo: 'Delivery and Invoicing',
        buyAgain: 'Buy again',
        noReorder: 'Reorder not possible',
        positionPriceNet: 'Position price (net)',
        deliveryHeader: 'Delivery and Invoicing',
        deliveryAddress: 'Delivery Address',
        billingAddress: 'Invoice Address',
        deliveryNote: 'Delivery Note',
        packingList: 'Packing List',
        deliveryInvoice: 'Invoice',
        creditNote: 'Credit Note',
        downloadAll: 'Download all',
        reorderNotpossible: 'Reorder not possible',
        complaintRegistered: 'Complaint registered',
        orderRequestModal: {
          header: 'Contact Request',
          dateLabel: 'Date',
          orderNr: 'KURZ Order Nr',
          orderReference: 'Order Reference',
          commentLabel: 'Your request',
          commentPlaceholder: 'Please type your request'
        }
      }
    }
  },
  // -------------------------- en_GB
  en_GB: {},
  // -------------------------- en_US
  en_US: {},
  // -------------------------- de
  de: {
    orders: {
      title: 'Meine Bestellungen',
      noOrders: 'Für Ihre Suchkriterien wurde kein Ergebnis gefunden. Bitte passen Sie Ihre Suche an',
      emptyRecord: 'Es sind noch keine Bestellungen vorhanden.',
      code: 'KURZ Auftragsnr.',
      orderReference: 'Bestellreferenz',
      myReference: 'Meine Rereferenz',
      myMaterialNumber: 'Meine Materialnummer',
      totalNet: 'Gesamtsumme (Net):',
      total: 'Gesamtsumme:',
      statusLabel: 'Status',
      status: {
        new: 'offen',
        open: 'offen',
        created: 'offen',
        in_progress: 'in Arbeit',
        processing: 'in Arbeit',
        completed: 'erledigt',
        partially_delivered: 'teilgeliefert',
        cancelled: 'storniert',
        sap_create_order_error: 'SAP Error',
        processing_error: 'offen',
        blocked_customer: 'in Arbeit',
      },
      quantity: 'Anzahl',
      area: 'Gesamtfläche',
      requestedDate: 'Wunschtermin',
      deliveryDate: 'Liefertermin',
      cancellationDate: 'Stornierungsdatum',
      textFilterPlaceholder: 'Suche',
      periodSearch: 'Zeitraum',
      searchPast6Months: 'Zeitraum: letzten 6 Monate',
      olderOrders: 'Ältere Bestellungen',
      stateText: '{{current}} von {{total}} Bestellungen',
      date: {
        prefixMonth: 'Monat',
        prefixWeek: 'Woche',
        arriving: 'eintreffend',
        outgoing: 'abgehend',
        missing: 'Termin folgt',
        approximately: 'ca.'
      },
      details: {
        title: 'Bestelldetails',
        materialNumber: 'Materialnummer',
        orderDate: 'Bestelldatum',
        deliveryMethod: 'Liefermethode',
        fullDelivery: 'Komplettlieferung',
        orderedBy: 'Bestellt von',
        conditions: 'Lieferkonditionen',
        detailedCalc: 'Detaillierte Preisberechnung',
        subtotal: 'Zwischensumme',
        minQuantitySurcharge: 'Mindermengenzuschlag',
        minValueSurcharge: 'Mindestmengenzuschlag',
        orderConfirmation: 'Auftragsbestätigung',
        invoice: 'Rechnung',
        saveAsFavourite: {
          label: 'Als Bestellfavorit speichern',
          success: 'Der Bestellfavorit wurde angelegt.',
          failure: 'Fehler beim Anlegen des Bestellfavorits.'
        },
        addToCart: 'In den Warenkorb',
        registerComplaint: 'Reklamation anmelden',
        noPartialDelivery: 'Keine Teillieferung erlaubt',
        techSpec: 'Tech. Spezifikation',
        deliveryInfo: 'Liefer- und Rechnungsinformationen',
        buyAgain: 'Erneut kaufen',
        noReorder: 'Nachbestellung nicht möglich',
        positionPriceNet: 'Positionspreis (netto)',
        deliveryHeader: 'Liefer- und Rechnungsinformationen',
        deliveryAddress: 'Lieferadresse',
        billingAddress: 'Rechnungsadresse',
        deliveryNote: 'Lieferschein',
        packingList: 'Packliste',
        deliveryInvoice: 'Rechnung',
        creditNote: 'Gutschrift',
        downloadAll: 'Alle herunterladen',
        reorderNotpossible: 'Nachbestellung nicht möglich',
        complaintRegistered: 'Reklamation angemeldet',
        orderRequestModal: {
          header: 'Kontaktanfrage',
          dateLabel: 'Datum',
          orderNr: 'KURZ Auftragsnummer',
          orderReference: 'Bestellreferenz',
          commentLabel: 'Ihre Anfrage',
          commentPlaceholder: 'Bitte geben Sie Ihre Anfrage ein'
        }
      }
    }
  },
  // ----------------------------- fr
  fr: {
    orders: {
      title: 'Mes commandes',
      noOrders: 'Aucune commande ne correspond à vos critères de recherche. Veuillez ajuster votre recherche.',
      emptyRecord: 'Il n\'y a pas encore d\'historique des commandes',
      code: 'N° de commande KURZ',
      orderReference: 'Référence de commande',
      myReference: 'Ma référence',
      myMaterialNumber: 'Mon code article',
      totalNet: 'Total de la commande (HT)',
      total: 'Total:',
      statusLabel: 'Statut',
      status: {
        new: 'ouvert',
        open: 'ouvert',
        created: 'ouvert',
        in_progress: 'en cours',
        processing: 'en cours',
        completed: 'fini',
        partially_delivered: 'livraison partielle effectuée',
        cancelled: 'annulé',
        sap_create_order_error: 'SAP Error',
        processing_error: 'ouvert',
        blocked_customer: 'en cours',
      },
      quantity: 'Quantité',
      area: 'Surface',
      requestedDate: 'Date d\'expédition souhaitée',
      deliveryDate: 'Date de livraison',
      cancellationDate: 'Date d\'annulation',
      textFilterPlaceholder: 'Recherche',
      periodSearch: 'Période',
      searchPast6Months: 'Période : 6 derniers mois',
      olderOrders: 'Anciennes commandes',
      stateText: '{{current}} de {{total}} commandes',
      date: {
        prefixMonth: 'Mois',
        prefixWeek: 'Semaine',
        arriving: 'date d\'arrivée',
        outgoing: 'partant',
        missing: 'Date Ã suivre',
        approximately: 'environ'
      },
      details: {
        title: 'Détails de commande',
        materialNumber: 'Code article',
        orderDate: 'Date de commande',
        deliveryMethod: 'Conditions de livraison',
        fullDelivery: 'Livraison complète',
        orderedBy: 'Commandé par',
        conditions: 'Conditions de livraison',
        detailedCalc: 'Calcul détaillé du prix ',
        subtotal: 'SubTotal HT',
        minQuantitySurcharge: 'Supplément de quantité minimale',
        minValueSurcharge: 'Supplément de valeur minimale',
        orderConfirmation: 'Confirmation de commande',
        invoice: 'Facture',
        saveAsFavourite: {
          label: 'Enregistrer comme projet de commande',
          success: 'La projet de commande a été créée',
          failure: 'Erreur lors de la création du produit préféré'
        },
        addToCart: 'Ajouter à mon panier',
        registerComplaint: 'Déposer une réclamation',
        noPartialDelivery: 'Pas de livraison partielle',
        techSpec: 'Tech. Specification',
        deliveryInfo: 'Informations de livraison et de facture',
        buyAgain: 'Acheter de nouveau',
        noReorder: 'Commande supplémentaire impossible',
        positionPriceNet: 'Prix ligne HT',
        deliveryHeader: 'Informations de livraison et de facture',
        deliveryAddress: 'Adresse de livraison',
        billingAddress: 'Adresse de facturation',
        deliveryNote: 'Bon de livraison',
        packingList: 'Liste de colisage',
        deliveryInvoice: 'Facture',
        creditNote: 'Avoir',
        downloadAll: 'tout télécharger',
        reorderNotpossible: 'Commande supplémentaire impossible',
        complaintRegistered: 'Réclamation déposée',
        orderRequestModal: {
          header: 'Demande d\'informations',
          dateLabel: 'Date',
          orderNr: 'N° de commande KURZ',
          orderReference: 'Référence de commande',
          commentLabel: 'Votre demande',
          commentPlaceholder: 'Merci de saisir votre demande'
        }
      }
    }
  },
  // ----------------------------- fr_FR
  fr_FR: {},
  // ----------------------------- fr_CH
  fr_CH: {},
  // ----------------------------- fr_LU
  fr_LU: {},
  // ----------------------------- nl
  nl: {
    orders: {
      title: 'Mijn bestellingen',
      noOrders: 'Geen bestellingen voor uw zoekcriteria. Pas uw zoekopdracht aan.',
      emptyRecord: 'We hebben geen bestelgegevens voor dit account.',
      code: 'Mijn bestellingen',
      orderReference: 'Mijn bestellingen',
      myReference: 'Mijn referentie',
      myMaterialNumber: 'Mijn materiaalnr.',
      totalNet: 'Mijn bestellingen:',
      total: 'Totaal:',
      statusLabel: 'Status',
      status: {
        new: 'open',
        open: 'open',
        created: 'open',
        in_progress: 'in behandeling',
        processing: 'in behandeling',
        completed: 'geleverd',
        partially_delivered: 'deellevering',
        cancelled: 'geannuleerd',
        sap_create_order_error: 'SAP Error',
        processing_error: 'open',
        blocked_customer: 'in behandeling',
      },
      quantity: 'Hoeveelheid',
      area: 'Oppervlakte',
      requestedDate: 'Gevraagde afleverdatum',
      deliveryDate: 'Verwachte verzending',
      cancellationDate: 'Annuleringsdatum',
      textFilterPlaceholder: 'Zoek op',
      periodSearch: 'Periode',
      searchPast6Months: 'Periode: laatste 6 maanden',
      olderOrders: 'Oudere bestellingen',
      stateText: '{{current}} van {{total}} bestellingen',
      date: {
        prefixMonth: 'Maand',
        prefixWeek: 'Week',
        arriving: 'in huis',
        outgoing: 'Uitgaand',
        missing: 'Datum volgt',
        approximately: 'ca.'
      },
      details: {
        title: 'Besteldetails',
        materialNumber: 'Artikelnummer',
        orderDate: 'Besteldetails',
        deliveryMethod: 'Leveringswijze',
        fullDelivery: 'Volledige levering',
        orderedBy: 'Besteld door',
        conditions: 'Leveringsvoorwaarden',
        detailedCalc: 'Gedetailleerde prijsberekening',
        subtotal: 'Subtotaal',
        minQuantitySurcharge: 'Minimum hoeveelheid toeslag',
        minValueSurcharge: 'Minimumwaarde toeslag',
        orderConfirmation: 'Bestelbevestiging',
        invoice: 'Factuur',
        saveAsFavourite: 'Sla op als bestelfavoriet',
        addToCart: 'Voeg toe aan winkelwagentje',
        registerComplaint: 'Klacht registreren',
        noPartialDelivery: 'Geen gedeeltelijke levering',
        techSpec: 'Tech. Specification',
        deliveryInfo: 'Leverings- en factuurinformatie',
        buyAgain: 'Opnieuw kopen',
        noReorder: 'Nabestellen niet mogelijk',
        positionPriceNet: 'Item prijs (netto)',
        deliveryHeader: 'Leverings- en factuurinformatie',
        deliveryAddress: 'Leveringsadres',
        billingAddress: 'Factuuradres',
        deliveryNote: 'Leveringsbon',
        packingList: 'Paklijst',
        deliveryInvoice: 'Factuur',
        creditNote: 'Kredietnota',
        downloadAll: 'alles downloaden',
        reorderNotpossible: 'Nabestellen niet mogelijk',
        complaintRegistered: 'Klacht geregistreerd',
        orderRequestModal: {
          header: 'Contactaanvraag',
          dateLabel: 'Date',
          orderNr: 'KURZ bestelnr.',
          orderReference: 'Bestelreferentie',
          commentLabel: 'Uw verzoek:',
          commentPlaceholder: 'Geef hier uw verzoek in.'
        }
      }
    }
  },
  // ----------------------------- CHUNKS
  chunks: [
    'orderHistory'
  ]

};
