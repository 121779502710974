export const metaTagsTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    metaTags: {
      title: 'The KURZ customer portal',
      description: '24/7 + NEW TRENDS + MAX. TRANSPARENCY: Tired of long order processing? Order brilliant surface finishes for your decoration project easily, conveniently and quickly'
    }
  },
  // -------------------------- DE
  de: {
    metaTags: {
      title: 'Das KURZ Kundenportal',
      description: '24/7 + NEUE TRENDS + MAX. TRANSPARENZ: Keine Lust auf lange Bestellabwicklungen? Ordern Sie einfach, bequem & schnell brillante Oberflächen-Veredelungen für Ihr Dekorationsprojekt'
    }
  },
  // -------------------------- FR
  fr: {
    metaTags: {
      title: 'Le portail client de KURZ',
      description: '24/7 + NOUVELLES TENDANCES + TRANSPARENCE MAXIMUM: Des soucis avec des traitements de commandes trop longs ? Commandez simplement et rapidement des finitions de surface brillantes pour votre projet de décoration'
    }
  },
  // -------------------------- NL
  nl: {
    metaTags: {
      title: 'De KURZ klantenportaal',
      description: '24/7 + NIEUWE TRENDS + MAX. TRANSPARANTIE: Moe van de lange orderverwerking? Bestel eenvoudig, gemakkelijk en snel schitterende oppervlakteveredelingen voor uw decoratieproject'
    }
  },
  chunks: [
    'metaTags'
  ]
};
