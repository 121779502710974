
export const contractsTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    contracts: {
      title: 'Contracts',
      newRequest: 'New Request',
      label: {
        orderReference: 'Contract Name',
        orderNumber: 'KURZ Order No.',
        validity: 'Validity'
      },
      noContractsMainText: 'No result was found for your search criteria.',
      noContractsSubText: 'Please adjust your search.',
      availibility: {
        leftText: '{{ used }} of {{ sum }} ordered',
        rightText: '{{ available }} available',
      },
      price: {
        pricePerUnit: '{{ price }} / {{ translatedUnit }}',
      },
      contactModal: {
        header: 'Contact us',
        orderCodeLabel: 'KURZ Order No.',
        orderReferenceLabel: 'Order Reference',
      },
      stateText: '{{current}} of {{total}} contracts',
    }
  },
  // -------------------------- DE
  de: {
    contracts: {
      title: 'Rahmenverträge',
      newRequest: 'Neue Anfrage',
      label: {
        orderReference: 'Vertragsname',
        orderNumber: 'KURZ-Auftragsnr.',
        validity: 'Gültigkeit',
      },
      noContractsMainText: 'Für Ihr Suchkriterium wurde kein Ergebnis gefunden.',
      noContractsSubText: 'Bitte passen Sie Ihre Suche an.',
      availibility: {
        leftText: '{{ used }} von {{ sum }} bestellt',
        rightText: '{{ available }} verfügbar'
      },
      price: {
        pricePerUnit: '{{ price }} / {{ translatedUnit }}',
      },
      contactModal: {
        header: 'Kontaktanfrage',
        orderCodeLabel: 'KURZ-Auftragsnr.',
        orderReferenceLabel: 'Bestellreferenz',
      },
      stateText: '{{current}} von {{total}} Rahmenverträge',
    }
  },
  // -------------------------- FR
  fr: {
    contracts: {
      title: 'Commande sur appel',
      newRequest: 'Nouvelle demande',
      label: {
        orderReference: 'Nom de commande sur appel',
        orderNumber: 'N° de commande KURZ',
        validity: 'Validité',
      },
      noContractsMainText: 'Aucun résultat n\'a été trouvé pour votre critère de recherche',
      noContractsSubText: 'Veuillez adapter votre recherche',
      availibility: {
        leftText: 'Commandé {{ used }} sur {{ sum }}',
        rightText: '{{ available }} disponibles'
      },
      price: {
        pricePerUnit: '{{ price }} / {{ translatedUnit }}',
      },
      contactModal: {
        header: 'Demande de contact',
        orderCodeLabel: 'N° de commande KURZ',
        orderReferenceLabel: 'Référence de commande',
      },
      stateText: '{{current}} de {{total}} contrats cadres',
    }
  },
  // -------------------------- NL
  nl: {
    contracts: {
      title: 'Contracten',
      newRequest: 'Nieuwe aanvraag',
      label: {
        orderReference: 'Contractnaam',
        orderNumber: 'KURZ bestelnr.',
        validity: 'Geldigheid',
      },
      noContractsMainText: 'Voor uw zoekcriteria is geen resultaat gevonden.',
      noContractsSubText: 'Pas uw zoekopdracht aan.',
      availibility: {
        leftText: '{{ used }} van {{ sum }} besteld',
        rightText: '{{ available }} beschikbaar'
      },
      price: {
        pricePerUnit: '{{ price }} / {{ translatedUnit }}',
      },
      contactModal: {
        header: 'Contactaanvraag',
        orderCodeLabel: 'KURZ bestelnr.',
        orderReferenceLabel: 'Bestelreferentie',
      },
      stateText: '{{current}} van {{total}} raamovereenkomst',
    }
  },
  chunks: [
    'contracts'
  ]
};
