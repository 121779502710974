
export const sharedTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    foilconfig: {
      selectBoxes: {
        label: {
          core: 'Core',
          length_M: 'Length (m)',
          length_I: 'Length (ft)',
          width_M: 'Width (mm)',
          width_I: 'Width (in)',
          length_finishingType_core_M: 'Length (m) / Finishing Type / Core',
          length_finishingType_core_I: 'Length (ft) /Finishing Type / Core',
          finishingType: 'Finishing Type',
        },
        specialWidth: '(Special Width)',
        noItemsNoCoreFound: 'No suitable core available',
        noItemsFreeInput: 'Enter desired dimensions',
        noItemsStrictInput: 'No configuration found'
      },
      configMissing: 'Cannot find a suitable configuration?',
      findConfigButton: 'Check other dimensions',
      modal: {
        info: 'Your request is processed automatically. You will receive results best fitting your data entries.',
        title: 'Check other dimensions',
        requestButton: 'Check',
        header: 'Directly available configurations:',
        accept: 'Accept result',
        contact: 'Contact sales service coordinator',
        error: 'Sorry, this combination is not possible. Tip: Adjust the width to full 10mm increments to meet our technical finishing requirements.'
      }
    },
    acceptLegalDocument: {
      title: {
        termsOfUse: 'Terms of Use',
      },
      text: {
        termsOfUse: 'Please read and accept our Terms of Use before using the KURZ customer portal.',
      },
      acceptButtonLabel: {
        termsOfUse: 'Accept Terms of Use',
      },
    },
    contactModal: {
      defaultHeader: 'Contact Request',
      dateLabel: 'Date',
      subjectLabel: 'Subject',
      orderNumberLabel: 'Order Number',
      referenceNumberLabel: 'Order Reference',
      textLabel: 'Your Request',
      headerSuccessful: 'Request sent',
      headerFailed: 'Request failed',
      sentSuccessfully_1: 'Your request has been forwarded to our customer service coordinator.',
      sentSuccessfully_2: 'We will respond as quickly as possible.',
      sentFailed_1: 'Your request could not be sent.',
      sentFailed_2: 'You can try again by clicking on "Back".',
      nameLabel: 'Name',
      emailLabel: 'E-Mail',
      companyNameLabel:'Company Name',
      countryDropdownLabel: 'Country',
      noCountryFound: 'No country found',
    },
    loadMoreState: {
      stateText: '{{current}} of {{total}} products',
      loadMoreLabel: 'Load More'
    },
    kurzUnit: {
      m2: '{{unitFactor}}m²',
      rol: '{{unitFactor}} Roll',
      rol_plural: '{{unitFactor}} Rolls',
      pfi: '{{unitFactor}}PFI',
      msi: '{{unitFactor}}MSI',
      mmm: '61m*100mm',
      fti: '{{unitFactor}}FTI',
      pieces: 'Piece',
      pieces_plural: '{{unitFactor}} Pieces',
      notfound: '{{unitFactor}} [unit of measurement not found]'
    },
    priceDisplay: {
      label: 'Price'
    },
    configDisplay: {
      core: 'Core',
      length: 'Length',
      width: 'Width',
      specialWidth: '(special width)',
      finishingType: 'Finishing Type',
      area: 'Area',
      price: 'Price',
      priceBase: 'Price Base',
      cutting: 'Cutting:',
      cuttingRemainder: 'Rest:',
      quantity: 'Quantity',
      totalArea: 'Total Area',
      totalPrice: 'Total Price',
      productOrigin: 'Product Origin',
      stockPosition: 'Stock Position',
      shipmentInfo:'Shipment via Ground in 1 or 2 Business Days'
    },
    getOrderFavouriteNameModal: {
      title: 'Save As Order Favourite',
      context: {
        line1: 'A order favourite will be saved for your order.',
        line2: 'Please choose a name for it.',
      },
      inputLabel: 'Name of the Order Favourite'
    },
    kurzModalWrapper: {
      information: 'Information'
    },
    legalPages: {
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use',
      termsAndConditions: 'Terms of Delivery',
      status: ', Status:'
    },
    manualAddressModal: {
      title: 'Add new Address',
      subTitle: 'Please enter a new address',
      fields: {
        companyName: 'Company Name',
        companyNamePlaceholder: 'Company Name',
        country: 'Country',
        countryPlaceholder: 'Country',
        street: 'Street name',
        streetPlaceholder: 'Street name',
        addressAddition: 'Address supplement',
        addressAdditionPlaceholder: 'Address supplement',
        contactPerson: 'Person of contact',
        contactPersonPlaceholder: 'Person of contact',
        houseNumber: 'House Number',
        houseNumberPlaceholder: 'House Number',
        postalCode: 'Postal Code',
        postalCodePlaceholder: 'Postal Code',
        town: 'City',
        townPlaceholder: 'City'
      }
    },
    helpMenu: {
      helpLabel: 'Help & Contact',
      helpText: 'Do you need assistance or do you have any questions regarding the KURZ customer portal? I will be glad to help you!',
      noAccountManagerHint: 'It seems that you have not been assigned a manager yet. You can still send us an e-mail at sales@kurz.com or call us at +49 911 71 41-0.',
      footerHelpTitle: 'Help & Contact'
    },
    stockFilter: {
      filterHeader: 'Filter',
      toggleTooltip: 'Toggle filter',
      resetTooltip: 'Reset filter',
      coreLabel: 'Core',
      cutLabel: 'Cut Roll oneself',
      fixedToCoreLabel: 'Fixed To Core',
      lengthLabel: 'Length',
      widthLabel: 'Width',
      warehouseLabel: 'Warehouse'
    },
    stockLevel: {
      header: {
        core: 'Core',
        fixedToCore: '',
        length: 'Length',
        width: 'Width',
        area: 'Area',
        price: 'Price',
        discount: 'Discount',
        available: 'Available',
        addToCart: 'Add To Cart'
      },
      body: {
        noStocklLevelFound: 'There exists no stock matching the selected filters.',
        iconTooltip: {
          cuttable: 'Cuttable',
          notCuttable: 'Not Cuttable',
          fixedToCore: 'Fixed to core',
          notFixedToCore: 'Not fixed to core'
        }
      },
      footer: {
        loadMoreState: '{{current}} of {{total}} shown',
        loadMoreButton: 'Show more',
        oneRollSelected: '1 roll selected',
        xRollsSelected: '{{x}} rolls selected',
        addToCartButton: 'Transfer selection to cart'
      }
    },
    uploadPicturesDropZone: {
      info: 'Image upload ({{pictureMax}} images max, {{maxFileSize}})',
      dragMessage: 'Drag pictures or click here',
      fileTooBig: '{{name}} too big'
    }
  },
  // -------------------------- DE
  de: {
    foilconfig: {
      selectBoxes: {
        label: {
          core: 'Kern',
          length_M: 'Länge (m)',
          length_I: 'Länge (ft)',
          width_M: 'Breite (mm)',
          width_I: 'Breite (in)',
          length_finishingType_core_M: 'Länge (m) / Konfektionsart / Kern',
          length_finishingType_core_I: 'Länge (ft) / Konfektionsart / Kern',
          finishingType: 'Konfektionsart',
        },
        specialWidth: '(Sonderbreite)',
        noItemsNoCoreFound: 'Kein passender Kern verfügbar',
        noItemsFreeInput: 'Wunschmaß eingeben',
        noItemsStrictInput: 'Keine Konfiguration gefunden'
      },
      configMissing: 'Passende Größe nicht dabei?',
      findConfigButton: 'Andere Abmessungen prüfen',
      cancelConfiguration: '',
      saveConfiguration: '',
      modal: {
        info: 'Ihre Anfrage wird automatisiert bearbeitet; Sie erhalten die besten Ergebnisse, die zu Ihren Angaben passen.',
        title: 'Andere Abmessungen prüfen',
        requestButton: 'Prüfen',
        header: 'Direkt verfügbare Konfigurationen:',
        accept: 'Ergebnis übernehmen',
        contact: 'Kundenberater kontaktieren',
        error: 'Diese Kombinantion ist leider nicht möglich. Tipp: Bitte passen Sie die Breite um volle 10mm-Schritte an, um die Konfiguration technisch durchführen zu können.'
      }
    },
    acceptLegalDocument: {
      title: {
        termsOfUse: 'Nutzungsbedingungen',
      },
      text: {
        termsOfUse: 'Bitte lesen und akzeptieren Sie vor Verwendung des KURZ Kundenportals unsere nachfolgenden Nutzungsbedingungen.',
      },
      acceptButtonLabel: {
        termsOfUse: 'Nutzungsbedingungen akzeptieren',
      },
    },
    contactModal: {
      defaultHeader: 'Kontaktanfrage',
      dateLabel: 'Datum',
      subjectLabel: 'Betreff',
      orderNumberLabel: 'KURZ Auftragsnummer',
      referenceNumberLabel: 'Bestellreferenz',
      textLabel: 'Ihr Anliegen',
      headerSuccessful: 'Anfrage versendet',
      headerFailed: 'Anfrage fehlgeschlagen',
      sentSuccessfully_1: 'Ihre Anfrage wurde an unseren Kundenberater weitergeleitet.',
      sentSuccessfully_2: 'Sie erhalten baldmöglichst Antwort.',
      sentFailed_1: 'Ihre Anfrage konnte nicht versendet werden.',
      sentFailed_2: 'Möchten Sie es noch einmal versuchen, dann klicken Sie bitte auf "Zurück"',
      nameLabel: 'Name',
      emailLabel: 'E-Mail',
      companyNameLabel:'Firma',
      countryDropdownLabel: 'Land',
      noCountryFound: 'Kein Land gefunden',
    },
    loadMoreState: {
      stateText: '{{current}} von {{total}} Produkte',
      loadMoreLabel: 'Mehr Ergebnisse laden'
    },
    kurzUnit: {
      m2: '{{unitFactor}}m²',
      rol: '{{unitFactor}} Rolle',
      rol_plural: '{{unitFactor}} Rollen',
      pfi: '{{unitFactor}}PFI',
      msi: '{{unitFactor}}MSI',
      mmm: '61m*100mm',
      fti: '{{unitFactor}}FTI',
      pieces: 'Stück',
      pieces_plural: '{{unitFactor}} Stücke',
      notfound: '{{unitFactor}} [Einheit-nicht-gefunden]'
    },
    priceDisplay: {
      label: 'Preis'
    },
    configDisplay: {
      core: 'Kern',
      length: 'Länge',
      width: 'Breite',
      specialWidth: '(Sonderbreite)',
      finishingType: 'Konfektionsart',
      area: 'Fläche',
      price: 'Preis',
      priceBase: 'Preisgrundlage',
      cutting: 'Schnitte:',
      cuttingRemainder: 'Rest:',
      quantity: 'Menge',
      totalArea: 'Gesamtfläche',
      totalPrice: 'Gesamtpreis',
      productOrigin: 'Produktherkunft',
      stockPosition: 'Lagerware'
    },
    getOrderFavouriteNameModal: {
      title: 'Als Bestellfavorit speichern',
      context: {
        line1: 'Für Ihre Bestellung wird ein Bestellfavorit angelegt.',
        line2: 'Bitte vergeben Sie hierfür einen Namen.',
      },
      inputLabel: 'Name des Bestellfavoriten'
    },
    kurzModalWrapper: {
      information: 'Information'
    },
    legalPages: {
      privacyPolicy: 'Datenschutzerklärung',
      termsOfUse: 'Nutzungsbedingungen',
      termsAndConditions: 'Lieferbedingungen',
      status: ', Stand:'
    },
    manualAddressModal: {
      title: 'Neue Adresse hinzufügen',
      subTitle: 'Geben Sie eine neue Adresse ein',
      fields: {
        companyName: 'Firmenname',
        companyNamePlaceholder: 'Firmenname',
        country: 'Land',
        countryPlaceholder: 'Land',
        street: 'Straße',
        streetPlaceholder: 'Straße',
        addressAddition: 'Adresszusatz',
        addressAdditionPlaceholder: 'Adresszusatz',
        contactPerson: 'Ansprechpartner',
        contactPersonPlaceholder: 'Ansprechpartner',
        houseNumber: 'Hausnummer',
        houseNumberPlaceholder: 'Hausnummer',
        postalCode: 'Postleitzahl',
        postalCodePlaceholder: 'Postleitzahl',
        town: 'Stadt',
        townPlaceholder: 'Stadt'
      }
    },
    helpMenu: {
      helpLabel: 'Hilfe',
      helpText: 'Sie benötigen Unterstützung oder haben Fragen zur Bestellung im KURZ Kundenportal? Ich helfe Ihnen gerne!',
      noAccountManagerHint: 'Es scheint, dass Sie noch keinen Manager zugewiesen bekommen haben. Sie können uns trotzdem eine E-Mail an sales@kurz.com schicken oder uns unter +49 911 71 41-0 anrufen.',
      footerHelpTitle: 'Hilfe & Kontakt'
    },
    stockFilter: {
      filterHeader: 'Filtern',
      toggleTooltip: 'Filter ein-/ausblenden',
      resetTooltip: 'Filter zurücksetzen',
      coreLabel: 'Kern',
      cutLabel: 'Rolle selbst schneiden',
      fixedToCoreLabel: 'Am Kern festgeklebt?',
      lengthLabel: 'Länge',
      widthLabel: 'Breite',
      warehouseLabel: 'Lagerhaus'
    },
    stockLevel: {
      header: {
        core: 'Kern',
        fixedToCore: '',
        length: 'Länge',
        width: 'Breite',
        area: 'Fläche',
        price: 'Preis',
        discount: 'Rabatt',
        available: 'Verfügbar',
        addToCart: 'In den Warenkorb'
      },
      body: {
        noStocklLevelFound: 'Es existiert kein Lagerbestand, der zu den gewählten Filtern passt',
        iconTooltip: {
          cuttable: 'Schneidbar',
          notCuttable: 'Nicht schneidbar',
          fixedToCore: 'Am Kern festgeklebt',
          notFixedToCore: 'Nicht am Kern festgeklebt'
        }
      },
      footer: {
        loadMoreState: '{{current}} von {{total}} geladen',
        loadMoreButton: 'Mehr laden',
        oneRollSelected: '1 Rolle ausgewählt',
        xRollsSelected: '{{x}} Rollen ausgewählt',
        addToCartButton: 'Auswahl in den Warenkorb'
      }
    },
    uploadPicturesDropZone: {
      info: 'Bildupload (max {{pictureMax}} Bilder, {{maxFileSize}})',
      dragMessage: 'Bilder hinein ziehen oder klicken',
      fileTooBig: '{{name}} zu groß'
    }
  },
  // --------------------------- Fallback fr
  fr: {
    foilconfig: {
      selectBoxes: {
        label: {
          core: 'Mandrin',
          length_M: 'Longueur (m)',
          length_I: 'Longueur (ft)',
          width_M: 'Laize (mm)',
          width_I: 'Laize (in)',
          length_finishingType_core_M: 'Longueur (m) / FinishingType / Mandrin',
          length_finishingType_core_I: 'Longueur (in) / FinishingType / Mandrin',
          finishingType: 'FinishingType',
        },
        specialWidth: '(Laize spécifique)',
        noItemsNoCoreFound: 'Pas de noyau correspondant disponible',
        noItemsFreeInput: 'Saisir les dimensions souhaitées',
        noItemsStrictInput: 'Aucune configuration trouvée'
      },
      configMissing: 'Pas de dimension existante ?',
      findConfigButton: 'Vérifier d\'autres dimensions',
      cancelConfiguration: '',
      saveConfiguration: '',
      modal: {
        info: 'Votre demande sera traitée automatiquement, vous recevrez les meilleures solutions respectives à vos données',
        title: 'Vérifier d\'autres dimensions',
        requestButton: 'Vérifier',
        header: 'Confections directement disponibles:',
        accept: 'Reprendre le résultat',
        contact: 'Contacter un conseiller clientèle',
        error: 'Cette combinaison n\'est pas possible. Aide: merci d\'ajuster la laize par pas de 10mm pour pouvoir effectuer la confection techniquement'
      }
    },
    acceptLegalDocument: {
      title: {
        termsOfUse: 'Conditions d\'utilisation',
      },
      text: {
        termsOfUse: 'Veuillez lire et accepter avant utilisation du portail client KURZ nos conditions suivantes d\'utilisation.',
      },
      acceptButtonLabel: {
        termsOfUse: 'Accepter les conditions d\'utilisation',
      },
    },
    contactModal: {
      defaultHeader: 'Demande de contact',
      dateLabel: 'Date',
      subjectLabel: 'Objet',
      orderNumberLabel: 'N° de commande KURZ',
      referenceNumberLabel: 'Référence de commande',
      textLabel: 'Votre demande',
      headerSuccessful: 'Demande envoyée',
      headerFailed: 'Demande non réussie',
      sentSuccessfully_1: 'Votre demande a été transférée au responsable du Service commercial. ',
      sentSuccessfully_2: 'Une réponse vous sera adressée dans les plus brefs délais. ',
      sentFailed_1: 'Votre demande ne pouvait pas être envoyée. ',
      sentFailed_2: 'Si vous souhaitez réessayer, veuillez cliquer sur "retour".',
      nameLabel: 'Nom',
      emailLabel: 'E-Mail',
      companyNameLabel:'Nom de l\'entreprise',
      countryDropdownLabel: 'Pays',
      noCountryFound: 'Aucun pays trouvé',
    },
    loadMoreState: {
      stateText: 'Produits {{current}} de {{total}}',
      loadMoreLabel: 'Plus de résultats'
    },
    kurzUnit: {
      m2: '{{unitFactor}}m²',
      rol: '{{unitFactor}} Rouleau',
      rol_plural: '{{unitFactor}} Rouleaux ',
      pfi: '{{unitFactor}}PFI',
      msi: '{{unitFactor}}MSI',
      mmm: '61m*100mm',
      fti: '{{unitFactor}}FTI',
      pieces: 'Pièce',
      pieces_plural: '{{unitFactor}} Pièces',
      notfound: '{{unitFactor}} [Unité non trouvée]'
    },
    priceDisplay: {
      label: 'Prix'
    },
    configDisplay: {
      core: 'Mandrin',
      length: 'Longueur',
      width: 'Laize',
      specialWidth: '(Laize spécifique)',
      finishingType: 'FinishingType',
      area: 'Surface',
      price: 'Prix',
      priceBase: 'Base de prix',
      cutting: 'Cutting:',
      cuttingRemainder: 'Solde:',
      quantity: 'Quantité',
      totalArea: 'Surface',
      totalPrice: 'Total (HT)',
      productOrigin: 'Product Origin',
      stockPosition: 'Stock Position'
    },
    getOrderFavouriteNameModal: {
      title: 'Enregistrer comme projet de commande',
      context: {
        line1: 'Pour votre commande un projet de commande sera enregistré.',
        line2: 'Veuillez lui attribuer un nom.',
      },
      inputLabel: 'Nom du projet de commande'
    },
    kurzModalWrapper: {
      information: 'Information'
    },
    legalPages: {
      privacyPolicy: 'Déclaration de confidentialité',
      termsOfUse: 'Conditions d\'utilisation',
      termsAndConditions: 'Conditions Générales de Vente',
      status: ',Édition :'
    },
    manualAddressModal: {
      title: 'Rajouter nouvelle adresse',
      subTitle: 'Veuilez saisir une nouvelle adresse',
      fields: {
        companyName: 'Nom de l\'entreprise',
        companyNamePlaceholder: 'Nom de l\'entreprise',
        country: 'Pays',
        countryPlaceholder: 'Pays',
        street: 'Rue',
        streetPlaceholder: 'Rue',
        addressAddition: 'Supplément d\'adresse',
        addressAdditionPlaceholder: 'Supplément d\'adresse',
        contactPerson: 'Personne à contacter',
        contactPersonPlaceholder: 'Personne à contacter',
        houseNumber: 'Numéro',
        houseNumberPlaceholder: 'Numéro',
        postalCode: 'Code postal',
        postalCodePlaceholder: 'Code postal',
        town: 'Ville',
        townPlaceholder: 'Ville'
      }
    },
    helpMenu: {
      helpLabel: 'Besoin d\'aide & contact',
      helpText: 'Besoin d\'aide ou des questions pour passer votre commande ? Nous serons ravis de vous aider !',
      noAccountManagerHint: 'Il semble que vous n\'ayez pas encore été affecté à un gestionnaire. Vous pouvez néanmoins nous envoyer un e-mail à contact@kurz.fr ou nous appeler au +33 1 43 57 16 70.',
      footerHelpTitle: 'Besoin d\'aide & contact'
    },
    stockFilter: {
      filterHeader: 'Trier',
      toggleTooltip: 'Afficher/masquer les filtres',
      resetTooltip: 'Réinitialiser le filtre',
      coreLabel: 'Mandrin',
      cutLabel: 'Coupez-vous les rlx vous-même ?',
      fixedToCoreLabel: 'Faut-il fixer le produit au mandrin ?',
      lengthLabel: 'Longueur',
      widthLabel: 'Laize',
      warehouseLabel: 'Site/Entrepôt'
    },
    stockLevel: {
      header: {
        core: 'Mandrin',
        fixedToCore: '',
        length: 'Longueur',
        width: 'Laize',
        area: 'Surface',
        price: 'Montant HT',
        discount: 'Remise',
        available: 'Quantité disponible',
        addToCart: 'Ajouter à mon panier'
      },
      body: {
        noStocklLevelFound: 'Il n\'existe pas du stock correspondant à vos filtres choisis.',
        iconTooltip: {
          cuttable: 'Découpable',
          notCuttable: 'Non découpable',
          fixedToCore: 'Produit attaché au mandrin',
          notFixedToCore: 'Produit non attaché au mandrin'
        }
      },
      footer: {
        loadMoreState: '{{current}} de {{total}} téléchargés',
        loadMoreButton: 'Télécharger plus',
        oneRollSelected: '1 rouleau choisi',
        xRollsSelected: '{{x}} rouleaux choisis',
        addToCartButton: 'Ajouter au panier '
      }
    },
    uploadPicturesDropZone: {
      info: 'FR:Image upload ({{pictureMax}} images max, {{maxFileSize}})',
      dragMessage: 'Faites glisser des images ou cliquez ici',
      fileTooBig: '{{name}} trop gros'
    }
  },
  // ----------------------------- fr_FR
  fr_FR: {
    legalPages: {
      privacyPolicy: 'Déclaration de confidentialité',
      termsOfUse: 'Conditions d\'utilisation',
      termsAndConditions: 'Conditions Générales de Vente ',
      status: ',Edition'
    }
  },
  // ----------------------------- fr_CH
  fr_CH: {},
  // ----------------------------- fr_LU
  fr_LU: {},
  // ----------------------------- nl
  nl: {
    foilconfig: {
      selectBoxes: {
        label: {
          core: 'Kern',
          length_M: 'Lengte (m)',
          length_I: 'Lengte (ft)',
          width_M: 'Breedte (mm)',
          width_I: 'Breedte (in)',
          length_finishingType_core_M: 'Lengte (m) / Confectietype / Kern',
          length_finishingType_core_I: 'Lengte (ft) / Confectietype / Kern',
          finishingType: 'Confectietype',
        },
        specialWidth: '(aangepaste breedte)',
        noItemsNoCoreFound: 'Geen geschikte kern beschikbaar',
        noItemsFreeInput: 'Voer de gewenste maat in',
        noItemsStrictInput: 'Geen configuratie gevonden'
      },
      configMissing: 'Niet de juiste maat?',
      findConfigButton: 'Andere rolafmetingen zoeken',
      cancelConfiguration: '',
      saveConfiguration: '',
      modal: {
        info: 'Uw aanvraag wordt automatisch verwerkt; U krijgt de beste resultaten die overeenkomen met uw specificaties.',
        title: 'Andere rolafmetingen zoeken',
        requestButton: 'Zoeken',
        header: 'Direct beschikbare configuraties:',
        accept: 'Resultaat accepteren',
        contact: 'Neem contact op met customer service',
        error: 'Helaas is deze combinatie niet mogelijk. Tip: pas de breedte aan in stappen van 10 mm om configuratie aan te passen.'
      }
    },
    acceptLegalDocument: {
      title: {
        termsOfUse: 'Gebruiksvoorwaarden',
      },
      text: {
        termsOfUse: 'Lees en accepteer onze volgende gebruiksvoorwaarden voordat u het KURZ klantenportaal gebruikt.',
      },
      acceptButtonLabel: {
        termsOfUse: 'Gebruiksvoorwaarden accepteren',
      },
    },
    contactModal: {
      defaultHeader: 'Contactaanvraag',
      dateLabel: 'Datum',
      subjectLabel: 'Onderwerp',
      orderNumberLabel: 'KURZ bestelnr.',
      referenceNumberLabel: 'Bestelreferentie',
      textLabel: 'Uw verzoek:',
      headerSuccessful: 'Verzoek verzonden',
      headerFailed: 'verzoek mislukt',
      sentSuccessfully_1: 'Uw verzoek is doorgestuurd naar onze klantenservice',
      sentSuccessfully_2: 'U ontvangt zo snel mogelijk een antwoord.',
      sentFailed_1: 'Uw verzoek kon niet worden verzonden.',
      sentFailed_2: 'Als u het opnieuw wilt proberen, klikt u op "Terug"',
      nameLabel: 'Naam',
      emailLabel: 'E-Mail',
      companyNameLabel:'Bedrijfsnaam',
      countryDropdownLabel: 'Land',
      noCountryFound: 'Geen land gevonden',
    },
    loadMoreState: {
      stateText: '{{current}} van {{total}} producten',
      loadMoreLabel: 'Laad meer resultaten'
    },
    kurzUnit: {
      m2: '{{unitFactor}}m²',
      rol: '{{unitFactor}} rol',
      rol_plural: '{{unitFactor}} rollen',
      pfi: '{{unitFactor}}PFI',
      msi: '{{unitFactor}}MSI',
      mmm: '61m*100mm',
      fti: '{{unitFactor}}FTI',
      pieces: 'Stuk',
      pieces_plural: '{{unitFactor}} Stukken',
      notfound: '{{unitFactor}} [eenheid-niet-gevonden]'
    },
    priceDisplay: {
      label: 'Prijs'
    },
    configDisplay: {
      core: 'Kern',
      length: 'Lengte',
      width: 'Breedte',
      specialWidth: '(aangepaste breedte)',
      finishingType: 'Confectietype',
      area: 'Oppervlakte',
      price: 'Prijs',
      priceBase: 'Prijs basis',
      cutting: 'snijden:',
      cuttingRemainder: 'Rest:',
      quantity: 'Hoeveelheid',
      totalArea: 'Oppervlakte',
      totalPrice: 'Totaal prijs',
      productOrigin: 'Product Origin',
      stockPosition: 'Stock Position'
    },
    getOrderFavouriteNameModal: {
      title: 'Sla op als favoriet',
      context: {
        line1: 'Voor uw bestelling wordt een favoriet aangelegt.',
        line2: 'Geef een naam op.',
      },
      inputLabel: 'Naam favoriete bestelling'
    },
    kurzModalWrapper: {
      information: 'Informatie'
    },
    legalPages: {
      privacyPolicy: 'Verklaring inzake gegevensbescherming',
      termsOfUse: 'Gebruiksvoorwaarden',
      termsAndConditions: 'Leveringsvoorwaarden',
      status: ',Editie:'
    },
    manualAddressModal: {
      title: 'Nieuw adres toevoegen',
      subTitle: 'Voer een nieuw adres in',
      fields: {
        companyName: 'Bedrijfsnaam',
        companyNamePlaceholder: 'Bedrijfsnaam',
        country: 'Land',
        countryPlaceholder: 'Land',
        street: 'Straat',
        streetPlaceholder: 'Straat',
        addressAddition: 'Adres aanvulling',
        addressAdditionPlaceholder: 'Adres aanvulling',
        contactPerson: 'Contactpersoon',
        contactPersonPlaceholder: 'Contactpersoon',
        houseNumber: 'Huisnummer',
        houseNumberPlaceholder: 'Huisnummer',
        postalCode: 'Postcode',
        postalCodePlaceholder: 'Postcode',
        town: 'Stad',
        townPlaceholder: 'Stad'
      }
    },
    helpMenu: {
      helpLabel: 'Help',
      helpText: 'Heft u ondersteuning nodig of heeft u vragen over de bestelling in de KURZ klantenportaal? Ik help u graag!',
      noAccountManagerHint: 'Het lijkt erop dat je nog geen manager toegewezen hebt gekregen. Je kunt ons nog steeds een e-mail sturen naar info@kurz.nl of ons bellen op +31 24 37 81 84 0.',
      footerHelpTitle: 'Help'
    },
    stockFilter: {
      filterHeader: 'Filteren',
      toggleTooltip: 'Filter tonen/verbergen',
      resetTooltip: 'Filter opnieuw instellen',
      coreLabel: 'Kern',
      cutLabel: 'Rol zelf snijden',
      fixedToCoreLabel: 'Aan kern bevestigd?',
      lengthLabel: 'Lengte',
      widthLabel: 'Breedte',
      warehouseLabel: 'Locatie / Magazijn'
    },
    stockLevel: {
      header: {
        core: 'Kern',
        fixedToCore: '',
        length: 'Lengte',
        width: 'Breedte',
        area: 'Oppervlakte',
        price: 'Prijs',
        discount: 'Korting',
        available: 'Beschikbare hoeveelheid',
        addToCart: 'In het winkelwagentje'
      },
      body: {
        noStocklLevelFound: 'Er is geen voorraad die overeenkomt met de geselecteerde filters',
        iconTooltip: {
          cuttable: 'Snijdbaar',
          notCuttable: 'Niet snijdbaar',
          fixedToCore: 'Houdt vast aan de kern',
          notFixedToCore: 'Blijft niet bij de kern'
        }

      },
      footer: {
        loadMoreState: '{{current}} van {{total}} geladen',
        loadMoreButton: 'Meer laden',
        oneRollSelected: '1 rol geselecteerd',
        xRollsSelected: '{{x}} rollen geselecteerd',
        addToCartButton: 'Selectie in de winkelwagen'
      }
    },
    uploadPicturesDropZone: {
      info: 'NL:Image upload ({{pictureMax}} images max, {{maxFileSize}})',
      dragMessage: 'Sleep afbeeldingen of klik hier',
      fileTooBig: '{{name}} te groot'
    }
  },
  chunks: [
    'foilconfig',
    'acceptLegalDocument',
    'contactModal',
    'loadMoreState',
    'kurzUnit',
    'priceDisplay',
    'configDisplay',
    'getOrderFavouriteNameModal',
    'kurzModalWrapper',
    'legalPages',
    'manualAddressModal',
    'helpMenu',
    'stockLevel',
    'stockFilter',
    'uploadPicturesDropZone'
  ]
};
