export const recosysTranslations = {
  en: {
    kurzRecosys: {
      heading: 'KURZ RECOSYS 2.0',
      pickups: 'Pickup Orders',
      certificatesLabel: 'Certificates',
      userManagement: 'User management',
      returnedWeight: 'Returned weight',
      download: 'Download',
      done: 'Done',
      pickupOrders: {
        noPickupAddressesHTML: `
          <p>Unfortunately, your details are not yet complete.</p>
          <p>Please get in touch with your contact person.</p>
        `,
        pickupAddressDropdownLabel: 'Pickup Address',
        current: 'Current pickup order',
        progressBar: {
          leftText: '{{ currentWeightStr }}kg / min {{ minWeightStr }}kg',
          rightText: '~ {{ leftOverWeightStr }}kg left',
          rightTextEnough: 'Limit reached'
        },
        displayObject: 'Display Objects / Containers',
        newObject: 'Register object',
        commissionPickup: 'Commission pickup',
        commissionedOn: 'Commissioned on',
        receivedOn: 'Received on',
        deliveryBill: 'Delivery bill',
        positions: 'Items',
      },
      pickupPositions: {
        position: 'Position',
        container: 'Container',
        label: 'Label',
        newPositionDialog: {
          title: 'Record object',
          enterWeight: 'Enter weight',
          selectContainerType: 'Select casing type',
          stackable: 'Stackable',
          continue: 'Continue',
          customDimensions: 'Custom Dimensions',
          length: 'L',
          width: 'W',
          height: 'H',
          printLabel: 'Print Label',
          labelHint: 'Please note that each container must be labeled!',
          customer: 'Customer',
        },
        positionAddedSuccess: 'Position added successfully',
        positionAddedError: 'Error adding Position',
        positionEditedSuccess: 'Position edited successfully',
        positionEditedError: 'Error editing Position',
        positionDeletedSuccess: 'Position deleted successfully',
        positionDeletedError: 'Error deleting Position',
      },
      certificates: {
        rootLabel: 'All Years',
        year: 'Year',
        month: 'Month',
        customer: 'Customer',
        co2SavingsInKg: 'CO₂ saved',
        returnedWeightInKg: 'Returned Weight',
      },
      clientsManagement: {
        noClients: 'There are no clients yet',
        addClient: 'Add Client',
        grid: {
          name: 'Client\'s Name'
        },
        modal: {
          edit: {
            title: 'Edit Client'
          },
          add: {
            title: 'Add New Client'
          },
          name: 'Name',
          language: 'Language',
          branch: 'Branch',
          salesChannel: 'Sales Channel',
          salesOrganization: 'Sales Organization',
          consignees: 'Consignees',
          addConsignee: 'Add Consignee',
          removeConsignee: 'Remove Consignee',
          consigneeSection: {
            name: 'Consignee Name',
            contact: 'Contact',
            contactSection: {
              firstName: 'First Name',
              lastName: 'Last Name',
              email: 'Email',
              phone: 'Phone'
            },
            distanceToCustomer: 'Distance To Customer',
            distanceToSalesOrganization: 'Distance To Sales Organization',
            climateNeutral: 'Climate Neutral',
            minimumWeight: 'Minimum Weight'
          },
          contactMails: 'Contact Mails',
          addContactMail: 'Add Contact Mail',
          removeContactMail: 'Remove Contact Mail',
          contactMailSection: {
            mailAddress: 'Mail Address',
            certificate: 'For Certificates',
            positivList: 'For Positiv Lists',
          },
          address: 'Address',
          addressSection: {
            street: 'Street',
            houseNumber: 'House Number',
            city: 'City',
            country: 'Country',
            postalCode: 'Postal Code'
          }
        }
      },
    }
  },
  de: {
    kurzRecosys: {
      heading: 'KURZ RECOSYS 2.0',
      pickups: 'Abholaufträge',
      certificatesLabel: 'Zertifikate',
      userManagement: 'Nutzerverwaltung',
      returnedWeight: 'Retourniertes Gewicht',
      download: 'Download',
      done: 'Fertig',
      pickupOrders: {
        noPickupAddressesHTML: `
          <p>Leider sind Ihre Daten noch nicht vollständing hinterlegt.</p>
          <p>Wenden Sie sich bitte an Ihren Ansprechpartner.</p>
        `,
        pickupAddressDropdownLabel: 'Abholadresse',
        current: 'Aktueller Abholauftrag',
        displayObject: 'Objekte / Gebinde anzeigen',
        progressBar: {
          leftText: '{{ currentWeightStr }}kg / min {{ minWeightStr }}kg',
          rightText: 'Noch ~ {{ leftOverWeightStr }}kg',
          rightTextEnough: 'Grenze erreicht'
        },
        newObject: 'Objekt erfassen',
        commissionPickup: 'Abholung beautragen',
        commissionedOn: 'Beauftrag am',
        receivedOn: 'Eingegangen am',
        deliveryBill: 'Lieferschein',
        positions: 'Positionen',
      },
      pickupPositions: {
        position: 'Position',
        container: 'Objekt',
        label: 'Etikett',
        newPositionDialog: {
          title: 'Objekt erfassen',
          enterWeight: 'Gewicht angeben',
          selectContainerType: 'Gebindeart auswählen',
          stackable: 'Stapelbar',
          continue: 'Weiter',
          customDimensions: 'Individuelle Abmessungen',
          length: 'L',
          width: 'W',
          height: 'H',
          printLabel: 'Etikett drucken',
          labelHint: 'Bitte beachten Sie, dass jedes Gebinde etikettiert werden muss!',
          customer: 'Kunde',
        },
        positionAddedSuccess: 'Position erfolgreich hinzugefügt',
        positionAddedError: 'Fehler beim Hinzufügen der Position',
        positionEditedSuccess: 'Position erfolgreich bearbeitet',
        positionEditedError: 'Fehler beim Bearbeiten der Position',
        positionDeletedSuccess: 'Position erfolgreich gelöscht',
        positionDeletedError: 'Fehler beim Löschen der Position',
      },
      certificates: {
        rootLabel: 'Alle Jahre',
        year: 'Jahr',
        month: 'Monat',
        customer: 'Kunde',
        co2SavingsInKg: 'Eingespartes CO₂',
        returnedWeightInKg: 'Retourniertes Gewicht'
      },
      clientsManagement: {
        noClients: 'Es wurden noch keine Clients angelegt',
        addClient: 'Client hinzufügen',
        grid: {
          name: 'Client-Name'
        },
        modal: {
          edit: {
            title: 'Client bearbeiten'
          },
          add: {
            title: 'Neuer Client hinzufügen'
          },
          name: 'Name',
          language: 'Sprache',
          branch: 'Branch',
          salesChannel: 'Sales Channel',
          salesOrganization: 'Sales Organization',
          consignees: 'Consignees',
          addConsignee: 'Consignee hinzufügen',
          removeConsignee: 'Consignee entfernen',
          consigneeSection: {
            name: 'Consignee-Name',
            contact: 'Kontakt',
            contactSection: {
              firstName: 'Vorname',
              lastName: 'Nachname',
              email: 'eMail',
              phone: 'Telefon'
            },
            distanceToCustomer: 'Distanz zum Kunden',
            distanceToSalesOrganization: 'Distanz zur Sales Organization',
            climateNeutral: 'Klimaneutral',
            minimumWeight: 'Mindestgewicht'
          },
          contactMails: 'Kontakt-Email',
          addContactMail: 'Kontakt-Email hinzufügen',
          removeContactMail: 'Kontakt-Email entfernen',
          contactMailSection: {
            mailAddress: 'eMail-Adresse',
            certificate: 'Für Zertifikaten',
            positivList: 'Für Positiv Lists',
          },
          address: 'Adresse',
          addressSection: {
            street: 'Straße',
            houseNumber: 'Hausnummer',
            city: 'Stadt',
            country: 'Land',
            postalCode: 'Postleitzahl'
          }
        }
      },
    }
  },
  fr: {
    kurzRecosys: {
      heading: 'KURZ RECOSYS 2.0',
      pickups: 'Ordres de collecte',
      certificatesLabel: 'Certificats',
      userManagement: 'Gestion des utilisateurs',
      returnedWeight: 'Poids retourné',
      download: 'Download',
      done: 'Prêt à l\'emploi',
      pickupOrders: {
        noPickupAddressesHTML: `
          <p>Malheureusement, vos données ne sont pas encore complètes.</p>
          <p>Veuillez vous adresser à votre interlocuteur.</p>
        `,
        pickupAddressDropdownLabel: 'Adresse de collecte',
        current: 'Ordre de collecte actuel',
        displayObject: 'Afficher les objets / emballages',
        progressBar: {
          leftText: 'FR:{{ currentWeightStr }}kg / min {{ minWeightStr }}kg',
          rightText: 'FR:Noch ~ {{ leftOverWeightStr }}kg',
          rightTextEnough: 'Limite atteinte'
        },
        newObject: 'Saisir l\'objet',
        commissionPickup: 'Demander l\'enlèvement',
        commissionedOn: 'Commandé le',
        receivedOn: 'Reçu le ',
        deliveryBill: 'Bon de livraison',
        positions: 'Positions',
      },
      pickupPositions: {
        position: 'Position',
        container: 'Objet',
        label: 'Étiquette',
        newPositionDialog: {
          title: 'Saisir un objet',
          enterWeight: 'Entrer le poids',
          selectContainerType: 'Sélectionner le type de boîtier',
          stackable: 'Empilable',
          continue: 'Continuer',
          customDimensions: 'Dimensions personnalisées',
          length: 'L',
          width: 'l',
          height: 'H',
          printLabel: 'Imprimer l\'étiquette',
          labelHint: 'Veuillez noter que chaque emballage doit être étiqueté !',
          customer: 'Client',
        },
        positionAddedSuccess: 'Position ajoutée avec succès',
        positionAddedError: 'Erreur lors de l\'ajout de la position',
        positionEditedSuccess: 'Position modifiée avec succès',
        positionEditedError: 'Erreur lors de la modification de la position',
        positionDeletedSuccess: 'Position supprimée avec succès',
        positionDeletedError: 'Erreur lors de la suppression de la position',
      },
      certificates: {
        rootLabel: 'Toutes Années',
        year: 'Année',
        month: 'Mois',
        customer: 'Client',
        co2SavingsInKg: 'CO₂ économisé',
        returnedWeightInKg: 'Poids retourné'
      },
      clientsManagement: {
        noClients: 'FR:There are no clients yet',
        addClient: 'FR:Add Client',
        grid: {
          name: 'FR:Client\'s Name'
        },
        modal: {
          edit: {
            title: 'FR:Edit Client'
          },
          add: {
            title: 'FR:Add New Client'
          },
          name: 'FR:Name',
          language: 'FR:Language',
          branch: 'FR:Branch',
          salesChannel: 'FR:Sales Channel',
          salesOrganization: 'FR:Sales Organization',
          consignees: 'FR:Consignees',
          addConsignee: 'FR:Add Consignee',
          removeConsignee: 'FR:Remove Consignee',
          consigneeSection: {
            name: 'FR:Consignee Name',
            contact: 'FR:Contact',
            contactSection: {
              firstName: 'FR:First Name',
              lastName: 'FR:Last Name',
              email: 'FR:Email',
              phone: 'FR:Phone'
            },
            distanceToCustomer: 'FR:Distance To Customer',
            distanceToSalesOrganization: 'FR:Distance To Sales Organization',
            climateNeutral: 'FR:Climate Neutral',
            minimumWeight: 'FR:Minimum Weight'
          },
          contactMails: 'FR:Contact Mails',
          addContactMail: 'FR:Add Contact Mail',
          removeContactMail: 'FR:Remove Contact Mail',
          contactMailSection: {
            mailAddress: 'FR:Mail Address',
            certificate: 'FR:For Certificates',
            positivList: 'FR:For Positiv Lists',
          },
          address: 'FR:Address',
          addressSection: {
            street: 'FR:Street',
            houseNumber: 'FR:House Number',
            city: 'FR:City',
            country: 'FR:Country',
            postalCode: 'FR:Postal Code'
          }
        }
      },
    }
  },
  nl: {
    kurzRecosys: {
      heading: 'KURZ RECOSYS 2.0',
      pickups: 'Bestellingen afhalen',
      certificatesLabel: 'Certificaten',
      userManagement: 'Gebruikersadministratie',
      returnedWeight: 'Geretourneerd gewicht',
      download: 'Download',
      done: 'Afgewerkt',
      pickupOrders: {
        noPickupAddressesHTML: `
          <p>Helaas zijn uw gegevens nog niet compleet.</p>
          <p>Neem contact op met uw contactpersoon.</p>
        `,
        pickupAddressDropdownLabel: 'Ophaaladres',
        current: 'Huidige ophaalvolgorde',
        displayObject: 'Objecten / containers weergeven',
        progressBar: {
          leftText: 'NL:{{ currentWeightStr }}kg / min {{ minWeightStr }}kg',
          rightText: 'NL:Noch ~ {{ leftOverWeightStr }}kg',
          rightTextEnough: 'Limiet bereikt'
        },
        newObject: 'Object invoeren',
        commissionPickup: 'Orderverzameling',
        commissionedOn: 'In opdracht gegeven op',
        receivedOn: 'Ontvangen op',
        deliveryBill: 'Leveringsbon',
        positions: 'Artikelen',
      },
      pickupPositions: {
        position: 'Positie',
        container: 'Container',
        label: 'Label',
        newPositionDialog: {
          title: 'Object vastleggen',
          enterWeight: 'Gewicht invoeren',
          selectContainerType: 'Boezemtype selecteren',
          stackable: 'Verpilingbaar',
          continue: 'Doorgaan',
          customDimensions: 'Aangepaste afmetingen',
          length: 'L',
          width: 'B',
          height: 'H',
          printLabel: 'Etiket printen',
          labelHint: 'Let op: elke verpakking moet gelabeld worden!',
          customer: 'Klant',
        },
        positionAddedSuccess: 'Positie succesvol toegevoegd',
        positionAddedError: 'Fout bij het toevoegen van de positie',
        positionEditedSuccess: 'Positie succesvol bewerkt',
        positionEditedError: 'Fout bij het bewerken van de positie',
        positionDeletedSuccess: 'Positie succesvol verwijderd',
        positionDeletedError: 'Fout bij het verwijderen van de positie',
      },
      certificates: {
        rootLabel: 'Alle Jaren',
        year: 'Jaar',
        month: 'Maand',
        customer: '',
        co2SavingsInKg: 'CO₂ bespaard',
        returnedWeightInKg: 'Teruggegeven gewicht'
      },
      clientsManagement: {
        noClients: 'NL:There are no clients yet',
        addClient: 'NL:Add Client',
        grid: {
          name: 'NL:Client\'s Name'
        },
        modal: {
          edit: {
            title: 'NL:Edit Client'
          },
          add: {
            title: 'NL:Add New Client'
          },
          name: 'NL:Name',
          language: 'NL:Language',
          branch: 'NL:Branch',
          salesChannel: 'NL:Sales Channel',
          salesOrganization: 'NL:Sales Organization',
          consignees: 'NL:Consignees',
          addConsignee: 'NL:Add Consignee',
          removeConsignee: 'NL:Remove Consignee',
          consigneeSection: {
            name: 'NL:Consignee Name',
            contact: 'NL:Contact',
            contactSection: {
              firstName: 'NL:First Name',
              lastName: 'NL:Last Name',
              email: 'NL:Email',
              phone: 'NL:Phone'
            },
            distanceToCustomer: 'NL:Distance To Customer',
            distanceToSalesOrganization: 'NL:Distance To Sales Organization',
            climateNeutral: 'NL:Climate Neutral',
            minimumWeight: 'NL:Minimum Weight'
          },
          contactMails: 'NL:Contact Mails',
          addContactMail: 'NL:Add Contact Mail',
          removeContactMail: 'NL:Remove Contact Mail',
          contactMailSection: {
            mailAddress: 'NL:Mail Address',
            certificate: 'NL:For Certificates',
            positivList: 'NL:For Positiv Lists',
          },
          address: 'NL:Address',
          addressSection: {
            street: 'NL:Street',
            houseNumber: 'NL:House Number',
            city: 'NL:City',
            country: 'NL:Country',
            postalCode: 'NL:Postal Code'
          }
        }
      },
    }
  },
  chunks: [
    'kurzRecosys',
  ]

};
