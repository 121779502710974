import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseSiteService, I18nConfig, Language, LanguageService, SiteContextConfig, StateWithSiteContext, TranslationResources } from '@spartacus/core';
import { getUrlOfEndpoint } from '@util/functions/strings';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import { translationsMergerFactory } from '../custom-configuration-modules/custom-translations';
import { KurzI18nextTranslationService } from './kurz-i18next-translation.service';
import { environment } from 'src/environments/environment';
import { AllowedBaseSite } from 'src/environments/kurz-env.type';

@Injectable({
  providedIn: 'root'
})
export class KurzLanguageService extends LanguageService {


  constructor(
    store: Store<StateWithSiteContext>,
    config: SiteContextConfig,
    private httpClient: HttpClient,
    private baseSiteService: BaseSiteService,
    private kurzI18nextTranslationService: KurzI18nextTranslationService
  ) {
    super(store, config);

    // listen to a basesite change and changes the translations according to the new base site
    this.baseSiteService.get().pipe(
      map(baseSite => baseSite?.uid),
      distinctUntilChanged(),
      skip(1)
    ).subscribe(baseSite => {
      environment.currentBaseSite = baseSite as AllowedBaseSite;
      const spartacusTranslationConfig = translationsMergerFactory();
      this.addTranslationToConfig(spartacusTranslationConfig);
      this.kurzI18nextTranslationService.refreshUITranslations();

      // forcing a reload of the page (only if nothing else works anymore)
      // setTimeout(() => {
      //   window.location.reload();
      // }, 0);

    });

  }

  setStandardLanguageInCustomer(isoCode: string): Observable<{value: string;}> {
    const url = getUrlOfEndpoint(`/my-details/language?langIso=${isoCode}`);
    return this.httpClient.put<{value: string;}>(url, {});
  }

  private addTranslationToConfig(spartacusTranslationConfig: I18nConfig) {
    const resources: TranslationResources = spartacusTranslationConfig.i18n?.resources ?? {};
    Object.keys(resources).forEach((lang) => {
      Object.keys(resources[lang]).forEach((chunkName) => {
        this.kurzI18nextTranslationService.i18nextRef.addResourceBundle(
          lang,
          chunkName,
          resources[lang][chunkName],
          true,
          true
        );
      });
    });

  }

}


export class KurzLanguageTestingService implements Partial<LanguageService> {

  getAll(): Observable<Language[]> {
    return of([]);
  }
  getActive(): Observable<string> {
    return of('mock');
  }
  setActive(isocode: string): void {}

  isInitialized(): boolean {
    return true;
  }

  setStandardLanguageInCustomer(isoCode: string): Observable<{value: string;}> {
    return of({value: 'success'});
  }

}
