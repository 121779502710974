
export const kurzComplaintTranslations = { //NOSONAR
  en: {
    kurzComplaint: {
      modal: {
        header: 'Register Complaint',
        step1: {
          nextStep: 'Next Step',
          subTitle: 'Products of this order',
          registerComplaintCheckbox: 'Register complaint',
          complaintInfo: 'Please declare the details and the reason for the complaint',
          quantity: 'Quantity',
          openedRoll: 'Opened rolls',
          batch: 'Batch',
          reason: 'Reason',
          sample: 'Sample',
          sampleValues: {
            yes: 'Yes',
            no: 'No'
          },
          area: 'Area of Use/ Processing',
          complaintFollowUpValues: {
            return: 'Return',
            noReturn: 'No Return',
            underReview: 'Under Review'
          },
        },
        step2: {
          subTitle: 'Pickup order',
          pickUpAddressTitle: 'Pickup address',
          noPickUpAddressSelected: 'No pickup address selected',
          editPickUpAddressTitel: 'Edit Pick-up Address',
          useManualPickUpAddressLabel: 'Use manual Address',
          furtherInfoTitle: 'Further info',
          openingHours: 'Opening hours',
          collectDate: 'Pick up at',
          pickupEntriesTitle: 'Pick-up entries',
          quantity: 'Quantity',
          packagingType: 'Packaging Type',
          packagingTypeValues: {
            none: 'none',
            cardboard: 'Cardboard',
            palett: 'Palett'
          },
          stackable: 'Stackable',
          stackableValues: {
            yes: 'Yes',
            no: 'No'
          },
          dimension: {
            length_M: 'Length (cm)',
            length_I: 'Length (in)',
            width_M: 'Width (cm)',
            width_I: 'Width (in)',
            height_M: 'Height (cm)',
            height_I: 'Height (in)'
          },
          content: 'Content',
          weight: 'Weight',
          furtherEntryLabel: 'Further Entry'
        },
        requestError: 'Error happened while trying to send the complaint'
      }
    }
  },
  de: {
    kurzComplaint: {
      modal: {
        header: 'Reklamation anmelden',
        step1: {
          nextStep: 'Nächster Schritt',
          subTitle: 'Produkte in dieser Bestellung',
          registerComplaintCheckbox: 'Reklamieren',
          complaintInfo: 'Bitte erläutern Sie die Details und den Grund für Ihre Reklamation',
          quantity: 'Anzahl',
          openedRoll: 'Angebrochene Rollen',
          batch: 'Charge',
          reason: 'Grund',
          sample: 'Muster',
          sampleValues: {
            yes: 'Ja',
            no: 'Nein'
          },
          area: 'Einsatzgebiet',
          complaintFollowUpValues: {
            return: 'Rücknahme',
            noReturn: 'keine Rücknahme',
            underReview: 'in Prüfung'
          },
        },
        step2: {
          subTitle: 'Abholauftrag',
          pickUpAddressTitle: 'Abholadresse',
          noPickUpAddressSelected: 'Keine Abholadresse ausgewählt',
          editPickUpAddressTitel: 'Bearbeiten',
          useManualPickUpAddressLabel: 'Verwende manuelle Adresse',
          furtherInfoTitle: 'Zusatzinfos',
          openingHours: 'Öffnungszeiten',
          collectDate: 'Abholung am',
          pickupEntriesTitle: 'Ware zur Abholung',
          quantity: 'Anzahl',
          packagingType: 'Umverpackung',
          packagingTypeValues: {
            none: 'keine',
            cardboard: 'Karton',
            palett: 'Palette'
          },
          stackable: 'Stapelbar',
          stackableValues: {
            yes: 'Ja',
            no: 'Nein'
          },
          dimension: {
            length_M: 'Länge (cm)',
            length_I: 'Länge (in)',
            width_M: 'Breite (cm)',
            width_I: 'Breite (in)',
            height_M: 'Höhe (cm)',
            height_I: 'Höhe (in)'
          },
          content: 'Inhalt',
          weight: 'Gesamtgewicht (kg)',
          furtherEntryLabel: 'Weitere Zeile'
        },
        requestError: 'Fehler trat während des Versuchs die Reklamation zu verschicken auf'
      }
    }
  },
  fr: {
    kurzComplaint: {
      modal: {
        header: 'Enregistrer une plainte',
        step1: {
          nextStep: 'L\'étape suivante',
          subTitle: 'Produits dans cette commande',
          registerComplaintCheckbox: 'se plaindre',
          complaintInfo: 'Veuillez expliquer les détails et la raison de votre plainte',
          quantity: 'Nombre',
          openedRoll: 'Dont rouleaux entamés',
          batch: 'Lot',
          reason: 'Motif détaillé du retour',
          sample: 'Échantillons de clients/d\'articles',
          sampleValues: {
            yes: 'Oui',
            no: 'Non'
          },
          area: 'Domaine d\'utilisation',
          complaintFollowUpValues: {
            return: 'Retrait',
            noReturn: 'pas de reprise',
            underReview: 'en cours d\'examen'
          },
        },
        step2: {
          subTitle: 'Ordre de collecte',
          pickUpAddressTitle: 'Adresse de collecte',
          noPickUpAddressSelected: 'Aucune adresse de collecte sélectionnée',
          editPickUpAddressTitel: 'Modifier',
          useManualPickUpAddressLabel: 'Utiliser l\'adresse manuelle',
          furtherInfoTitle: 'Informations Complémentaires',
          openingHours: 'Heures d\'ouverture',
          collectDate: 'Retrait le',
          pickupEntriesTitle: 'Marchandises à collectionner',
          quantity: 'Nombre',
          packagingType: 'Type d\'emballage',
          packagingTypeValues: {
            none: 'acon',
            cardboard: 'Carton',
            palett: 'Palette'
          },
          stackable: 'Empilable',
          stackableValues: {
            yes: 'Oui',
            no: 'Non'
          },
          dimension: {
            length_M: 'Longueur (cm)',
            length_I: 'Longueur (in)',
            width_M: 'Largeur (cm)',
            width_I: 'Largeur (in)',
            height_M: 'Hauteur (cm)',
            height_I: 'Hauteur (in)'
          },
          content: 'Contenu',
          weight: 'Poids total (kg)',
          furtherEntryLabel: 'Une autre ligne'
        },
        requestError: 'Une erreur s\'est produite lors de la tentative d\'envoi de la plainte'
      }
    }
  },
  nl: {
    kurzComplaint: {
      modal: {
        header: 'Registreer een klacht',
        step1: {
          nextStep: 'Volgende stap',
          subTitle: 'Producten in deze volgorde',
          registerComplaintCheckbox: 'Klagen',
          complaintInfo: 'Geef een toelichting op de details en de reden van uw klacht',
          quantity: 'Aantal',
          openedRoll: 'daarvan geopende rollen',
          batch: 'Laad',
          reason: 'Gedetailleerde reden voor retourzending',
          sample: 'Klanten/artikelvoorbeelden',
          sampleValues: {
            yes: 'Ja',
            no: 'Geen'
          },
          area: 'Gebruiksgebied',
          complaintFollowUpValues: {
            return: 'Terugnemen',
            noReturn: 'geen aflossing',
            underReview: 'in behandeling'
          },
        },
        step2: {
          subTitle: 'Ophaalopdracht',
          pickUpAddressTitle: 'Afhaaladres',
          noPickUpAddressSelected: 'Geen afhaaladres geselecteerd',
          editPickUpAddressTitel: 'Bewerking',
          useManualPickUpAddressLabel: 'Gebruik handmatig adres',
          furtherInfoTitle: 'Extra informatie',
          openingHours: 'Openingstijden',
          collectDate: 'Collectie op',
          pickupEntriesTitle: 'Goederen voor verzameling',
          quantity: 'Aantal',
          packagingType: 'Type verpakking',
          packagingTypeValues: {
            none: 'geen',
            cardboard: 'Karton',
            palett: 'Palett'
          },
          stackable: 'Stapelbar',
          stackableValues: {
            yes: 'Ja',
            no: 'Geen'
          },
          dimension: {
            length_M: 'Lengte (cm)',
            length_I: 'Lengte (in)',
            width_M: 'Breedte (cm)',
            width_I: 'Breedte (in)',
            height_M: 'Hoogte (cm)',
            height_I: 'Hoogte (in)'
          },
          content: 'Inhoud',
          weight: 'Totaal gewicht (kg)',
          furtherEntryLabel: 'Nog een lijn'
        },
        requestError: 'Er is een fout opgetreden bij het verzenden van de klacht'
      }
    }
  },
  chunks: [
    'kurzComplaint'
  ]
};
