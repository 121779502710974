
export const kurzReorderTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    kurzReorder: {
      invalidModal: {
        multiEntries: {
          title: 'Order To Cart',
          productsToCartButton: 'Add products to cart',
          notReorderableSection: {
            sectionTitle: 'Note: The following products can not be reordered:',
            status: 'Can not be reordered'
          },
          validSection: {
            sectionTitle: 'Reorder',
            addProductToCart: 'Add product to cart'
          },
          invalidSection: {
            sectionTitle: 'Modify position and reorder',
            addProductToCart: 'Add product to cart',
            configNotAvailable: {
              status: 'Configuration not possible.',
              detailsHTML: 'The selected configuration is not possible.'
            },
            contractContingentTooLow: {
              status: 'Not enough contract quota available',
              detailsHTML: `
                <div>There is not enough contingent available for this product in the contract.</div>
                <div>Please contact customer service for a new contract.</div>`,
            },
            noContingentLeftOrContractExpired: {
              status: 'No valid contract exists',
              detailsHTML: `
              <div>No more quota available for this product or contract has expired.</div>
              <div>For a new contract please contact customer service.</div>`
            },
            noPrice: {
              status: 'No price stored',
              detailsHTML: 'No price stored for this product'
            },
            projectExpired: {
              status: 'No project price available',
              detailsHTML: `
              <div>The project for this product has expired.</div>
              <div>Please contact customer service for a new project.</div>`
            },
            notReorderable: {
              status: 'Order not possible',
              detailsHTML: '<b>This product can no longer be ordered.</b>'
            }
          }
        }
      }
    }
  },
  // -------------------------- DE
  de: {
    kurzReorder: {
      invalidModal: {
        multiEntries: {
          title: 'Bestellung in den Warenkorb',
          productsToCartButton: 'Produkte in den Warenkorb',
          notReorderableSection: {
            sectionTitle: 'Achtung, folgende Produkte können nicht erneut bestellt werden:',
            status: 'Bestellung nicht möglich'
          },
          validSection: {
            sectionTitle: 'Erneut kaufen',
            addProductToCart: 'Produkt in den Warenkorb'
          },
          invalidSection: {
            sectionTitle: 'Positionen anpassen und nochmals kaufen',
            addProductToCart: 'Produkt in den Warenkorb',
            configNotAvailable: {
              status: 'Konfiguration nicht verfügbar',
              detailsHTML: 'Die gewählte Konfiguration ist nicht verfügbar.'
            },
            contractContingentTooLow: {
              status: 'Nicht genügend Rahmenvertrag-Kontingent verfügbar',
              detailsHTML: `
                <div>Für dieses Produkt ist nicht genügend Kontingent im Rahmenvertrag verfügbar.</div>
                <div>Aus technischen Gründen ist eine Bestellung von Kontrakten nur über die Produktseite möglich.</div>
                <div>Für einen neuen Rahmenvertrag kontaktieren Sie bitte Ihren Kundenberater.</div>`,
            },
            noContingentLeftOrContractExpired: {
              status: 'Rahmenvertrag nicht verfügbar',
              detailsHTML: `
              <div>Für dieses Produkt sind keine Kontingente mehr vorhanden oder der Rahmenvertrag ist abgelaufen.</div>
              <div>Für einen neuen Rahmenvertrag kontaktieren Sie bitte Ihren Kundenberater.</div>`
            },
            noPrice: {
              status: 'Kein Preis vorhanden',
              detailsHTML: 'Für dieses Produkt ist kein Preis hinterlegt.'
            },
            projectExpired: {
              status: 'Projektpreis nicht verfügbar',
              detailsHTML: `
              <div>Für dieses Produkt ist das Projekt abgelaufen.</div>
              <div>Für ein neues Projekt kontaktieren Sie bitte Ihren Kundenberater.</div>`
            },
            notReorderable: {
              status: 'Bestellung nicht möglich',
              detailsHTML: '<b>Dieses Produkt ist nicht mehr bestellbar.</b>'
            }
          }
        }
      }
    }
  },
  // -------------------------- FR
  fr: {
    kurzReorder: {
      invalidModal: {
        multiEntries: {
          title: 'Ajouter la commande au panier',
          productsToCartButton: 'Ajouter des produits au panier',
          notReorderableSection: {
            sectionTitle: 'Attention, les produits suivants ne peuvent pas être commandés à nouveau',
            status: 'Commande pas possible'
          },
          validSection: {
            sectionTitle: 'Acheter de nouveau',
            addProductToCart: 'Ajouter le produit au panier'
          },
          invalidSection: {
            sectionTitle: 'Ajuster les positions et acheter à nouveau',
            addProductToCart: 'Ajouter le produit au panier',
            configNotAvailable: {
              status: 'Confection personnalisée pas possible',
              detailsHTML: 'Cette combinaison n\'est pas possible. '
            },
            contractContingentTooLow: {
              status: 'Commande sur appel épuisée',
              detailsHTML: `
                <div>Pour ce produit il n'y a plus de quantité disponible de la commande sur appel.</div>
                <div>Par des raisons techniques, passer une commande n'est possible que par le moyen de visiter la page des produits.</div>
                <div>Pour initialiser une nouvelle commande sur appel, veuillez contacter votre conseiller client. </div>`,
            },
            noContingentLeftOrContractExpired: {
              status: 'Commande sur appel n\'existe pas',
              detailsHTML: `
              <div>Pour ce produit il n'y a plus de quantité disponible de la commande sur appel ou elle est épuisée.</div>
              <div>Pour initialiser une nouvelle commande sur appel, veuillez contacter votre conseiller client.</div>`
            },
            noPrice: {
              status: 'Aucun prix disponible',
              detailsHTML: 'Manque de prix pour ce produit'
            },
            projectExpired: {
              status: 'Prix de projet n\'existe pas',
              detailsHTML: `
              <div>Le projet pour ce produit a expiré.</div>
              <div>Pour un nouveau projet, veuillez contacter votre conseiller client.</div>`
            },
            notReorderable: {
              status: 'Commande pas possible',
              detailsHTML: '<b>Ce produit ne peut plus être commandé</b>'
            }
          }
        }
      }
    }
  },
  // -------------------------- NL
  nl: {
    kurzReorder: {
      invalidModal: {
        multiEntries: {
          title: 'Bestelling in de winkelwagen',
          productsToCartButton: 'Producten in de winkelwagen',
          notReorderableSection: {
            sectionTitle: 'Let op, de volgende producten kunnen niet opnieuw worden besteld:',
            status: 'Bestelling niet mogelijk'
          },
          validSection: {
            sectionTitle: 'Opnieuw kopen',
            addProductToCart: 'Product in de winkelwagen'
          },
          invalidSection: {
            sectionTitle: 'Posities aanpassen en opnieuw kopen',
            addProductToCart: 'Product in de winkelwagen',
            configNotAvailable: {
              status: 'Configuratie is niet beschikbaar',
              detailsHTML: 'De geselecteerde configuratie is niet beschikbaar'
            },
            contractContingentTooLow: {
              status: 'Onvoldoende raamverdrag contingenten beschikbaar',
              detailsHTML: `
                <div>Voor dit product is niet genoeg contingent in het raamverdrag beschikbaar.</div>
                <div>Om technische redenen is een nabestelling van contract producten alleen via de product pagina bij te bestellen.</div>
                <div>Neem contact op met uw accountmanager voor een nieuw raamcontract.</div>`,
            },
            noContingentLeftOrContractExpired: {
              status: 'Raamovereenkomst niet beschikbaar',
              detailsHTML: `
              <div>Voor dit product zijn geen contingenten beschikbaar of het raamverdrag is verlopen.</div>
              <div>Neem contact op met uw accountmanager voor een nieuw raamcontract.</div>`
            },
            noPrice: {
              status: 'Geen prijs beschikbaar',
              detailsHTML: 'Voor dit product is geen prijs opgeslagen.'
            },
            projectExpired: {
              status: 'Projectprijs is niet beschikbaar.',
              detailsHTML: `
              <div>Voor dit product is het project afgelopen.</div>
              <div>Neem voor een nieuw contract contact op met uw accountmanager.</div>`
            },
            notReorderable: {
              status: 'Bestelling niet mogelijk',
              detailsHTML: '<b>Dit product is niet meer te bestellen.</b>'
            }
          }
        }
      }
    }
  },
  chunks: [
    'kurzReorder',
  ]
};
