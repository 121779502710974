

export const stockSaleTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    stockSale: {
      title: 'Stock Sale',
      infoModal: {
        title: 'Stock Sale',
        list: `
        <ul>
          <li>Well known KURZ top quality</li>
          <li>Huge selection</li>
          <li>Fast accessibility</li>
          <li>Booked until 11.30 am, dispatched the same day</li>
        </ul>
        `,
      },
      extraFacetGroup: {
        groupName: 'Assortment',
        valueName: 'Stock'
      },
      toggleHeader: 'Available Stock Level',
      stateText: '{{current}} of {{total}} Products',
    }
  },
  // -------------------------- en_US
  en_US: {
    stockSale: {
      infoModal: {
        list: `
        <ul>
          <li>All orders will ship within 1 business day or less</li>
          <li>Orders will arrive in 2-5 business days from shipping date</li>
          <li>See transit time maps for ground estimates</li>
        </ul>
        `,
      }
    }
  },
  // -------------------------- en_AU
  en_AU: {
    stockSale: {
      infoModal: {
        list: `
        <ul>
          <li>Well known KURZ top quality</li>
          <li>Huge selection</li>
          <li>Fast accessibility</li>
        </ul>
        `,
      }
    }
  },
  // -------------------------- en_CH
  en_CH: {
    stockSale: {
      infoModal: {
        list: `
        <ul>
          <li>Well known KURZ quality</li>
          <li>Large choice</li>
          <li>Ordered until 3 p.m., shipped today</li>
        </ul>
        `,
      }
    }
  },
  // -------------------------- DE
  de: {
    stockSale: {
      title: 'Lagerrollenverkauf',
      infoModal: {
        title: 'Lagerrollenverkauf',
        list: `
        <ul>
          <li>Gewohnte KURZ Top-Qualität</li>
          <li>Riesige Auswahl</li>
          <li>Schnelle Verfügbarkeit</li>
          <li>Bis 11:30 Uhr gebucht, heute noch versendet</li>
        </ul>
        `,
      },
      extraFacetGroup: {
        groupName: 'Sortiment',
        valueName: 'Lagerbestand'
      },
      toggleHeader: 'Verfügbare Lagerrollen',
      stateText: '{{current}} von {{total}} Produkte',
    }
  },
  // -------------------------- de_CH
  de_CH: {
    stockSale: {
      infoModal: {
        list: `
        <ul>
          <li>Gewohnte KURZ Qualität</li>
          <li>Grosse Auswahl</li>
          <li>Bis 15:00 Uhr bestellt, heute noch versendet</li>
        </ul>
        `,
      }
    }
  },
  // -------------------------- FR
  fr: {
    stockSale: {
      title: 'Stock',
      infoModal: {
        title: 'Stock en ligne disponible',
        list: `
        <ul>
          <li>Qualité KURZ</li>
          <li>Large choix</li>
          <li>Accessibilité rapide</li>
        </ul>
        `,
      },
      extraFacetGroup: {
        groupName: 'Assortiment',
        valueName: 'Stock'
      },
      toggleHeader: 'Stock disponible',
      stateText: '{{current}} de {{total}} Produits',
    }
  },
  // -------------------------- fr_CH
  fr_CH: {
    stockSale: {
      infoModal: {
        list: `
        <ul>
          <li>Qualité KURZ</li>
          <li>Large choix</li>
          <li>Commande jusqu'à 15h00, expédition le jour même</li>
        </ul>
        `,
      }
    }
  },
  // -------------------------- NL
  nl: {
    stockSale: {
      title: 'Outlet',
      infoModal: {
        title: 'Outlet',
        list: `
        <ul>
          <li>Gebruikelijke KURZ topkwaliteit</li>
          <li>Enorme selectie</li>
          <li>Snelle beschikbaarheid</li>
          <li>Geboekt tot 11.30 uur, vandaag verzonden</li>
        </ul>
        `,
      },
      extraFacetGroup: {
        groupName: 'Assortiment',
        valueName: 'Outlet'
      },
      toggleHeader: 'Beschikbaar voorraadniveau',
      stateText: '{{current}} van de {{total}} Producten',
    }
  },
  chunks: [
    'stockSale'
  ]
};
