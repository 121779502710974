
export const quickOrderTranslations = { //NOSONAR
  // -------------------------- en
  en: {
    quickOrder: {
      header: {
        title: 'Quick Ordering',
        position_singular: '{{n}} Position',
        position_plural: '{{n}} Positions',
        addToCartLabel: 'Add To Cart'
      },
      entry: {
        quantity: 'Quantity',
        totalArea: 'Total Area',
        myMaterialNo: 'My Material No.',
        myReference: 'My Reference',
        totalPrice: 'Total Price',
        deleteMessage: 'Product will be deleted.',
        noConfigurationsFound: 'No configurations found',
        requestConfigurationThroughPDP: 'Configurations can only be requested through its product page',
      },
      addProduct: 'Add a product',
      searchFor: 'Search for...',
      noProducts: 'No Products found',
    }
  },
  // -------------------------- de
  de: {
    quickOrder: {
      header: {
        title: 'Schnellbestellung',
        position_singular: '{{n}} Position',
        position_plural: '{{n}} Positionen',
        addToCartLabel: 'In den Warenkorb'
      },
      entry: {
        quantity: 'Anzahl',
        totalArea: 'Gesamtfläche',
        myMaterialNo: 'Meine Materialnummer',
        myReference: 'Meine Referenz',
        totalPrice: 'Preis',
        deleteMessage: 'Der Artikel wird gelöscht.',
        noConfigurationsFound: 'Keine Konfiguration gefunden',
        requestConfigurationThroughPDP: 'Konfigurationen können nur über die Produktseite angefordert werden',
      },
      addProduct: 'Produkt hinzufügen',
      searchFor: 'Suchen nach...',
      noProducts: 'Keine Produkte gefunden',
    }
  },
  // ----------------------------- fr
  fr: {
    quickOrder: {
      header: {
        title: 'Commande rapide',
        position_singular: '{{n}} Position',
        position_plural: '{{n}} Positions',
        addToCartLabel: 'Ajouter au panier'
      },
      entry: {
        quantity: 'Quantité',
        totalArea: 'Surface',
        myMaterialNo: 'Mon code article',
        myReference: 'Ma référence',
        totalPrice: 'Montant (HT)',
        deleteMessage: 'Ce produit sera annulé',
        noConfigurationsFound: 'Aucune configuration trouvée',
        requestConfigurationThroughPDP: 'Des configurations ne peuvent être demandées que via sa page produit',
      },
      addProduct: 'Ajouter un produit',
      searchFor: 'Rechercher ...',
      noProducts: 'Aucun produit trouvé',
    }
  },
  // ----------------------------- nl
  nl: {
    quickOrder: {
      header: {
        title: 'Snel bestellen',
        position_singular: '{{n}} Positie',
        position_plural: '{{n}} Posities',
        addToCartLabel: 'Voeg toe aan winkelwagentje'
      },
      entry: {
        quantity: 'Hoeveelheid',
        totalArea: 'Oppervlakte',
        myMaterialNo: 'Mijn materiaalnr.',
        myReference: 'Mijn referentie',
        totalPrice: 'Prijs (netto)',
        deleteMessage: 'Dit artikel wordt verwijderd',
        noConfigurationsFound: 'Geen configuratie gevonden',
        requestConfigurationThroughPDP: 'Configuraties kunnen alleen worden aangevraagd via de productpagina',
      },
      addProduct: 'Voeg een product toe',
      searchFor: 'Zoeken naar...',
      noProducts: 'Geen producten gevonden'
    }
  },
  // ----------------------------- CHUNKS
  chunks: [
    'quickOrder'
  ]

};
