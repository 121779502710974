// English translations for common objects are done my spartacus
// https://github.com/SAP/spartacus .\projects\assets\src\translations\en\common.ts

export const commonTranslations = { //NOSONAR
  // -------------------------- EN
  en: {/* can be used to override or extend default translations */
    common: {
      logout: 'Logout',
      login: 'Login',
      apply: 'Apply',
      send: 'Send',
      yes: 'Yes',
      no: 'No',
      indifferent: 'indifferent',
      undo: 'Undo',
      redo: 'Redo',
      copy: 'Copy',
      download: 'Download',
      ok: 'OK',
      homepage: 'Home',
      loading: 'Loading',
      breadcrumb: {
        backToCart: 'Back To Cart'
      },
      ready: 'Ready',
      retry: 'retry',
      today: 'Today',
      start: 'Start',
      end: 'End',
      countryId: {
        'DE': 'Germany',
        'FR': 'France',
        'BE': 'Belgium',
        'NL': 'Netherlands',
        'LU': 'Luxembourg',
        'GB': 'United Kingdom',
        'CH': 'Switzerland',
        'NZ': 'New Zealand',
        'AU': 'Australia',
        'US': 'USA',
        'GR': 'Greece',
        'ES': 'Spain',
        'CA': 'Canada'
      },
    },
    formErrors: {
      cxEmailsMustMatch: 'Email fields must match',
      cxInvalidPassword: 'This is not a valid password format',
      cxPasswordsMustMatch: 'Password fields must match',
      required: 'This field is required',
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'Old password incorrect.',
      sessionExpired: 'Your session has expired. Please login again.',
      validationErrors: {
        invalid: {
          sessionCart: 'An Error happened. Please try again in a few Minutes.'
        }
      }
    },
  },
  // -------------------------- DE
  de: {
    common: {
      apply: 'Übernehmen',
      logout: 'Abmelden',
      login: 'Anmelden',
      cancel: 'Abbrechen',
      delete: 'Löschen',
      remove: 'Entfernen',
      edit: 'Bearbeiten',
      restore: 'Wiederherstellen',
      back: 'Zurück',
      submit: 'Abschicken',
      continue: 'Weiter',
      save: 'Speichern',
      done: 'Fertigstellen',
      home: 'Home',
      noData: 'Keine Daten.',
      confirm: 'Bestätigen',
      more: 'mehr',
      close: 'Schließen',
      loading: 'Laden',
      menu: 'Menü',
      reset: 'Zurücksetzen',
      search: 'Suchen',
      add: 'Hinzufügen',
      send: 'Absenden',
      yes: 'Ja',
      no: 'Nein',
      indifferent: 'Egal',
      undo: 'Rückgängig',
      redo: 'Wiederherstellen',
      copy: 'Kopieren',
      download: 'Herunterladen',
      ok: 'OK',
      homepage: 'Startseite',
      breadcrumb: {
        backToCart: 'Zurück zum Warenkorb'
      },
      ready: 'Fertig',
      retry: 'Wiederholen',
      today: 'Heute',
      start: 'Start',
      end: 'Ende',
      countryId: {
        'DE': 'Deutschland',
        'FR': 'Frankreich',
        'BE': 'Belgien',
        'NL': 'Niederlande',
        'LU': 'Luxemburg',
        'GB': 'Vereinigtes Königreich',
        'CH': 'Schweiz',
        'NZ': 'Neuseeland',
        'AU': 'Australien',
        'US': 'USA',
        'GR': 'Griechenland',
        'ES': 'Spanien',
        'CA': 'Kanada'
      },
    },
    formErrors: {
      cxEmailsMustMatch: 'DE: Email fields must match',
      cxInvalidPassword: 'DE: This is not a valid password format',
      cxPasswordsMustMatch: 'DE: Password fields must match',
      required: 'DE: This field is required',
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'DE: Old password incorrect.',
      sessionExpired: 'DE: Your session has expired. Please login again.',
      validationErrors: {
        invalid: {
          sessionCart: 'Ein Fehler ist aufgetreten Bitte versuchen Sie es in ein paar Minuten noch einmal.'
        }
      }
    },
  },
  // -------------------------- FR
  fr: {
    common: {
      apply: 'Reprendre',
      logout: 'Déconnexion',
      login: 'Connexion',
      cancel: 'Annuler',
      delete: 'Supprimer',
      remove: 'supprimer',
      edit: 'Modifier',
      restore: 'Wiederherstellen',
      back: 'retour',
      submit: 'Envoyer',
      continue: 'Continuer',
      save: 'Enregistrer',
      done: 'Fertigstellen',
      home: 'Home',
      noData: 'Pas de données',
      confirm: 'Confirmer',
      more: 'plus',
      close: 'fermer',
      loading: 'Chargement',
      menu: 'Menu',
      reset: 'réinitialiser',
      search: 'chercher',
      add: 'ajouter',
      send: 'Envoyer',
      yes: 'oui',
      no: 'non',
      indifferent: 'peu importe',
      undo: 'Annuler',
      redo: 'Refaire',
      copy: 'Copier',
      download: 'télécharger',
      ok: 'OK',
      homepage: 'Page d\'accueil',
      breadcrumb: {
        backToCart: 'retour au panier'
      },
      ready: 'fini',
      retry: 'Recommencer',
      today: 'Aujourd\'hui',
      start: 'Commencer',
      end: 'Fin',
      countryId: {
        'DE': 'Allemagne',
        'FR': 'France',
        'BE': 'Belgique',
        'NL': 'Pays-Bas',
        'LU': 'Luxembourg',
        'GB': 'Royaume-Uni',
        'CH': 'Suisse',
        'NZ': 'Nouvelle-Zélande',
        'AU': 'Australie',
        'US': 'États-Unis',
        'GR': 'Grèce',
        'ES': 'Espagne',
        'CA': 'Canada'
      },
    },
    formErrors: {
      cxEmailsMustMatch: 'FR: Email fields must match',
      cxInvalidPassword: 'FR: This is not a valid password format',
      cxPasswordsMustMatch: 'FR: Password fields must match',
      required: 'FR: This field is required',
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'FR: Old password incorrect.',
      sessionExpired: 'FR: Your session has expired. Please login again.',
      validationErrors: {
        invalid: {
          sessionCart: 'Une erreur est survenue. Veuillez essayer à nouveau dans quelques minutes.'
        }
      }
    },
  },
  // -------------------------- NL
  nl: {
    common: {
      apply: 'overnemen',
      logout: 'Uitloggen',
      login: 'Inloggen',
      cancel: 'Annuleren',
      delete: 'verwijderen',
      remove: 'Verwijder',
      edit: 'bewerken',
      restore: 'Herstel',
      back: 'terug',
      submit: 'Verzenden',
      continue: 'verder',
      save: 'Opslaan',
      done: 'Fertigstellen',
      home: 'Home',
      noData: 'Geen gegevens.',
      confirm: 'Bevestig',
      more: 'meer',
      close: 'Sluiten',
      loading: 'Laden',
      menu: 'Menu',
      reset: 'reset',
      search: 'zoeken',
      add: 'toevoegen',
      send: 'Verzenden',
      yes: 'Ja',
      no: 'Nee',
      indifferent: 'Egal',
      undo: 'Ongedaan maken',
      redo: 'Wiederherstellen',
      copy: 'Kopiëren',
      download: 'Downloaden',
      ok: 'OK',
      homepage: 'Homepage',
      breadcrumb: {
        backToCart: 'terug naar de winkelwagen'
      },
      ready: 'klaar',
      retry: 'Herzien',
      today: 'Vandaag',
      start: 'Begin',
      end: 'Einde',
      countryId: {
        'DE': 'Duitsland',
        'FR': 'Frankrijk',
        'BE': 'België',
        'NL': 'Nederland',
        'LU': 'Luxemburg',
        'GB': 'Verenigd Koninkrijk',
        'CH': 'Zwitserland',
        'NZ': 'Nieuw-Zeeland',
        'AU': 'Australië',
        'US': 'Verenigde Staten',
        'GR': 'Griekenland',
        'ES': 'Frankrijk',
        'CA': 'Canada'
      },
    },
    formErrors: {
      cxEmailsMustMatch: 'NL: Email fields must match',
      cxInvalidPassword: 'NL: This is not a valid password format',
      cxPasswordsMustMatch: 'NL: Password fields must match',
      required: 'NL: This field is required',
    },
    httpHandlers: {
      badRequestOldPasswordIncorrect: 'NL: Old password incorrect.',
      sessionExpired: 'NL: Your session has expired. Please login again.',
      validationErrors: {
        invalid: {
          sessionCart: 'Er is een fout opgetreden. Probeer het over een paar minuten opnieuw.'
        }
      }
    },
  }
};
