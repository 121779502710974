
export const pricelistTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    pricelist: {
      title: 'Price List',
      noPricesMainText: 'No result was found for your search criteria.',
      noPricesSubText: 'Please adjust your search.',
      effectiveAsOf: 'Effective as of:',
      textFilterLabel: 'Searching For...',
      textFilterPlaceholder: 'Searching For...',
      statusFilterLabel: 'Price States',
      statusFilterPlaceholder: 'Price Base: All',
      tableColumns: {
        number: 'Material Number',
        name: 'Product',
        priceBase: 'Price Basis',
        unit: 'Unit',
        price: 'Price'
      },
      priceBaseType: {
        standard: 'Standard',
        contract: 'Contract',
        individual: 'Individual',
        project: 'Project',
        scalePriceString: 'as of {{scaleTranslation}}',
        scalePrice: 'Scale Price',
        tailorMade: 'Tailor Made',
        tailorMadeTooltip: 'Tailor made prices are available for specific special configurations.',
        bestInClass: 'Best in Class',
        bestInClassTooltip: 'Best in class prices are available for specific standard configurations.',
        singular: {
          standard: 'Standard',
          contract: 'Contract',
          project: 'Project',
          scalePrice: 'Scale Price',
          tailorMade: 'Tailor Made',
          bestInClass: 'Best in Class'
        },
        plural: {
          standard: 'Standard',
          contract: 'Contracts',
          project: 'Projects',
          scalePrice: 'Scale Prices',
          tailorMade: 'Tailor Made Prices',
          bestInClass: 'Best in Class Prices'
        }
      },
      tableCells: {
        m2: 'Per {{unitTranslation}}',
        rol: 'Per {{unitTranslation}}',
        pfi: '/ {{unitTranslation}}',
        msi: '/ {{unitTranslation}}',
        mmm: 'Per {{unitTranslation}}',
        fti: '/ {{unitTranslation}}',
        pieces: 'Per {{unitTranslation}}',
        unitNotFound: '{{unitTranslation}}',
      },
      downloadButtonLabel: 'Download',
      stateText: '{{current}} of {{total}} prices',
      showMore: 'Show more results',
      modal: {
        header: 'Confirm',
        question: 'Would you like to go to the product detail page of the requested product "{{name}}"?'
      }
    }
  },
  // -------------------------- DE
  de: {
    pricelist: {
      title: 'Preisliste',
      noPricesMainText: 'Für Ihre Suchkriterien wurde kein Ergebnis gefunden.',
      noPricesSubText: 'Bitte passen Sie Ihre Suche an.',
      effectiveAsOf: 'Stand:',
      textFilterLabel: 'Suche',
      textFilterPlaceholder: 'Suche',
      statusFilterLabel: 'Preisgrundlagen',
      statusFilterPlaceholder: 'Preisgrundlage: Alle',
      tableColumns: {
        number: 'Materialnummer',
        name: 'Produkt',
        priceBase: 'Preisgrundlage',
        unit: 'Einheit',
        price: 'Preis (netto)'
      },
      priceBaseType: {
        standard: 'Standardpreis',
        contract: 'Kontraktpreis',
        individual: 'Individuell',
        project: 'Projektpreis',
        scalePriceString: 'Ab {{scaleTranslation}}',
        scalePrice: 'Staffelpreis',
        tailorMade: 'Tailor Made',
        tailorMadeTooltip: 'Tailor Made Preise sind für bestimmte Spezial-Konfigurationen vorhanden.',
        bestInClass: 'Best in Class',
        bestInClassTooltip: 'Best in Class Preise sind für bestimmte Standard-Konfigurationen vorhanden.',
        singular: {
          standard: 'Standard',
          contract: 'Kontrakt',
          project: 'Projekt',
          scalePrice: 'Staffelpreis',
          tailorMade: 'Tailor Made',
          bestInClass: 'Best in Class'
        },
        plural: {
          standard: 'Standard',
          contract: 'Kontrakte',
          project: 'Projekte',
          scalePrice: 'Staffelpreise',
          tailorMade: 'Tailor Made-Preise',
          bestInClass: 'Best in Class-Preise'
        }
      },
      tableCells: {
        m2: 'Per {{unitTranslation}}',
        rol: 'Per {{unitTranslation}}',
        pfi: '/ {{unitTranslation}}I',
        msi: '/ {{unitTranslation}}I',
        mmm: 'Per {{unitTranslation}}',
        fti: '/ {{unitTranslation}}I',
        pieces: 'Per {{unitTranslation}}',
        unitNotFound: '{{unitTranslation}}'
      },
      downloadButtonLabel: 'Herunterladen',
      stateText: '{{current}} von {{total}} Preise',
      showMore: 'Mehr Ergebnisse laden',
      modal: {
        header: 'Bestätigen',
        question: 'Möchten Sie auf die Produktdetailseite des gewünschten Produkts "{{name}}" geleitet werden?'
      }
    }
  },
  // -------------------------- FR
  fr: {
    pricelist: {
      title: 'Liste de prix',
      noPricesMainText: 'Aucun prix n\'a été trouvé pour vos critères de sélection.',
      noPricesSubText: 'Veuillez svp adapter vos critères de recherche !',
      effectiveAsOf: 'État :',
      textFilterLabel: 'Recherche',
      textFilterPlaceholder: 'Recherche...',
      statusFilterLabel: 'Base de prix',
      statusFilterPlaceholder: 'Base de prix : tous',
      tableColumns: {
        number: 'Code article',
        name: 'Produit',
        priceBase: 'Base de prix',
        unit: 'Unité',
        price: 'Prix'
      },
      priceBaseType: {
        standard: 'Prix standard',
        contract: 'Prix de commande sur appel',
        individual: 'Individuel',
        project: 'Prix de projet',
        scalePriceString: 'à partir de {{scaleTranslation}}',
        scalePrice: 'Prix échelonné',
        tailorMade: 'Tailor Made',
        tailorMadeTooltip: 'Prix "Tailer Made" existent pour certaines confections spécifiques',
        bestInClass: 'Best in Class',
        bestInClassTooltip: 'Prix "Best in Class" existent pour certaines confections spécifiques',
        singular: {
          standard: 'Standard',
          contract: 'Contrat',
          project: 'Projet',
          scalePrice: 'Prix échelonné',
          tailorMade: 'Tailor Made',
          bestInClass: 'Best in Class'
        },
        plural: {
          standard: 'Standard',
          contract: 'Contrats',
          project: 'Projets',
          scalePrice: 'Prix échelonnés',
          tailorMade: 'Tailor Made-Prix',
          bestInClass: 'Best in Class-Prix'
        }
      },
      tableCells: {
        m2: 'Par {{unitTranslation}}',
        rol: 'Par {{unitTranslation}}',
        pfi: '/ {{unitTranslation}}I',
        msi: '/ {{unitTranslation}}I',
        mmm: 'Par {{unitTranslation}}',
        fti: '/ {{unitTranslation}}I',
        pieces: 'Par {{unitTranslation}}',
        unitNotFound: '{{unitTranslation}}'
      },
      downloadButtonLabel: 'Télécharger',
      stateText: '{{current}} prix sur {{total}} ',
      showMore: 'Plus de résultats',
      modal: {
        header: 'Confirmer',
        question: 'Souhaitez-vous être guidé sur les pages de détails de produit du produit spécifique "{{name}}" ?'
      }
    }
  },
  // -------------------------- NL
  nl: {
    pricelist: {
      title: 'Prijslijst',
      noPricesMainText: 'Er zijn geen prijzen voor uw zoekcriteria.',
      noPricesSubText: 'Pas de zoekcriteria aan.',
      effectiveAsOf: 'Prijslijst :',
      textFilterLabel: 'Op zoek naar',
      textFilterPlaceholder: 'Op zoek naar...',
      statusFilterLabel: 'Prijs basis',
      statusFilterPlaceholder: 'Prijsbasis: Alle',
      tableColumns: {
        number: 'Artikelnummer',
        name: 'Product',
        priceBase: 'Prijs basis',
        unit: 'Eenheid',
        price: 'Prijs per eenheid'
      },
      priceBaseType: {
        standard: 'Standaardprijs',
        contract: 'Contractprijs',
        individual: 'Individueel',
        project: 'Projectprijs',
        scalePriceString: 'vanaf {{scaleTranslation}}',
        scalePrice: 'Staffelprijs',
        tailorMade: 'Op maat gemaakt',
        tailorMadeTooltip: 'Tailor Made prijzen zijn voor bepaalde speciale configuraties beschikbaar.',
        bestInClass: 'Best in Class',
        bestInClassTooltip: 'Best in Class prijzen zijn voor bepaalde speciale configuraties beschikbaar.',
        singular: {
          standard: 'Standaard',
          contract: 'Contract',
          project: 'Project',
          scalePrice: 'Staffelprijs',
          tailorMade: 'Op maat gemaakt',
          bestInClass: 'Best in Class'
        },
        plural: {
          standard: 'Standaard',
          contract: 'Contracten',
          project: 'Projecten',
          scalePrice: 'Staffelprijzen',
          tailorMade: 'Op maat gemaakt-Prijzen',
          bestInClass: 'Best in Class-Prijzen'
        }
      },
      tableCells: {
        m2: 'Per {{unitTranslation}}',
        rol: 'Per {{unitTranslation}}',
        pfi: '/ {{unitTranslation}}I',
        msi: '/ {{unitTranslation}}I',
        mmm: 'Per {{unitTranslation}}',
        fti: '/ {{unitTranslation}}I',
        pieces: 'Per {{unitTranslation}}',
        unitNotFound: '{{unitTranslation}}'
      },
      downloadButtonLabel: 'Download',
      stateText: '{{current}} van {{total}} prijzen',
      showMore: 'Laad meer resultaten',
      modal: {
        header: 'Bestätigen',
        question: 'Wilt u worden doorgestuurd naar de productdetailpagina van het gewenste product "{{name}}"?'
      }
    }
  },
  chunks: [
    'pricelist'
  ]
};
