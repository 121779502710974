import { I18nConfig, deepMerge } from '@spartacus/core';
import { deepCopy } from '@util/functions/objects';
import { environment } from 'src/environments/environment';
import { cartTranslations } from './cart.translations';
import { checkoutTranslations } from './checkout.translations';
import { commonTranslations } from './common.translations';
import { kurzComplaintTranslations } from './complaint.translations';
import { contractsTranslations } from './contracts.translations';
import { customLoginTranslations } from './custom-login.translations';
import { customResetPasswordTranslations } from './custom-reset-password-translations';
import { customSearchboxTranslations } from './custom-searchbox.translations';
import { customUpdatePasswordTranslations } from './custom-update-password-translations';
import { forgottenPasswordTranslations } from './forgotten-password.translations';
import { headerTranslations } from './header.translations';
import { homepageTranslations } from './homepage.transalations';
import { metaTagsTranslations } from './meta-tags.transalations';
import { myAccountTranslations } from './my-account.translations';
import { myDetailsTranslations } from './my-details.translations';
import { newOrderTranslations } from './new-order.translations';
import { orderConfirmationTranslations } from './order-confirmation.translations';
import { orderHistoryTranslations } from './order-history.translations';
import { pricelistTranslations } from './pricelist.translations';
import { productComparisonTranslations } from './product-comparison.translations';
import { productDetailsTranslations } from './product-details.translations';
import { productFinderTranslations } from './product-finder.translations';
import { productListTranslations } from './product-list.translations';
import { quickOrderTranslations } from './quick-order.translations';
import { recosysTranslations } from './recosys.translations';
import { kurzReorderTranslations } from './reorder.translations';
import { savedCartsTranslations } from './saved-carts.translations';
import { sharedTranslations } from './shared.translations';
import { stockSaleTranslations } from './stock-sale.translations';

interface KurzIsocodeTranslationObject {
  [isocode: string]: Record<string, (Record<string, any> | string)>;
}

interface KurzChuncksTranslationObject {
  chunks: string[];
}

export type KurzTranslationObject = KurzIsocodeTranslationObject | KurzChuncksTranslationObject;


function baseConfigFiles() {

  const i18nConfig: I18nConfig = {
    i18n: {
      resources: {},
      chunks: {},
      // fallbackLang: 'en' // seperate fallback languages are set in the AppComponent through KurzI18nextTranslationService
    }
  };

  const part = environment.currentBaseSite || window.location.pathname.split('/').find(part => part.startsWith('kurz-'));
  let countrySpecificPostfix: string;
  switch (part) {
    case 'kurz-de': countrySpecificPostfix = '_DE'; break;
    case 'kurz-fr': countrySpecificPostfix = '_FR'; break;
    case 'kurz-ch': countrySpecificPostfix = '_CH'; break;
    case 'kurz-us': countrySpecificPostfix = '_US'; break;
    case 'kurz-uk': countrySpecificPostfix = '_GB'; break;
    case 'kurz-au': countrySpecificPostfix = '_AU'; break;
    case 'kurz-nl': countrySpecificPostfix = '_NL'; break;
    case 'kurz-nz': countrySpecificPostfix = '_NZ'; break;
  }

  return {i18nConfig, countrySpecificPostfix};

}

/**
 * a factory function, which traverses through the given objects and place them correctly in the config object
 * it does not matter how many languages the translation file has, they get integrated to the Spartacus i18n config
 */
export function translationsMergerFactory(): I18nConfig {

  /**
   * Terms:
   * - non specific language isocode; example: 'en' = English
   * - country specific language isocode; example 'en_US' = American English
   */

  // a collection of translation objects, whose keys are context related.
  // every translation object has translations divided in non specific language isocodes
  // and optional country specific language isocode

  let translationObjectPool: Record<string, KurzTranslationObject> = {
    common: commonTranslations,
    header: headerTranslations,
    homepage: homepageTranslations,
    myDetails: myDetailsTranslations,
    productDetails: productDetailsTranslations,
    myAccount: myAccountTranslations,
    newOrder: newOrderTranslations,
    userProfile: forgottenPasswordTranslations,
    shared: sharedTranslations,
    cart: cartTranslations,
    pricelist: pricelistTranslations,
    productlist: productListTranslations,
    contracts: contractsTranslations,
    quickOrder: quickOrderTranslations,
    orders: orderHistoryTranslations,
    checkout: checkoutTranslations,
    orderConfirmation: orderConfirmationTranslations,
    kurzReorder: kurzReorderTranslations,
    savedCarts: savedCartsTranslations,
    stockSale: stockSaleTranslations,
    customLogin: customLoginTranslations,
    customResetPassword: customResetPasswordTranslations,
    customUpdatePassword: customUpdatePasswordTranslations,
    customSearchbox: customSearchboxTranslations,
    metaTags: metaTagsTranslations,
    kurzComplaint: kurzComplaintTranslations,
    productFinder: productFinderTranslations,
    productcomparison: productComparisonTranslations,
    kurzRecosys: recosysTranslations
  };

  // a deep copy so we wont override the original translation objects
  // translationObjectPool = deepCopy(translationObjectPool);
  translationObjectPool = deepCopy(translationObjectPool);

  /**
   * Now we write all non specific language codes in the spartacus config 'i18nConfig'
   * Then if translations for the corresponding country specific language isocode exists, we override the
   * translations for the non specific language isocode
   */

  const {i18nConfig, countrySpecificPostfix} = baseConfigFiles();

  // we loop through the contextes
  Object.keys(translationObjectPool).forEach(context => {

    // we get the translation object of the context
    const translationObject = translationObjectPool[context];
    const isoCodesOrChunkKeys = Object.keys(translationObject);

    isoCodesOrChunkKeys.forEach(isoCodeOrChunks => {

      if (isoCodeOrChunks !== 'chunks') {

        const isSpecifiedIsoCode = isoCodeOrChunks.includes('_');

        // we only want to write the translations for non specific languages ('de', 'en', 'fr' or 'nl')
        if (!isSpecifiedIsoCode) {
          // creating the object, so there wont be any NPE
          if (!i18nConfig.i18n.resources[isoCodeOrChunks]) {
            i18nConfig.i18n.resources[isoCodeOrChunks] = {};
          }

          if (!i18nConfig.i18n.resources[isoCodeOrChunks][context]) {
            i18nConfig.i18n.resources[isoCodeOrChunks][context] = {};
          }

          // shallow merge
          // effectivly "writing" to the correct path of the 'i18nConfig'
          Object.assign(i18nConfig.i18n.resources[isoCodeOrChunks][context], translationObject[isoCodeOrChunks]);

          const baseSiteSpecificLang = isoCodeOrChunks + countrySpecificPostfix;

          // check if the country specific translation object of the current base site exists
          if (translationObject[baseSiteSpecificLang]) {
            deepMerge(i18nConfig.i18n.resources[isoCodeOrChunks][context], translationObject[baseSiteSpecificLang]);
          }

        }

      } else {
        Object.defineProperty(i18nConfig.i18n.chunks, context, {
          value: translationObject[isoCodeOrChunks],
          configurable: true,
          enumerable: true,
          writable: true
        });
      }

    });

  });

  return i18nConfig;
}
