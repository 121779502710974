
export const newOrderTranslations = { //NOSONAR
  en: {
    newOrder: {
      newOrder: {
        mainTitle: 'New Order',
        subTitle: 'Create Order',
        searchInstruction: 'Enter Material Name Or Number',
        resetOrder: 'Reset Order',
        saveDraft: 'Save Draft',
        addCart: 'Add To Cart'
      },
      configurableProduct: {
        link: {
          productInformation: 'Product Information',
          technicalSpecification: 'Tech Spec'
        },
        label: {
          productName: 'KURZ Material Name',
          core: 'Core',
          length_M: 'Length (m)',
          width_M: 'Width (mm)',
          length_I: 'Length (ft)',
          width_I: 'Width (in)',
          length_finishingType_core_M: 'Length (m) / Finishing Type / Core',
          length_finishingType_core_I: 'Length (ft) / Finishing Type / Core',
          cutStepPlaceholder: 'Select...',
          noContractForThisProduct: 'No related contract for this product found',
          materialNumber: 'My Material No.',
          reference: 'My Reference',
          quantity: 'Quantity',
          area: 'Area',
          requestedDeliveryDate: 'Requested Delivery Date',
          unitCosts: 'Unit Costs',
          cuttingWidth_M:' Cutting width (mm)',
          cuttingWidth_I: 'Cutting width (in)',
          amount: 'Amount',
          addCutting: 'Add cutting',
          selectedCuttings: 'Selected Cuttings:',
          remaining: 'Remaining width:',
          cuttingError:'Invalid width or quantity',
          noCuttingSelected: 'No cutting was selected'
        }
      },
      draftPurchaseOrders : {
        mainTitle: 'Draft Purchase Orders',
        subTitle: 'List of Drafts'
      }
    }
  },
  de: {
    newOrder: {
      newOrder: {
        mainTitle: 'Neue Bestellung',
        subTitle: 'Bestellung',
        searchInstruction: 'Materialbezeichnung oder -nummer finden',
        resetOrder: 'Bestellung zurücksetzen',
        saveDraft: 'Als Bestellentwurf speichern',
        addCart: 'In den Warenkorb'
      },
      configurableProduct: {
        link: {
          productInformation: 'Produkt Informationen',
          technicalSpecification: 'Techn. Spez.'
        },
        label: {
          productName: 'KURZ Materialbez.',
          core: 'Kern',
          length_M: 'Länge (m)',
          width_M: 'Breite (mm)',
          length_I: 'Länge (Fuß)',
          width_I: 'Breite (Zoll)',
          length_finishingType_core_M: 'Länge (m) / Konfektionsart / Kern',
          length_finishingType_core_I: 'Länge (Fuß) / Konfektionsart / Kern',
          cutStepPlaceholder: 'Wähle...',
          materialNumber: 'Meine Materialnr.',
          reference: 'Meine Referenz',
          quantity: 'Anzahl',
          area: 'Fläche',
          requestedDeliveryDate: 'Wunschtermin',
          unitCosts: 'Preis',
          cuttingWidth_M: 'Schnittbreite (mm)',
          cuttingWidth_I: 'Schnittbreite (in)',
          amount: 'Menge',
          addCutting: 'Schnitt hinzufügen',
          selectedCuttings: 'ausgewählte Schnitte:',
          remaining: 'verbleibende Breite:',
          cuttingError:'ungültige Breite oder Menge',
          noCuttingSelected: 'Keine Breite wurde ausgewählt',
          noContractForThisProduct: 'Keinen mit dem Produkt verknüpften Kontrakt gefunden',
        }
      },
      draftPurchaseOrders : {
        mainTitle: 'Bestellentwürfe',
        subTitle: 'Liste der Entwürfe'
      }
    }
  },
  chunks: [
    'newOrder'
  ]
};
