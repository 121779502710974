
export const orderConfirmationTranslations = { //NOSONAR
  // -------------------------- fallback EN
  en: {
    orderConfirmation: {
      thankYou: 'Thank You For Your Order!',
      whereConfirmation: 'You will receive a confirmation and summary of your order via email.',
      continueText: 'Discover further KURZ finishings and make your products unique!',
      continueShoppingLabel: 'Continue shopping'
    },
  },
  // -------------------------- en_GB
  en_GB: {},
  // -------------------------- en_US
  en_US: {},
  // -------------------------- DE
  de: {
    orderConfirmation: {
      thankYou: 'Vielen Dank für Ihre Bestellung!',
      whereConfirmation: 'Sie erhalten eine Bestätigung und Zusammenfassung Ihrer Bestellung per Mail.',
      continueText: 'Entdecken Sie weitere KURZ-Veredelungen und machen Sie Ihre Produkte einzigartig!',
      continueShoppingLabel: 'Weiter shoppen'
    }
  },
  // -------------------------- fallback FR
  fr: {
    orderConfirmation: {
      thankYou: 'Merci pour votre commande !',
      whereConfirmation: 'Vous allez recevoir une copie de votre commande par e-mail.',
      continueText: 'Découvrez d\'autres possibilités d\'ennoblissement de KURZ et rendez vos produits unique !',
      continueShoppingLabel: 'Continuer vos achats'
    }
  },
  // ----------------------------- fr_FR
  fr_FR: {},
  // ----------------------------- fr_CH
  fr_CH: {},
  // ----------------------------- fr_LU
  fr_LU: {},
  // ----------------------------- NL
  nl: {
    orderConfirmation: {
      thankYou: 'Bedankt voor uw bestelling!',
      whereConfirmation: 'U ontvangt een bevestiging en een overzicht van uw bestelling.',
      continueText: 'Ontdek meer decoratieve KURZ producten en maak uw product uniek!',
      continueShoppingLabel: 'Doorgaan met winkelen'
    }
  },
  // ----------------------------- CHUNKS
  chunks: [
    'orderConfirmation'
  ]
};
