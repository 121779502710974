export const customSearchboxTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    customSearchbox: {
      searchPlaceholder: 'Search for material number, product name, quality, ...',
      showAllResults: 'Show all results',
      noResultsFound: 'We could not find any results',
      searchIndicators: {
        product: 'Product',
        keyword: 'Keyword search'
      }
    }
  },
  // -------------------------- DE
  de: {
    customSearchbox: {
      searchPlaceholder: 'Suche nach Materialnummer, Produktbezeichnung, Qualität, …',
      showAllResults: 'Alle Ergebnisse anzeigen',
      noResultsFound: 'Wir konnten keine Ergebnisse finden',
      searchIndicators: {
        product: 'Produkt',
        keyword: 'Stichwortsuche'
      }
    }
  },
  // -------------------------- FR
  fr: {
    customSearchbox: {
      searchPlaceholder: 'Rechercher des produits par numéro d\'article, référence, qualité, …',
      showAllResults: 'Afficher tous les résultats',
      noResultsFound: 'Nous n\'avons pas trouvé de résultats',
      searchIndicators: {
        product: 'Produit',
        keyword: 'Recherche par mots-clés'
      }
    }
  },
  // -------------------------- NL
  nl: {
    customSearchbox: {
      searchPlaceholder: 'Zoek op materiaalnummer, materiaalnaam, foliekwaliteit, ...',
      showAllResults: 'Alle resultaten weergeven',
      noResultsFound: 'We konden geen resultaten vinden',
      searchIndicators: {
        product: 'Product',
        keyword: 'Zoeken op trefwoord'
      }
    }
  }
};
