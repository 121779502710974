

export const checkoutTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    checkout: {
      reviewOrder: {
        title: 'Checkout',
        orderReferencePlaceholder: 'Order Reference',
        body: {
          deliveryAddress: 'Delivery Address',
          completeDeliveryHeader: 'Complete Delivery',
          completeDeliveryLabel: 'Send complete delivery as a whole',
          deliveryMode: 'Delivery Mode',
          deliveryCondition: 'Delivery Condition',
          noDeliveryConditionsFound: 'No delivery conditions found.',
          paymentAddress: 'Payment Address',
          paymentCondition: 'Payment condition',
          paymentConditionTitle: {
            account: 'On Account'
          },
          noPaymentConditionsFound: 'No payment conditions found.',
          courierString: 'courier',
          onAccountString: 'on account',
          completeDeliveryDisabled: 'Due to differing delivery dates, a complete shipment is not possible.',
          modals: {
            editAddresses: {
              deliveryAddressesHeader: 'Select delivery address',
              paymentAddressesHeader: 'Edit Payment Address',
              currentAddress: 'Current address',
              availableAddresses: 'Stored addresses',
              thisAddressIsUsed: 'This address will be used',
              useThisAddress: 'Use this address',
              useOtherAddress: 'Enter different delivery address',
              otherAddressModal: {
                title: 'Select delivery address',
                subTitle: 'Enter different delivery address'
              }
            },
            editDeliveryMode: {
              header: 'Edit Delivery Mode',
              availableDeliveryModes: 'Delivery Modes',
              thisModeIsUsed: 'This mode is used',
              useThisMode: 'Use this mode',
            },
          },
        },
        site: {
          coupon: {
            title: 'Coupon',
            claim: 'Redeem Coupon',
            success: 'Coupon successfully claimed',
            alreadyInCart: 'Coupon already applied to cart',
            alreadyRedeemed: 'Coupon already redeemed'
          },
          position_singular: '1 Item line',
          position_n: '{{n}} Items',
          entry: {
            detailedCalc: 'Detailed price calculation',
            separateDeliveryDate: 'Differing Delivery Date',
            noPartialDelivery: 'No Partial Delivery',
            basePrice: 'Unit Price',
            positionPrice: 'Item line value',
          },
          backToCartPageLabel: 'Back',
          subtotal: 'Subtotal',
          minimumQuantitySurchage: 'low quantity surcharge',
          minimumValueSurcharge: 'minimum value surcharge',
          totalNetPrice: 'Total (net)',
          furtherDeliveryDatesInfoText: 'Show Differing Delivery Dates ',
          globalDeliveryDate: 'Delivery Date',
          confirmationLine_1: 'By sending the order',
          confirmationLine_2: 'I confirm that I have read and agree to the Terms of Delivery as well as the technical specifications of the respective foil.',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          deliveryConditionsParseText: 'Terms of Delivery',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          technicalSpecificationsParseText: 'technical specifications',
          confirmationLine_3: '',
          modals: {
            deliveryConditions: { infoText: 'For downloading the delivery conditions click here.' },
            technicalSpecifications: {
              infoText: 'Attached you will find the technical specifications for the products in your order. Further information can be found on the respective product detail pages.',
              title: {
                entry: '{{count}} Position',
                entry_other: '{{count}} Positions'
              }
            }
          },
          orderButtonLabel: 'Confirm chargeable order',
          orderError: 'An error happened while placing the order',
        }
      },
    }
  },
  // -------------------------- DE
  de: {
    checkout: {
      reviewOrder: {
        title: 'Checkout',
        orderReferencePlaceholder: 'Meine Bestellreferenz',
        body: {
          deliveryAddress: 'Lieferadresse',
          completeDeliveryHeader: 'Komplettversand',
          completeDeliveryLabel: 'Gesamte Bestellung auf einmal versenden',
          deliveryMode: 'Liefermethode',
          deliveryCondition: 'Lieferbedingung',
          noDeliveryConditionsFound: 'Es wurden keine Lieferbedingungen gefunden',
          paymentAddress: 'Rechnungsadresse',
          paymentCondition: 'Zahlungsbedingung',
          paymentConditionTitle: {
            account: 'Auf Rechnung'
          },
          noPaymentConditionsFound: 'Es wurden keine Zahlungsbedingungen gefunden',
          courierString: 'Kurier',
          onAccountString: 'Auf Rechnung',
          completeDeliveryDisabled: 'Aufgrund von abweichenden Wunschterminen ist ein Komplettversand nicht möglich.',
          modals: {
            editAddresses: {
              deliveryAddressesHeader: 'Lieferadresse auswählen',
              paymentAddressesHeader: 'Rechnungsadresse bearbeiten',
              currentAddress: 'Aktuelle Adresse',
              availableAddresses: 'Gespeicherte Adressen',
              thisAddressIsUsed: 'Diese Adresse wird verwendet',
              useThisAddress: 'Adresse verwenden',
              useOtherAddress: 'Abweichende Lieferadresse eingeben',
              otherAddressModal: {
                title: 'Lieferadresse auswählen',
                subTitle: 'Abweichende Lieferadresse eingeben'
              }
            },
            editDeliveryMode: {
              header: 'Liefermethode ändern',
              availableDeliveryModes: 'Liefermethoden',
              thisModeIsUsed: 'Diese Methode wird verwendet',
              useThisMode: 'Methode verwenden',
            },
          },
        },
        site: {
          coupon: {
            title: 'Coupon',
            claim: 'Coupon einlösen',
            success: 'Coupon erfolgreich eingelöst',
            alreadyInCart: 'Coupon bereits im Warenkorb',
            alreadyRedeemed: 'Coupon bereits eingelöst'
          },
          position_singular: '1 Position',
          position_n: '{{n}} Positionen',
          entry: {
            detailedCalc: 'Detaillierte Preisberechnung',
            separateDeliveryDate: 'Separater Wunschtermin',
            noPartialDelivery: 'Position gesamt ausliefern',
            basePrice: 'Grundpreis',
            positionPrice: 'Positionspreis',
          },
          backToCartPageLabel: 'Zurück',
          subtotal: 'Zwischensumme',
          minimumQuantitySurchage: 'Mindermengenzuschlag',
          minimumValueSurcharge: 'Mindestwertzuschlag',
          totalNetPrice: 'Gesamt (netto)',
          furtherDeliveryDatesInfoText: 'Weitere Wunschtermine für einzelne Produkte',
          globalDeliveryDate: 'Wunschtermin',
          confirmationLine_1: 'Hiermit bestätige ich, dass',
          confirmationLine_2: 'ich die Lieferbedingungen und die jeweiligen Technischen Spezifikationen der einzelnen Produkte',
          deliveryConditionsParseText: 'Lieferbedingungen',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          technicalSpecificationsParseText: 'Technischen Spezifikationen',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          confirmationLine_3: 'gelesen habe, und erkläre mich mit ihnen einverstanden.',
          modals: {
            deliveryConditions: { infoText: 'Sie können sich die Lieferbedingungen hier herunterladen.' },
            technicalSpecifications: {
              infoText: 'Anbei finden Sie die Technischen Spezifikationen zu den Produkten Ihrer Bestellung. Weitere Informationen finden Sie auf den jeweiligen Produkdetailseiten',
              title: { entry: '{{count}} Position', entry_other: '{{count}} Positionen' }
            }
          },
          orderButtonLabel: 'Jetzt kostenpflichtig bestellen',
          orderError: 'Beim Aufgeben der Bestellung ist ein Fehler aufgetreten',
        }
      }
    },
  },
  // -------------------------- FR
  fr: {
    checkout: {
      reviewOrder: {
        title: 'Finalisation',
        orderReferencePlaceholder: 'Ma référence de commande',
        body: {
          deliveryAddress: 'Adresse de livraison',
          completeDeliveryHeader: 'Expédition complète',
          completeDeliveryLabel: 'Envoyer une livraison complète dans son ensemble',
          deliveryMode: 'Mode de livraison',
          deliveryCondition: 'Conditions de livraison',
          noDeliveryConditionsFound: 'Des conditions de livraison n\'ont pas été trouvées',
          paymentAddress: 'Adresse de facturation',
          paymentCondition: 'Conditions de paiement',
          paymentConditionTitle: {
            account: 'Par facture'
          },
          noPaymentConditionsFound: 'Conditions de paiement pas trouvées',
          courierString: 'Service de livraison',
          onAccountString: 'Par facture',
          completeDeliveryDisabled: 'En raison de dates de livraison souhaitées divergentes, un envoi complet n\'est pas possible.',
          modals: {
            editAddresses: {
              deliveryAddressesHeader: 'Choisir l\'adresse de livraison',
              paymentAddressesHeader: 'Adresses de facturation',
              currentAddress: 'Adresse actuelle',
              availableAddresses: 'Adresse mémorisée',
              thisAddressIsUsed: 'L\'adresse jointe sera utilisée',
              useThisAddress: 'Utiliser l\'adresse',
              useOtherAddress: 'Introduire une adresse de livraison différente',
              otherAddressModal: {
                title: 'Choisir l\'adresse de livraison',
                subTitle: 'Introduire une adresse de livraison différente'
              }
            },
            editDeliveryMode: {
              header: 'Modifier le mode de livraison',
              availableDeliveryModes: 'Mode de livraison',
              thisModeIsUsed: 'Le mode suivant est utilisé',
              useThisMode: 'Mode à utiliser',
            },
          },
        },
        site: {
          coupon: {
            title: 'Coupon',
            claim: 'Utiliser le coupon',
            success: 'Coupon validé avec succès',
            alreadyInCart: 'Coupon déjà dans le panier',
            alreadyRedeemed: 'Coupon déjà utilisé'
          },
          position_singular: '1 Position',
          position_n: '{{n}} Positions',
          entry: {
            detailedCalc: 'Calculs de prix détaillés',
            separateDeliveryDate: 'Date de livraison séparée',
            noPartialDelivery: 'Position à livrer uniquement en complèt',
            basePrice: 'Prix de base',
            positionPrice: 'Prix de la position',
          },
          backToCartPageLabel: 'Retour',
          subtotal: 'Total HT',
          minimumQuantitySurchage: 'Supplément pour quantité inférieure à la quantité minium',
          minimumValueSurcharge: 'Supplément pour valeur inférieure à la valeur minium',
          totalNetPrice: 'Total de la commande (HT)',
          furtherDeliveryDatesInfoText: 'D\'autres dates souhaitées pour d\'autres produits',
          globalDeliveryDate: 'Date d\'expédition souhaitée',
          confirmationLine_1: 'Par l\'envoi de la commande, je confirme que j\'ai lu et approuvé',
          confirmationLine_2: 'les Conditions Générales de Vente, ainsi que les spécifications techniques des produits commandés.',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          deliveryConditionsParseText: 'Conditions Générales de Vente',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          technicalSpecificationsParseText: 'spécifications techniques',
          confirmationLine_3: '',
          modals: {
            deliveryConditions: { infoText: 'Cliquez ici pour télécharger les conditions de livraison' },
            technicalSpecifications: {
              infoText: 'Ci-joint, vous trouverez les spécifications techniques des produits de votre commande. Des informations supplémentaires sont disponibles sur les pages de détail des produits respectifs.',
              title: {
                entry: '{{count}} Position',
                entry_other: '{{count}} Positions'
              }
            }
          },
          orderButtonLabel: 'valider votre commande',
          orderError: 'Une erreur s\'est produite lors du passage de la commande',
        }
      }
    },
  },
  // -------------------------- NL
  nl: {
    checkout: {
      reviewOrder: {
        title: 'Checkout',
        orderReferencePlaceholder: 'Mijn bestelreferentie',
        body: {
          deliveryAddress: 'Leveringsadres',
          completeDeliveryHeader: 'Volledige levering',
          completeDeliveryLabel: 'Volledige levering in zijn geheel versturen',
          deliveryMode: 'Leveringswijze',
          deliveryCondition: 'Leveringsvoorwaarden',
          noDeliveryConditionsFound: 'Er zijn geen leveringsvoorwaarden gevonden',
          paymentAddress: 'Factuuradres',
          paymentCondition: 'betalingsvoorwaarden',
          paymentConditionTitle: {
            account: 'op rekening'
          },
          noPaymentConditionsFound: 'Er zijn geen betalingsvoorwaarden gevonden',
          courierString: 'koerier',
          onAccountString: 'op rekening',
          completeDeliveryDisabled: 'Vanwege afwijkende wenstermijnen is een volledige levering niet mogelijk.',
          modals: {
            editAddresses: {
              deliveryAddressesHeader: 'afleveradres kiezen',
              paymentAddressesHeader: 'Factuuradres',
              currentAddress: 'huidige adres',
              availableAddresses: 'opgeslagen adressen',
              thisAddressIsUsed: 'Dit adres wordt gebruikt',
              useThisAddress: 'Adres gebruiken',
              useOtherAddress: 'Afwijkend leveradres ingeven',
              otherAddressModal: {
                title: 'Afleveradres kiezen',
                subTitle: 'Afwijkend leveradres ingeven'
              }
            },
            editDeliveryMode: {
              header: 'Verander leveringswijze',
              availableDeliveryModes: 'Leveringswijzen',
              thisModeIsUsed: 'Deze methode wordt gebruikt',
              useThisMode: 'Methode gebruiken',
            },
          },
        },
        site: {
          coupon: {
            title: 'Coupon',
            claim: 'Coupon inwisselen',
            success: 'Coupon succesvol ingewisseld',
            alreadyInCart: 'Coupon al in winkelmandje',
            alreadyRedeemed: 'Coupon al ingewisseld'
          },
          position_singular: '1 positie',
          position_n: '{{n}} posities',
          entry: {
            detailedCalc: 'Gedetailleerde prijsberekeningen',
            separateDeliveryDate: 'Separate wenstermijn',
            noPartialDelivery: 'Positie in geheel uitleveren',
            basePrice: 'Basisprijs',
            positionPrice: 'positie prijs',
          },
          backToCartPageLabel: 'Terug',
          subtotal: 'Subtotaal',
          minimumQuantitySurchage: 'Toeslag minimum hoeveelheid',
          minimumValueSurcharge: 'Toeslag minimum waarde',
          totalNetPrice: 'Totaal (netto)',
          furtherDeliveryDatesInfoText: 'Wenstermijn voor individuele producten.',
          globalDeliveryDate: 'Wenstermijn',
          confirmationLine_1: 'Door de bestelling te verzenden, bevestig ik dat',
          confirmationLine_2: ' ik de leveringsvoorwaarden heb gelezen en hiermee akkoord ga, evenals de technische specificaties van de betreffende folie.',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          deliveryConditionsParseText: 'leveringsvoorwaarden',
          /* value needs to be a part of confirmationLine_2 to signalize the algorithm where the clickable link is. */
          technicalSpecificationsParseText: 'technische specificaties',
          confirmationLine_3: '',
          modals: {
            deliveryConditions: { infoText: 'Hier kunt u de leveringsvoorwaarden downloaden.' },
            technicalSpecifications: {
              infoText: 'Bijgevoegd vindt u de technische specificaties van de producten in uw bestelling. Meer informatie is te vinden op de respectievelijke productdetailpagina\'s',
              title: {
                entry: '{{count}} Positie',
                entry_other: '{{count}} Posities'
              }
            }
          },
          orderButtonLabel: 'Bestel nu tegen betaling',
          orderError: 'Er is een fout opgetreden tijdens het plaatsen van de bestelling',
        }
      }
    },
  },
  chunks: [
    'reviewOrder'
  ]
};
