export const productListTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    productlist: {
      title: 'KURZ Product List',
      searchTitle: 'Search for "{{term}}"',
      stateText: '{{current}} of {{total}} Products',
      addToProductComparison: 'Compare',
      griditem: {
        quality: 'Quality',
        requestPrice: 'Request Pricing',
      },
      tooltip: {
        jumboPrice: 'Jumbo price',
        rollPrice: 'Roll price',
        materialNumber: 'Material No.'
      },
      facets: {
        productFinder: 'Product Finder',
        selectedValues: 'Selected filters',
        searchFilterPlaceholder: 'Search filters'
      }
    }
  },
  // -------------------------- DE
  de: {
    productlist: {
      title: 'KURZ Produktsortiment',
      searchTitle: 'Suchergebnisse für "{{term}}"',
      stateText: '{{current}} von {{total}} Produkte',
      addToProductComparison: 'Vergleichen',
      griditem: {
        quality: 'Qualität',
        requestPrice: 'Preis auf Anfrage'
      },
      tooltip: {
        jumboPrice: 'Jumbo-Preis',
        rollPrice: 'Rollen-Preis',
        materialNumber: 'Materialnr.'
      },
      facets: {
        productFinder: 'Product Finder',
        selectedValues: 'Ausgewählte Filter',
        searchFilterPlaceholder: 'Filter durchsuchen'
      }
    }
  },
  // -------------------------- FR
  fr: {
    productlist: {
      title: 'Gamme de produits de KURZ',
      searchTitle: 'Rechercher "{{term}}"',
      stateText: '{{current}} de {{total}} produits',
      addToProductComparison: 'comparer',
      griditem: {
        quality: 'Qualité',
        requestPrice: 'Prix sur demande'
      },
      tooltip: {
        jumboPrice: 'Prix par jumbo',
        rollPrice: 'Prix par rouleau ',
        materialNumber: 'Code article'
      },
      facets: {
        productFinder: 'Product Finder',
        selectedValues: 'Filtres choisis',
        searchFilterPlaceholder: 'Rechercher les filtres'
      }
    }
  },
  // -------------------------- NL
  nl: {
    productlist: {
      title: 'KURZ-productassortiment',
      searchTitle: 'Zoeken "{{term}}"',
      stateText: '{{current}} van {{total}} producten',
      addToProductComparison: 'Vergelijken',
      griditem: {
        quality: 'kwaliteit',
        requestPrice: 'Prijs op aanvraag'
      },
      tooltip: {
        jumboPrice: 'Jumbo prijs',
        rollPrice: 'Rollen prijs',
        materialNumber: 'Materiaalnr.'
      },
      facets: {
        productFinder: 'Product Finder',
        selectedValues: 'Geselecteerde filters',
        searchFilterPlaceholder: 'Blader door filters'
      }
    }
  },
  chunks: [
    'productlist'
  ]
};
