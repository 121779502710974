export const homepageTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    homepage: {
      industries: {
        title: 'Industries',
        allProductsLabel: 'All products'
      },
      lastVisited: {
        title: 'Last visited',
        allProductsLabel: 'All products'
      },
      videos: {
        title: 'Videos'
      },
      tradeFairs: {
        title: 'Fairs & Events',
        goTo: 'To the fair'
      },
    },
    workflowSolutions: {
      learnMore: 'Learn more'
    },
    userRedirect: {
      'message': 'Select a different country to view content for your location and shop online.',
      'next': 'Next'
    }
  },
  // -------------------------- DE
  de: {
    homepage: {
      industries: {
        title: 'Anwendungsbereiche',
        allProductsLabel: 'Alle Produkte'
      },
      lastVisited: {
        title: 'Zuletzt angesehen',
        allProductsLabel: 'Alle Produkte'
      },
      videos: {
        title: 'Videos'
      },
      tradeFairs: {
        title: 'Messen & Events',
        goTo: 'Zur Messe'
      },
    },
    workflowSolutions: {
      learnMore: 'Mehr erfahren'
    },
    userRedirect: {
      'message': 'Wählen Sie ein anderes Land, um Inhalte für Ihren Standort zu sehen und online einzukaufen.',
      'next': 'Weiter'
    }
  },
  // -------------------------- FR
  fr: {
    homepage: {
      industries: {
        title: 'Zones d\'application',
        allProductsLabel: 'Tous les produits'
      },
      lastVisited: {
        title: 'Dernière visite',
        allProductsLabel: 'Tous les produits'
      },
      videos: {
        title: 'Vidéos'
      },
      tradeFairs: {
        title: 'Salons & Èvénements',
        goTo: 'Vers le salon'
      },
    },
    workflowSolutions: {
      learnMore: 'En savoir plus'
    },
    userRedirect: {
      'message': 'Choisissez un autre pays pour voir le contenu de votre région et faire vos achats en ligne.',
      'next': 'Suivant'
    }
  },
  // -------------------------- NL
  nl: {
    homepage: {
      industries: {
        title: 'Toepassingsgebieden',
        allProductsLabel: 'Alle producten'
      },
      lastVisited: {
        title: 'Laatst bezocht',
        allProductsLabel: 'Alle producten'
      },
      videos: {
        title: 'Video\'s'
      },
      tradeFairs: {
        title: 'Beurzen & Evenementen',
        goTo: 'Naar de beurs'
      },
    },
    workflowSolutions: {
      learnMore: 'Meer leren'
    },
    userRedirect: {
      'message': 'Selecteer een ander land om inhoud voor jouw locatie te bekijken en online te winkelen.',
      'next': 'Volgende'
    }
  },
  chunks: [
    'homepage',
    'workflowSolutions',
    'videos',
    'userRedirect'
  ]
};
