
export const cartTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    cart: {
      title: 'Cart',
      empty: {
        isEmpty_quick: 'Your cart is empty.',
        isEmpty: 'There are currently no items in the cart.',
        suggestion: 'Discover further KURZ Refinements and make your products unique!',
        shoppingLabel: 'Continue shopping'
      },
      addMore: 'Adding further items',
      orderReference: 'Order Reference',
      orderReferenceTooltip: 'Order Reference',
      totalItems: 'Total Items',
      list: {
        product: 'Material',
        configuration: 'Configuration',
        area: 'Area',
        quantity: 'Quantity',
        cost: 'Item Price (net)'
      },
      header: {
        orderReference: 'Order Reference',
        deliveryDate: 'Delivery Date',
        deliveryDatePlaceholder: 'Select Delivery Date',
        totalCost: 'Total (net)',
        checkoutLabel: 'Proceed Checkout',
        resetDatesWarning: 'Separate dates were reset, you must select them again.',
      },
      priceDetails: {
        title: 'Detailed Price Calculation',
        subTotalPrice: 'Sum (Positions)',
        totalPrice: 'Total (net)'
      },
      entry: {
        delete: {
          message: 'Product will be deleted.'
        },
        myMaterialNo: 'My Material No.',
        myReference: 'My Reference',
        deliveryDate: 'Delivery Date',
        deliveryDatePlaceholder: 'Choose Delivery Date',
        reconfigureButtonLabel: 'Reconfigure',
        reconfigureToastMessage: 'The item was removed from cart',
        totalArea: 'Total Area:',
        priceDetails: {
          positionPrice: 'Position Price',
          noDetailsFound: 'No details found'
        },
        separateDeliveryDate: 'Separate Delivery Date',
        deleteDeliveryDate: 'Delete Delivery Date',
        noPartialDelivery: 'No Partial Delivery'
      },
      saveAsFavourite: {
        label: 'Save as favorite order',
        success: 'Saved successfully',
        failure: 'Error when creating favorite order'
      },
      summary: {
        label: {
          download: 'Download',
          saveAsDraft: 'Save as Draft',
          proceedCheckout: 'Go to Checkout',
        },
        total: 'Total (net)'
      },
      mini: {
        miniCartLabel: 'Shopping Cart',
        toCartPageButton: 'To Cart',
        overview: {
          priceDetailsNote: 'Detailed prices on Cart Page'
        }
      },
      sideMenu: {
        quantity: 'Quantity: {{i}}'
      },
      validation: {
        notReorderable: 'Attention! These products are no longer available for order/in stock',
        stockTooLow: 'Attention! There are not enough stock items available for these products',
        contractContingentTooLow: 'Note: The contract quota is exceeded. For technical reasons reordering of contract products is only possible via product detail page.',
        maxAvailable: 'Maximum available: {{available}}',
        remove: 'Remove'
      }
    }
  },
  // -------------------------- DE
  de: {
    cart: {
      title: 'Warenkorb',
      empty: {
        isEmpty_quick: 'Ihr Warenkorb ist leer',
        isEmpty: 'Sie haben noch keine Produkte in Ihrem Warenkorb',
        suggestion: 'Entdecken Sie weitere KURZ-Veredelungen und machen Sie Ihre Produkte einzigartig!',
        shoppingLabel: 'Weiter shoppen'
      },
      addMore: 'Weitere Artikel hinzufügen',
      orderReference: 'Meine Bestellreferenz',
      orderReferenceTooltip: 'Meine Bestellreferenz',
      totalItems: 'Positionen',
      list: {
        product: 'Produkt',
        configuration: 'Konfiguration',
        area: 'Fläche',
        quantity: 'Menge',
        cost: 'Preis (netto)'
      },
      header: {
        orderReference: 'Meine Bestellreferenz',
        deliveryDate: 'Wunschtermin',
        deliveryDatePlaceholder: 'Wunschtermin wählen',
        totalCost: 'Gesamt (netto)',
        checkoutLabel: 'Zur Kasse',
        resetDatesWarning: 'Separate Daten wurden zurückgesetzt, Sie müssen sie erneut auswählen.',
      },
      priceDetails: {
        title: 'Detaillierte Preisberechnung',
        subTotalPrice: 'Summe (Positionen)',
        totalPrice: 'Gesamt (netto)'
      },
      entry: {
        delete: {
          message: 'Der Artikel wird gelöscht.'
        },
        myMaterialNo: 'Meine Materialnummer',
        myReference: 'Meine Referenz',
        deliveryDate: 'Wunschtermin',
        deliveryDatePlaceholder: 'Wunschtermin wählen',
        reconfigureButtonLabel: 'Position ändern',
        reconfigureToastMessage: 'Die alte Position wurde aus dem Warenkorb entfernt',
        totalArea: 'Gesamtfläche:',
        priceDetails: {
          positionPrice: 'Positionspreis',
          noDetailsFound: 'keine Details gefunden'
        },
        separateDeliveryDate: 'Separater Wunschtermin',
        deleteDeliveryDate: 'Wunschtermin löschen',
        noPartialDelivery: 'Keine Teillieferung erlauben'
      },
      saveAsFavourite: {
        label: 'Als Bestellfavorit speichern',
        success: 'Der Bestellfavorit wurde angelegt.',
        failure: 'Fehler beim Anlegen des Bestellfavoriten.'
      },
      summary: {
        label: {
          download: 'Herunterladen',
          saveAsDraft: 'Als Bestellentwurf speichern',
          proceedCheckout: 'Zur Kasse',
        },
        total: 'Gesamt (netto)'
      },
      mini: {
        miniCartLabel: 'Warenkorb',
        toCartPageButton: 'Zum Warenkorb',
        overview: {
          priceDetailsNote: 'Detaillierte Preise auf der Warenkorbseite'
        }
      },
      sideMenu: {
        quantity: 'Anzahl: {{i}}'
      },
      validation: {
        notReorderable: 'Achtung! Diese Produkte sind nicht mehr bestellbar/verfügbar',
        stockTooLow: 'Achtung! Für diese Produkte sind nicht genügend Lagerprodukte verfügbar',
        contractContingentTooLow: 'Achtung! Das Kontingent für Rahmenverträge wird überschritten. Aus technischen Gründen ist eine Bestellung von Kontrakten nur über die Produktseite möglich.',
        maxAvailable: 'Maximal verfügbar: {{available}}',
        remove: 'Entfernen'
      }
    }
  },
  // -------------------------- FR
  fr: {
    cart: {
      title: 'Mon panier',
      empty: {
        isEmpty_quick: 'Votre panier est vide.',
        isEmpty: 'Vous n\'avez pas encore de produits dans votre panier.',
        suggestion: 'Découvrez d\'autres finitions KURZ et rendez vos produits uniques !',
        shoppingLabel: 'Continuer les achats'
      },
      addMore: 'Ajouter d\'autres produits',
      orderReference: 'Ma référence de commande',
      orderReferenceTooltip: 'Ma référence de commande',
      totalItems: 'Positions',
      list: {
        product: 'Produit',
        configuration: 'Combinaison',
        area: 'Surface',
        quantity: 'Quantité',
        cost: 'Montant (HT)'
      },
      header: {
        orderReference: 'Ma référence de commande',
        deliveryDate: 'Date de livraison souhaitée',
        deliveryDatePlaceholder: 'Choisir la date de départ souhaitée',
        totalCost: 'Total de la commande (HT)',
        checkoutLabel: 'Validation',
        resetDatesWarning: 'Les dates séparées ont été réinitialisées , vous devez les sélectionner à nouveau.',
      },
      priceDetails: {
        title: 'Calcul de prix détaillé',
        subTotalPrice: 'Somme (Positions)',
        totalPrice: 'Total (net)'
      },
      entry: {
        delete: {
          message: 'Ce produit sera annulé'
        },
        myMaterialNo: 'Mon code article',
        myReference: 'Ma référence',
        deliveryDate: 'Date de livraison souhaitée',
        deliveryDatePlaceholder: 'Choisir la date de livraison souhaitée',
        reconfigureButtonLabel: 'Reconfigurer',
        reconfigureToastMessage: 'L\'ancien article a été supprimé du panier',
        totalArea: 'Surface:',
        priceDetails: {
          positionPrice: 'Prix de la position',
          noDetailsFound: 'aucun détail trouvé'
        },
        separateDeliveryDate: 'Date de départ souhaitée',
        deleteDeliveryDate: 'Annuler la date de livraison souhaitée',
        noPartialDelivery: 'Position à livrer uniquement en complèt'
      },
      saveAsFavourite: {
        label: 'Enregistrer comme projet de commande',
        success: 'Le projet de commande a été créé.',
        failure: 'Erreur lors de la création du produit préféré'
      },
      summary: {
        label: {
          download: 'Télécharger',
          saveAsDraft: 'Enregistrer comme projet de commande',
          proceedCheckout: 'Validation',
        },
        total: 'Total de la commande (HT)'
      },
      mini: {
        miniCartLabel: 'Panier',
        toCartPageButton: 'au panier',
        overview: {
          priceDetailsNote: 'Prix ​​détaillés sur la page du panier'
        }
      },
      sideMenu: {
        quantity: 'Quantité {{i}}'
      },
      validation: {
        notReorderable: 'Attention ! Produit ou confection personnalisée n\'est plus disponible.',
        stockTooLow: 'Attention ! Les produits en stock ne sont plus disponibles dans la quantité sélectionnée.',
        contractContingentTooLow: 'Attention, la quantité des contrats-cadres est dépassée. Pour des raisons techniques, une commande supplémentaire de produits sous contrat n\'est possible que par la page des produits.',
        maxAvailable: 'Quantité disponible: : {{available}}',
        remove: 'Enlever'
      }
    }
  },
  // -------------------------- NL
  nl: {
    cart: {
      title: 'Winkelwagen',
      empty: {
        isEmpty_quick: 'Uw winkelwagentje is leeg',
        isEmpty: 'U heeft nog geen producten in het winkelwagentje.',
        suggestion: 'Ondek meer decoratieve KURZ producten en maak uw product uniek!',
        shoppingLabel: 'Nu kopen'
      },
      addMore: 'Voeg meer artikelen toe',
      orderReference: 'Mijn bestelreferentie',
      orderReferenceTooltip: 'Mijn bestelreferentie',
      totalItems: 'Posities',
      list: {
        product: 'Product',
        configuration: 'Configuratie',
        area: 'Oppervlakte',
        quantity: 'Hoeveelheid',
        cost: 'Prijs (netto)'
      },
      header: {
        orderReference: 'Mijn bestelreferentie',
        deliveryDate: 'Wenstermijn',
        deliveryDatePlaceholder: 'Selecteer wenstermijn',
        totalCost: 'Totaal (netto)',
        checkoutLabel: 'Uitchecken',
        resetDatesWarning: 'Afzonderlijke datums werden gereset, je moet ze opnieuw selecteren.',
      },
      priceDetails: {
        title: 'Gedetailleerde prijsberekening',
        subTotalPrice: 'Som (posities)',
        totalPrice: 'Totaal (netto)'
      },
      entry: {
        delete: {
          message: 'Dit artikel wordt verwijderd'
        },
        myMaterialNo: 'Mijn materiaalnr.',
        myReference: 'Mijn referentie',
        deliveryDate: 'Wenstermijn',
        deliveryDatePlaceholder: 'Selecteer wenstermijn',
        reconfigureButtonLabel: 'Herconfigureer',
        reconfigureToastMessage: 'Het artikel is uit de winkelwagen verwijderd',
        totalArea: 'Oppervlakte:',
        priceDetails: {
          positionPrice: 'Positie prijs',
          noDetailsFound: 'geen bijzonderheden gevonden'
        },
        separateDeliveryDate: 'Separate wenstermijn',
        deleteDeliveryDate: 'Wenstermijn annuleren',
        noPartialDelivery: 'Positie in geheel uitleveren'
      },
      saveAsFavourite: {
        label: 'Sla op als favoriet',
        success: 'Voor uw bestelling wordt een favoriet aangelegt.',
        failure: 'Fout bij het ingeven van de bestelfavoriet.'
      },
      summary: {
        label: {
          download: 'Download',
          saveAsDraft: 'Sla op als favoriet',
          proceedCheckout: 'Uitchecken',
        },
        total: 'Totaal (netto)'
      },
      mini: {
        miniCartLabel: 'Winkelwagentje',
        toCartPageButton: 'Naar winkelwagentje',
        overview: {
          priceDetailsNote: 'Gedetailleerde prijzen op winkelwagenpagina'
        }
      },
      sideMenu: {
        quantity: 'Hoeveelheid: {{i}}'
      },
      validation: {
        notReorderable: 'Opgelet! Product of configuratie is niet meer beschikbaar.',
        stockTooLow: 'Opgelet! Voorraadproducten zijn niet meer beschikbaar in de gewenste hoeveelheid.',
        contractContingentTooLow: 'Let op, het quotum voor de raamovereenkomsten is overschreden. Om technische redenen is een nabestelling van contract producten alleen via de product pagina bij te bestellen.',
        maxAvailable: 'Maximaal beschikbaar: {{available}}',
        remove: 'Verwijderen'
      }
    }
  },
  chunks: [
    'cart'
  ]
};
