export const productComparisonTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    productcomparison: {
      emptyMessage: 'No products have been added for comparison yet. Select up to 3 products to compare them.',
      toast: {
        productComparisonSuccess: 'Product added to comparison',
        productComparisonError: 'Product comparison full or product already in comparison',
        buttonLabel: 'To Comparison',
      },


    }
  },
  // -------------------------- DE
  de: {
    productcomparison: {
      emptyMessage: 'Aktuell sind noch keine Produkte zum Vergleich hinzugefügt. Wählen Sie bis zu 3 Produkte aus, um sie miteinander zu vergleichen.',
      toast: {
        productComparisonSuccess: 'Produkt zum Vergleich hinzugefügt',
        productComparisonError: 'Produktvergleich voll oder Produkt bereits im Vergleich',
        buttonLabel: 'Zum Vergleich',
      },

    }
  },
  // -------------------------- FR
  fr: {
    productcomparison: {
      emptyMessage: 'Actuellement, aucun produit n\'a encore été ajouté à la comparaison. Sélectionnez jusqu\'à 3 produits pour les comparer entre eux.',
      toast: {
        productComparisonSuccess: 'Produit ajouté à la comparaison',
        productComparisonError: 'Comparaison de produits pleine ou produit déjà en comparaison',
        buttonLabel: 'À comparer',
      },

    }
  },
  // -------------------------- NL
  nl: {
    productcomparison: {
      emptyMessage: 'Er zijn momenteel geen producten toegevoegd ter vergelijking. Selecteer maximaal 3 producten om te vergelijken.',
      toast: {
        productComparisonSuccess: 'Product toegevoegd aan vergelijking',
        productComparisonError: 'Productvergelijking vol of product al in vergelijking',
        buttonLabel: 'Ter vergelijking',
      },
    }
  },
  chunks: [
    'productcomparison'
  ]
};
