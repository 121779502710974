export const productFinderTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    productfinder: {
      starting: {
        title: 'Welcome to our KURZ Product Finder!',
        text: 'With this tool you will find the perfect product for your desired needs in no time.  Just enter your preferences, and our intelligent filter will present you with a selection of products matching your search. This will save you time, and you can easily make the best choice.  Let us help you find exactly the right product for you!',
        buttonLabel: 'Start now'
      },
      step1: {
        title: 'Which substrate would you like to decorate?'
      },
      step2: {
        title: 'Please further specify the substrate you want to use.'
      },
      step3: {
        title: 'Which finishing process do you use?'
      },
      step4: {
        title: 'Please further specify the finishing process you want to use.'
      },
      step5: {
        title: 'What colors are you looking for?'
      },
      backToStartLabel: 'Start again',
      kurzFinishingProcessesInfo: {
        header: 'Comment',
        analog: {
          header: 'EN:Analog',
          content: ` ${'-'} `
        },
        sheet: {
          header: 'EN:Bogen',
          content: ` ${'-'} `
        },
        digital: {
          header: 'EN:Digital',
          content: `EN:
            <p>Digital inLINE FOILING® findet bei Kunststofftuben Anwendung. Der Transferprozess erfolgt mit wenig Druck und ohne Hitze in einem
            Maschinendurchlauf und mit hoher Geschwindigkeit. Dazu wird ein Transferträger und Inkjettechnologie genutzt.</p>
            <p>Digitaldruck, Kaltfolientransfer:<br>Das gewünschte Design wird mittels Toner oder Elektronik im Digitaldruck auf das Substrat gedruckt.
            Danach erfolgt der Transfer auf das Triggerimage.</p>
          `
        },
        hotStamping: {
          header: 'EN:Heißprägen',
          content: ` ${'-'} `
        },
        coldTransfer: {
          header: 'EN:Kalttransfer',
          content: `EN:
            <p>Das Kalttransfer auf Etiketten-Druckmaschinen ist ein Vorgang, bei dem eine metallisierte Prägefolie mittels eines speziellen, z.B.
            UV-härtenden Klebers auf ein Substrat übertragen wird.</p>
          `
        },
      },
    }
  },
  // -------------------------- DE
  de: {
    productfinder: {
      starting: {
        title: 'Willkommen zu unserem KURZ Product Finder!',
        text: 'Mit diesem Tool finden Sie im Handumdrehen das perfekte Produkt, das Ihren Wünschen und Anforderungen entspricht. Geben Sie einfach Ihre Präferenzen ein und unser intelligenter Filter zeigt Ihnen eine Auswahl an Produkten, die ideal dazu passt. So sparen Sie Zeit und treffen mühelos die beste Kaufentscheidung. Lassen Sie uns Ihnen helfen, genau das Richtige zu finden!',
        buttonLabel: 'Jetzt starten'
      },
      step1: {
        title: 'Welches Substrat möchten Sie veredeln?'
      },
      step2: {
        title: 'Welches genaue Substrat möchten Sie verwenden?'
      },
      step3: {
        title: 'Welches Veredelungsverfahren wenden Sie an?'
      },
      step4: {
        title: 'Welches genaue Veredelungsverfahren möchten Sie verwenden?'
      },
      step5: {
        title: 'Nach welchen Farben suchen Sie?'
      },
      backToStartLabel: 'Von vorne beginnen',
      kurzFinishingProcessesInfo: {
        header: 'Erläuterung',
        analog: {
          header: 'Analog',
          content: ` ${'-'} `
        },
        sheet: {
          header: 'Bogen',
          content: ` ${'-'} `
        },
        digital: {
          header: 'Digital',
          content: `
            <p>Digital inLINE FOILING® findet bei Kunststofftuben Anwendung. Der Transferprozess erfolgt mit wenig Druck und ohne Hitze in einem
            Maschinendurchlauf und mit hoher Geschwindigkeit. Dazu wird ein Transferträger und Inkjettechnologie genutzt.</p>
            <p>Digitaldruck, Kaltfolientransfer:<br>Das gewünschte Design wird mittels Toner oder Elektronik im Digitaldruck auf das Substrat gedruckt.
            Danach erfolgt der Transfer auf das Triggerimage.</p>
          `
        },
        hotStamping: {
          header: 'Heißprägen',
          content: ` ${'-'} `
        },
        coldTransfer: {
          header: 'Kalttransfer',
          content: `
            <p>Das Kalttransfer auf Etiketten-Druckmaschinen ist ein Vorgang, bei dem eine metallisierte Prägefolie mittels eines speziellen, z.B.
            UV-härtenden Klebers auf ein Substrat übertragen wird.</p>
          `
        },
      },
    }
  },
  // -------------------------- FR
  fr: {
    productfinder: {
      starting: {
        title: 'Bienvenue sur notre KURZ Product Finder !',
        text: 'Grâce à cet outil, vous trouverez en un clin d\'œil le produit parfait qui correspond à vos souhaits et à vos exigences. Il vous suffit d\'entrer vos préférences et notre filtre intelligent vous présente une sélection de produits qui y correspondent idéalement. Vous gagnez ainsi du temps et prenez sans peine la meilleure décision d\'achat. Il serait un grand plaisir pour nous de vous aider trouver exactement ce qu\'il vous faut !',
        buttonLabel: 'Commencer maintenant'
      },
      step1: {
        title: 'Quel support souhaitez-vous ennoblir ?'
      },
      step2: {
        title: 'Quel support précis souhaitez-vous utiliser ?'
      },
      step3: {
        title: 'Quel procédé de finition utilisez-vous ?'
      },
      step4: {
        title: 'Quel procédé de finition précis souhaitez-vous utiliser ?'
      },
      step5: {
        title: 'Quelles sont les couleurs que vous recherchez ?'
      },
      backToStartLabel: 'reprendre au début',
      kurzFinishingProcessesInfo: {
        header: 'Explication',
        analog: {
          header: 'FR:Analog',
          content: ` ${'-'} `
        },
        sheet: {
          header: 'FR:Bogen',
          content: ` ${'-'} `
        },
        digital: {
          header: 'FR:Digital',
          content: `FR:
            <p>Digital inLINE FOILING® findet bei Kunststofftuben Anwendung. Der Transferprozess erfolgt mit wenig Druck und ohne Hitze in einem
            Maschinendurchlauf und mit hoher Geschwindigkeit. Dazu wird ein Transferträger und Inkjettechnologie genutzt.</p>
            <p>Digitaldruck, Kaltfolientransfer:<br>Das gewünschte Design wird mittels Toner oder Elektronik im Digitaldruck auf das Substrat gedruckt.
            Danach erfolgt der Transfer auf das Triggerimage.</p>
          `
        },
        hotStamping: {
          header: 'FR:Heißprägen',
          content: ` ${'-'} `
        },
        coldTransfer: {
          header: 'FR:Kalttransfer',
          content: `FR:
            <p>Das Kalttransfer auf Etiketten-Druckmaschinen ist ein Vorgang, bei dem eine metallisierte Prägefolie mittels eines speziellen, z.B.
            UV-härtenden Klebers auf ein Substrat übertragen wird.</p>
          `
        },
      },
    }
  },
  // -------------------------- NL
  nl: {
    productfinder: {
      starting: {
        title: 'Welkom bij onze KURZ Product Finder!',
        text: 'Met deze tool vindt u in een handomdraai het perfecte product dat aan uw wensen en eisen voldoet. Vul eenvoudig uw voorkeuren in en onze slimme filter toont u een selectie van producten die daar ideaal bij passen. Zo bespaart u tijd en neemt u moeiteloos de beste aankoopbeslissing. Laat ons u helpen precies te vinden wat u zoekt!',
        buttonLabel: 'Nu starten'
      },
      step1: {
        title: 'Welke ondergrond wilt u veredelen?'
      },
      step2: {
        title: 'Welke specifieke ondergrond wilt u gebruiken?'
      },
      step3: {
        title: 'Welke veredelingsmethode gebruikt u?'
      },
      step4: {
        title: 'Welke specifieke veredelingsmethode wilt u gebruiken?'
      },
      step5: {
        title: 'Naar welke kleuren ben je op zoek?'
      },
      backToStartLabel: 'Opnieuw beginnen',
      kurzFinishingProcessesInfo: {
        header: 'Verklaring',
        analog: {
          header: 'NL:Analog',
          content: ` ${'-'} `
        },
        sheet: {
          header: 'NL:Bogen',
          content: ` ${'-'} `
        },
        digital: {
          header: 'NL:Digital',
          content: `NL:
            <p>Digital inLINE FOILING® findet bei Kunststofftuben Anwendung. Der Transferprozess erfolgt mit wenig Druck und ohne Hitze in einem
            Maschinendurchlauf und mit hoher Geschwindigkeit. Dazu wird ein Transferträger und Inkjettechnologie genutzt.</p>
            <p>Digitaldruck, Kaltfolientransfer:<br>Das gewünschte Design wird mittels Toner oder Elektronik im Digitaldruck auf das Substrat gedruckt.
            Danach erfolgt der Transfer auf das Triggerimage.</p>
          `
        },
        hotStamping: {
          header: 'NL:Heißprägen',
          content: ` ${'-'} `
        },
        coldTransfer: {
          header: 'NL:Kalttransfer',
          content: `NL:
            <p>Das Kalttransfer auf Etiketten-Druckmaschinen ist ein Vorgang, bei dem eine metallisierte Prägefolie mittels eines speziellen, z.B.
            UV-härtenden Klebers auf ein Substrat übertragen wird.</p>
          `
        },
      },
    }
  },
  chunks: [
    'productfinder'
  ]
};
