
// customLogin:customLogin.errors.httpHandlers.internalServerError

export const customLoginTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    customLogin: {
      countries: {
        title: 'Country selector',
        de: 'Germany',
        fr: 'France',
        benelux: 'Belgium / Netherlands / Luxembourg',
        uk: 'United Kingdom',
        ch: 'Switzerland',
        nz: 'New Zealand',
        au: 'Australia',
        us: 'USA'
      },
      title: 'Log in',
      formular: {
        emailLabel: 'E-mail Address',
        emailPlaceholder: 'Enter e-mail',
        passwordLabel: 'Password',
        passwordPlaceholder: 'Enter your password',
        forgotPasswordLabel: 'Forgot password?',
        loginButtonLabel: 'Sign in',
      },
      registerRequest: {
        title: 'Don\'t have an account yet?',
        message: 'Take advantage of the digital benefits of the KURZ customer portal for your business and let us set up your own personal online access.',
        buttonLabel: 'Request access',
      },
      messages: {
        cxInvalidEmail: 'This is not a valid email format',
        required: 'This field is required',
        httpHandlers: {
          badRequestPleaseLoginAgain: 'E-mail or password is wrong, please try again.',
          disabledLogin: 'You are currently unable to use the webshop. We have forwarded your login attempt internally.',
          internalServerError: 'While trying to login, an internal server error happened',
          sessionExpired: 'Your session has expired. Please login again.',
        },
        authMessages: {
          signedOutSuccessfully: 'You have successfully signed out.',
        }
      },
      requestAccessModal: {
        step1: {
          title: 'Note: Use of Customer Portal',
          p1: 'Our customer portal is intended exclusively for companies, tradespeople and freelancers.',
          p2: 'Please note that access to our customer portal is only available in the following countries:',
          countryList: `<ul>
            <li>Germany</li>
            <li>France</li>
            <li>Great Britain</li>
            <li>Switzerland</li>
            <li>USA</li>
            <li>Australia</li>
            <li>New Zealand</li>
            <li>Belgium, Luxembourg und Netherlands</li>
          </ul>`,
          p3: 'We ask for your understanding and look forward to your use in the countries mentioned.',
          acceptLabel: 'Accept and Continue'
        },
        step2: {
          title: 'Secure your exclusive access',
          context: 'Use the digital benefits of the KURZ customer portal for your business and request your own personal online account now',
          nameLabel: 'Name',
          namePlaceholder: 'Name',
          emailLabel: 'E-Mail',
          emailPlaceholder: 'E-Mail',
          afterSentText: 'After submitting the registration form, our support team will contact you.',
          companyNameLabel: 'Company',
          companyNamePlaceholder: 'Company',
          streetLabel: 'Street',
          streetPlaceholder: 'Street',
          houseNumberLabel: 'House Number',
          houseNumberPlaceholder: 'House Number',
          postcodeLabel: 'Postcode',
          postcodePlaceholder: 'Postcode',
          cityLabel: 'City',
          cityPlaceholder: 'City',
          honeypotLabel: 'Subject',
          honeypotPlaceholder: 'Subject'
        },
      },
      contact: 'Contact',
    }
  },
  // -------------------------- DE
  de: {
    customLogin: {
      countries: {
        title: 'Länderauswahl',
        de: 'Deutschland',
        fr: 'Frankreich',
        benelux: 'Belgien / Niederlande / Luxemburg',
        uk: 'Vereinigtes Königreich',
        ch: 'Schweiz',
        nz: 'Neuseeland',
        au: 'Australien',
        us: 'USA'
      },
      title: 'Anmelden',
      formular: {
        emailLabel: 'E-Mail Adresse',
        emailPlaceholder: 'E-Mail eingeben',
        passwordLabel: 'Passwort',
        passwordPlaceholder: 'Passwort eingeben',
        forgotPasswordLabel: 'Passwort vergessen?',
        loginButtonLabel: 'Jetzt anmelden',
      },
      registerRequest: {
        title: 'Noch kein Konto?',
        message: 'Nutzen Sie die digitalen Vorteile des KURZ Kundenportals für Ihr Geschäft und lassen Sie sich Ihren ganz persönlichen Online-Zugang einrichten.',
        buttonLabel: 'Zugang anfragen'
      },
      messages: {
        cxInvalidEmail: 'Dies ist kein gültiges E-Mail-Format',
        required: 'Dieses Feld ist erforderlich',
        httpHandlers: {
          badRequestPleaseLoginAgain: 'Die eingegebene E-Mail-Adresse oder das Passwort ist nicht korrekt. Bitte versuchen Sie es erneut',
          disabledLogin: 'Der Webshop kann aktuell von Ihnen nicht genutzt werden. Wir haben Ihren Anmeldeversuch intern weitergeleitet.',
          internalServerError: 'Während des Logins ist ein interner Server-Fehler aufgetreten.',
          sessionExpired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
        },
        authMessages: {
          signedOutSuccessfully: 'Sie haben sich erfolgreich abgemeldet.',
        }
      },
      requestAccessModal: {
        step1: {
          title: 'Hinweis: Nutzung des Kundenportals',
          p1: 'Unser Kundenportal ist ausschließlich für Unternehmen, Gewerbetreibende und Freiberufler vorgesehen.',
          p2: 'Bitte beachten Sie, dass der Zugriff auf unser Kundenportal nur in den folgenden Ländern verfügbar ist:',
          countryList: `<ul>
            <li>Deutschland</li>
            <li>Frankreich</li>
            <li>Großbritannien</li>
            <li>Schweiz</li>
            <li>USA</li>
            <li>Australien</li>
            <li>Neuseeland</li>
            <li>Belgien, Luxemburg und Niederlande</li>
          </ul>`,
          p3: 'Wir bitten um Ihr Verständnis und freuen uns auf Ihre Nutzung in den genannten Ländern.',
          acceptLabel: 'Verstanden und weiter'
        },
        step2: {
          title: 'Exklusiven Zugang sichern',
          context: 'Nutzen Sie die digitalen Vorteile des KURZ Kundenportals für Ihr Geschäft und lassen Sie sich Ihren ganz persönlichen Online-Zugang einrichten.',
          nameLabel: 'Name',
          namePlaceholder: 'Name',
          emailLabel: 'E-Mail',
          emailPlaceholder: 'E-Mail',
          afterSentText: 'Nach dem Absenden des Anmeldeformulars wird sich unser Support-Team mit Ihnen in Verbindung setzen.',
          companyNameLabel: 'Firma',
          companyNamePlaceholder: 'Firma',
          streetLabel: 'Straße',
          streetPlaceholder: 'Straße',
          houseNumberLabel: 'Hausnummer',
          houseNumberPlaceholder: 'Hausnummer',
          postcodeLabel: 'Postleitzahl',
          postcodePlaceholder: 'Postleitzahl',
          cityLabel: 'Stadt',
          cityPlaceholder: 'Stadt',
          honeypotLabel: 'Betreff',
          honeypotPlaceholder: 'Betreff'
        },
      },
      contact: 'Kontakt',
    }
  },
  // -------------------------- FR
  fr: {
    customLogin: {
      countries: {
        title: 'Sélectionner le pays',
        de: 'Allemagne',
        fr: 'France',
        benelux: 'Belgique / Pays-Bas / Luxembourg',
        uk: 'Royaume-Uni',
        ch: 'Suisse',
        nz: 'Nouvelle-Zélande',
        au: 'Australie',
        us: 'États-Unis'
      },
      title: 'Connexion',
      formular: {
        emailLabel: 'E-Mail',
        emailPlaceholder: 'E-Mail',
        passwordLabel: 'Mot de passe',
        passwordPlaceholder: 'Mot de passe',
        forgotPasswordLabel: 'Mot de passe oublié ?',
        loginButtonLabel: 'Connexion',
      },
      registerRequest: {
        title: 'Pas de compte client ?',
        message: 'Profitez des avantages numériques du portail clients de KURZ pour votre entreprise et demandez l\'installation d\'un accès en ligne entièrement personnalisé.',
        buttonLabel: 'Demande accès'
      },
      messages: {
        cxInvalidEmail: 'Ce n\'est pas un format-mail valide',
        required: 'Cette case est obligatoire',
        httpHandlers: {
          badRequestPleaseLoginAgain: 'L\'adresse-mail ou le mot de passe n\'est pas correct. Merci d\'essayer de nouveau ',
          disabledLogin: 'Vous ne pouvez actuellement pas utiliser le portail client. Nous avons transmis votre tentative de connexion en interne',
          internalServerError: 'Lors de la tentative de connexion, une erreur de serveur interne s\'est produite',
          sessionExpired: 'Votre session a expiré. Veuillez vous connecter à nouveau.',
        },
        authMessages: {
          signedOutSuccessfully: 'Vous vous êtes déconnecté avec succès.',
        }
      },
      requestAccessModal: {
        step1: {
          title: 'Remarque : Utilisation du portail client',
          p1: 'Notre portail client s\'adresse exclusivement aux entreprises, artisans et indépendants.',
          p2: 'Veuillez noter que l\'accès à notre portail client n\'est disponible que dans les pays suivants:',
          countryList: `<ul>
              <li>Allemagne</li>
              <li>France</li>
              <li>Royaume-Uni</li>
              <li>Suisse</li>
              <li>États-Unis</li>
              <li>Australie</li>
              <li>Nouvelle-Zélande</li>
              <li>Belgique, Luxembourg et Pays-Bas</li>
          </ul>`,
          p3: 'Nous vous demandons de faire preuve de compréhension et nous nous réjouissons de l\'utilisation que vous en ferez dans les pays mentionnés.',
          acceptLabel: 'Accepter et continuer'
        },
        step2: {
          title: 'Assurer un accès exclusif',
          context: 'Profitez des avantages numériques du portail clients de KURZ pour votre societé et demandez l\'installation d\'un accès en ligne entièrement personnalisé.',        nameLabel: 'Nom',
          namePlaceholder: 'Nom',
          emailLabel: 'E-mail',
          emailPlaceholder: 'E-mail',
          afterSentText: 'Après l\'envoi du formulaire d\'inscription, notre équipe d\'assistance vous contactera.',
          companyNameLabel: 'Nom de l\'entreprise',
          companyNamePlaceholder: 'Nom de l\'entreprise',
          streetLabel: 'Rue',
          streetPlaceholder: 'Rue',
          houseNumberLabel: 'Numéro de maison',
          houseNumberPlaceholder: 'Numéro de maison',
          postcodeLabel: 'Code postal',
          postcodePlaceholder: 'Code postal',
          cityLabel: 'Ville',
          cityPlaceholder: 'Ville',
          honeypotLabel: 'Sujet',
          honeypotPlaceholder: 'Sujet'
        },
      },
      contact: 'Contact',
    }
  },
  // -------------------------- NL
  nl: {
    customLogin: {
      countries: {
        title: 'Landkiezer',
        de: 'Duitsland',
        fr: 'Frankrijk',
        benelux: 'België / Nederland / Luxemburg',
        uk: 'Verenigd Koninkrijk',
        ch: 'Zwitserland',
        nz: 'Nieuw-Zeeland',
        au: 'Australië',
        us: 'Verenigde Staten'
      },
      title: 'Inloggen',
      formular: {
        emailLabel: 'E-mail',
        emailPlaceholder: 'E-mail',
        passwordLabel: 'Wachtwoord',
        passwordPlaceholder: 'Wachtwoord',
        forgotPasswordLabel: 'Wachtwoord vergeten?',
        loginButtonLabel: 'Nu inloggen',
      },
      registerRequest: {
        title: 'Nog geen account?',
        message: 'Maak gebruik van de digitale voordelen van de KURZ klantenportaal voor je bedrijf en vraag een account aan.',
        buttonLabel: 'Account aanvragen'
      },
      messages: {
        cxInvalidEmail: 'Dit is geen geldig e-mailformaat',
        required: 'Dit veld is verplicht',
        httpHandlers: {
          badRequestPleaseLoginAgain: 'Het ingevoerde e-mailadres of wachtwoord is onjuist. Probeer het opnieuw',
          internalServerError: 'Tijdens het inloggen is er een interne serverfout opgetreden',
          disabledLogin: 'U kunt momenteel geen gebruik maken van de klantenportaal. We hebben uw inlogpoging intern doorgestuurd',
          sessionExpired: 'Uw sessie is verlopen. Gelieve opnieuw in te loggen.',
        },
        authMessages: {
          signedOutSuccessfully: 'U hebt succesvol uitgelogd.',
        }
      },
      requestAccessModal: {
        step1: {
          title: 'Let op: Gebruik van Klantportaal',
          p1: 'Ons klantenportaal is uitsluitend bedoeld voor bedrijven, vakmensen en freelancers.',
          p2: 'Houd er rekening mee dat toegang tot ons klantenportaal alleen beschikbaar is in de volgende landen:',
          countryList: `<ul>
              <li>Duitsland</li>
              <li>Frankrijk</li>
              <li>Verenigd Koninkrijk</li>
              <li>Zwitserland</li>
              <li>Verenigde Staten</li>
              <li>Australië</li>
              <li>Nieuw-Zeeland</li>
              <li>België, Luxemburg en Nederland</li>
          </ul>`,
          p3: 'Wij vragen om uw begrip en kijken uit naar uw gebruik in de genoemde landen.',
          acceptLabel: 'Accepteren en doorgaan'
        },
        step2: {
          title: 'Verzeker exclusieve toegang',
          context: 'Gebruik de digitale voordelen van de KURZ klantenportaal voor uw bedrijf en richt uw eigen persoonlijke toegang in.',
          nameLabel: 'naam',
          namePlaceholder: 'naam',
          emailLabel: 'e-mail',
          emailPlaceholder: 'e-mail',
          afterSentText: 'Na het versturen van het aanmeldformulier neemt ons supportteam contact met u op.',
          companyNameLabel: 'Bedrijfsnaam',
          companyNamePlaceholder: 'Bedrijfsnaam',
          streetLabel: 'Straat',
          streetPlaceholder: 'Straat',
          houseNumberLabel: 'Huisnummer',
          houseNumberPlaceholder: 'Huisnummer',
          postcodeLabel: 'Postcode',
          postcodePlaceholder: 'Postcode',
          cityLabel: 'Stad',
          cityPlaceholder: 'Stad',
          honeypotLabel: 'Onderwerp',
          honeypotPlaceholder: 'Onderwerp'
        },
      },
      contact: 'Contact',
    },
  },
  chunks: [
    'customLogin'
  ]
};

