export const customUpdatePasswordTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    customUpdatePassword: {
      title: 'Update Password',
      passwordHint: 'Please note: The password needs to contain at least 9 characters and at least one capital letter, one small letter and one number.',
      formular: {
        oldPasswordLabel: 'Current Password',
        oldPasswordPlaceholder: 'Enter current password',
        newPasswordLabel: 'New Password',
        newPasswordPlaceholder: 'Enter new password',
        confirmPasswordLabel: 'Confirm new password',
        confirmPasswordPlaceholder: 'Enter password confirmation',
        saveButton: 'Save',
        hints: {
          tooWeak: 'Password is too weak',
          weak: 'Weak password',
          medium: 'Medium password',
          secure: 'Secure password',
          verySecure: 'Very secure password',
        },
        errors: {
          cxPasswordsMustMatch: 'Password and password confirmation do not match',
          required: 'This field is required',
          minlength:'Password is too short'
        }
      },
    }
  },
  // -------------------------- DE
  de: {
    customUpdatePassword: {
      title: 'Passwort Ändern',
      passwordHint: 'Bitte beachten: Das Passwort muss aus mindestens 9 Zeichen bestehen und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
      formular: {
        oldPasswordLabel: 'Aktuelles Passwort',
        oldPasswordPlaceholder: 'Aktuelles Passwort eingeben',
        newPasswordLabel: 'Neues Passwort',
        newPasswordPlaceholder: 'Neues Passwort eingeben',
        confirmPasswordLabel: 'Neues Passwort wiederholen',
        confirmPasswordPlaceholder: 'Passwort Bestätigung eingeben',
        saveButton: 'Speichern',
        hints: {
          tooWeak: 'Passwort zu schwach',
          weak: 'schwaches Passwort',
          medium: 'mittelstarkes Passwort',
          secure: 'sicheres Passwort',
          verySecure: 'sehr sicheres Passwort',
        },
        errors: {
          cxPasswordsMustMatch: 'Passwort und Passwortbestätigung stimmen nicht überein',
          required: 'Dieses Feld ist erforderlich',
          minlength: 'Passwort zu kurz',
        }
      },
    }
  },
  // -------------------------- FR
  fr: {
    customUpdatePassword: {
      title: 'Mettre à Jour le Mot de Passe',
      passwordHint: 'Veuillez noter : Le mot de passe doit contenir au moins 9 caractères et au moins une lettre majuscule, une lettre minuscule et un chiffre.',
      formular: {
        oldPasswordLabel: 'Mot de Passe Actuel',
        oldPasswordPlaceholder: 'Entrer le mot de passe actuel',
        newPasswordLabel: 'Nouveau Mot de Passe',
        newPasswordPlaceholder: 'Entrer le nouveau mot de passe',
        confirmPasswordLabel: 'Confirmer le nouveau mot de passe',
        confirmPasswordPlaceholder: 'Entrer la confirmation du mot de passe',
        saveButton: 'Enregistrer',
        hints: {
          tooWeak: 'Le mot de passe est trop faible',
          weak: 'Mot de passe faible',
          medium: 'Mot de passe moyen',
          secure: 'Mot de passe sécurisé',
          verySecure: 'Mot de passe très sécurisé'
        },
        errors: {
          cxPasswordsMustMatch: 'Le mot de passe et la confirmation ne correspondent pas',
          required: 'Ce champ est obligatoire',
          minlength: 'Le mot de passe est trop court'
        }
      }
    }
  },
  // -------------------------- NL
  nl: {
    customUpdatePassword: {
      title: 'Wachtwoord Bijwerken',
      passwordHint: 'Let op: Het wachtwoord moet minstens 9 tekens bevatten en ten minste één hoofdletter, één kleine letter en één cijfer bevatten.',
      formular: {
        oldPasswordLabel: 'Huidig Wachtwoord',
        oldPasswordPlaceholder: 'Voer huidig wachtwoord in',
        newPasswordLabel: 'Nieuw Wachtwoord',
        newPasswordPlaceholder: 'Voer nieuw wachtwoord in',
        confirmPasswordLabel: 'Bevestig nieuw wachtwoord',
        confirmPasswordPlaceholder: 'Voer wachtwoordbevestiging in',
        saveButton: 'Opslaan',
        hints: {
          tooWeak: 'Wachtwoord is te zwak',
          weak: 'Zwak wachtwoord',
          medium: 'Gemiddeld wachtwoord',
          secure: 'Veilig wachtwoord',
          verySecure: 'Zeer veilig wachtwoord'
        },
        errors: {
          cxPasswordsMustMatch: 'Wachtwoord en wachtwoordbevestiging komen niet overeen',
          required: 'Dit veld is verplicht',
          minlength: 'Wachtwoord is te kort'
        }
      }
    }
  },
  chunks: [
    'customUpdatePassword'
  ]
};
