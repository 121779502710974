
export const myAccountTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    updatePasswordForm: {},
  },
  // -------------------------- DE
  de: {
    updatePasswordForm: {
      oldPassword: {
        label: 'Altes Passwort',
        placeholder: 'Altes Passwort',
      },
      oldPasswordIsRequired: 'Eingabe des Alten Passworts wird benötigt',
      newPassword: {
        label: 'Neues Passwort',
        placeholder: 'Neues Passwort',
      },
      passwordMinRequirements:
        'Passwort muss aus mindestens 6 Zeichen, darunter Großbuchstaben, Zahlen und Symbolen, bestehen',
      confirmPassword: {
        label: 'Passwortbestätigung',
        placeholder: 'Passwortbestätigung',
      },
      bothPasswordMustMatch: 'Beide Passwörter müssen übereinstimmen',
      passwordUpdateSuccess: 'Änderung war erfolgreich',
    }
  },
  // -------------------------- FR
  fr: {
    updatePasswordForm: {
      oldPassword: {
        label: 'FR: Altes Passwort',
        placeholder: 'FR: Altes Passwort',
      },
      oldPasswordIsRequired: 'FR: Eingabe des Alten Passworts wird benötigt',
      newPassword: {
        label: 'FR: Neues Passwort',
        placeholder: 'FR: Neues Passwort',
      },
      passwordMinRequirements: 'FR: Passwort muss aus mindestens 6 Zeichen, darunter Großbuchstaben, Zahlen und Symbolen, bestehen',
      confirmPassword: {
        label: 'FR: Passwortbestätigung',
        placeholder: 'FR: Passwortbestätigung',
      },
      bothPasswordMustMatch: 'FR: Beide Passwörter müssen übereinstimmen',
      passwordUpdateSuccess: 'FR: Änderung war erfolgreich',
    }
  },
  // -------------------------- FR
  nl: {
    updatePasswordForm: {
      oldPassword: {
        label: 'NL: Altes Passwort',
        placeholder: 'NL: Altes Passwort',
      },
      oldPasswordIsRequired: 'NL: Eingabe des Alten Passworts wird benötigt',
      newPassword: {
        label: 'NL: Neues Passwort',
        placeholder: 'NL: Neues Passwort',
      },
      passwordMinRequirements: 'NL: Passwort muss aus mindestens 6 Zeichen, darunter Großbuchstaben, Zahlen und Symbolen, bestehen',
      confirmPassword: {
        label: 'NL: Passwortbestätigung',
        placeholder: 'NL: Passwortbestätigung',
      },
      bothPasswordMustMatch: 'NL: Beide Passwörter müssen übereinstimmen',
      passwordUpdateSuccess: 'NL: Änderung war erfolgreich',
    }
  },
  chunks: [
    'updatePasswordForm'
  ]
};
