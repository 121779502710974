export const customResetPasswordTranslations = { //NOSONAR
  // -------------------------- EN
  en: {
    customResetPassword: {
      title: 'Reset Password',
      passwordHint: 'Please note: The password needs to contain at least 9 characters and at least one capital letter, one small letter and one number.',
      formular: {
        passwordLabel: 'New Password',
        passwordPlaceholder: 'Enter new password',
        confirmPasswordLabel: 'Confirm new password',
        confirmPasswordPlaceholder: 'Enter password confirmation',
        saveButton: 'Save',
        hints: {
          tooShort: 'Password is too short',
          tooWeak: 'Password is too weak',
          weak: 'Weak password',
          medium: 'Medium password',
          secure: 'Secure password',
          verySecure: 'Very secure password',
        },
        errors: {
          cxPasswordsMustMatch: 'Password and password confirmation do not match',
          required: 'This field is required',
          minlength:'Password is too short'
        }
      },
      toLoginButton: 'Go to Login',
    }
  },
  // -------------------------- DE
  de: {
    customResetPassword: {
      title: 'Passwort zurücksetzen',
      passwordHint: 'Bitte beachten: Das Passwort muss aus mindestens 9 Zeichen bestehen und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
      formular: {
        passwordLabel: 'Neues Passwort',
        passwordPlaceholder: 'Neues Passwort eingeben',
        confirmPasswordLabel: 'Neues Passwort wiederholen',
        confirmPasswordPlaceholder: 'Passwort Bestätigung eingeben',
        saveButton: 'Speichern',
        hints: {
          tooShort: 'Passwort zu kurz',
          tooWeak: 'Passwort zu schwach',
          weak: 'schwaches Passwort',
          medium: 'mittelstarkes Passwort',
          secure: 'sicheres Passwort',
          verySecure: 'sehr sicheres Passwort',
        },
        errors: {
          cxPasswordsMustMatch: 'Passwort und Passwortbestätigung stimmen nicht überein',
          required: 'Dieses Feld ist erforderlich',
          minlength: 'Passwort zu kurz',
        }
      },
      toLoginButton: 'Zum Login',
    }
  },
  // -------------------------- FR
  fr: {
    customResetPassword: {
      title: 'Réinitialiser le mot de passe',
      passwordHint: 'Attention: Le mot de passe doit comporter 9 caractères minimum et contenir une lettre majuscule, une lettre minuscule et un chiffre.',
      formular: {
        passwordLabel: 'Nouveau mot de passe',
        passwordPlaceholder: 'Veuillez saisir le mot de passe',
        confirmPasswordLabel: 'Répéter le mot de passe',
        confirmPasswordPlaceholder: 'Saisir le mot de passe de confirmation',
        saveButton: 'Sauvegarder',
        hints: {
          tooShort: 'Mot de passe trop court',
          tooWeak: 'Mot de passe trop faible',
          weak: 'Mot de passe faible',
          medium: 'Mot de passe moyen',
          secure: 'Mot de passe fort',
          verySecure: 'Mot de passe très fort',
        },
        errors: {
          cxPasswordsMustMatch: 'Mot de passe et confirmation mot de pass ne sont pas conformes',
          required: 'Cette case est obligatoire',
          minlength: 'Mot de passe trop court',
        }
      },
      toLoginButton: 'Revenir à connexion',
    }
  },
  // -------------------------- NL
  nl: {
    customResetPassword: {
      title: 'Wachtwoord resetten',
      passwordHint: 'Let op: Het wachtwoord moet uit minimaal 9 tekens bestaan en een hoofdletter, een kleine letter en een cijfer bevatten.',
      formular: {
        passwordLabel: 'Nieuw wachtwoord',
        passwordPlaceholder: 'Voer een nieuw wachtwoord in',
        confirmPasswordLabel: 'Herhaal nieuw wachtwoord',
        confirmPasswordPlaceholder: 'Bevestiging wachtwoord invoeren',
        saveButton: 'Opslaan',
        hints: {
          tooShort: 'Wachtwoord te kort',
          tooWeak: 'Wachtwoord te zwak',
          weak: 'Zwak wachtwoord',
          medium: 'Gemiddeld sterk wachtwoord',
          secure: 'Sterk wachtwoord',
          verySecure: 'Zeer sterk wachtwoord',
        },
        errors: {
          cxPasswordsMustMatch: 'Wachtwoord en wachtwoordbevestiging komen niet overeen',
          required: 'Dit veld is verplicht',
          minlength: 'Wachtwoord te kort',
        }
      },
      toLoginButton: 'Naar login',
    }
  },
  chunks: [
    'customResetPassword'
  ]
};
